package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ParentEnrolmentRequired
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinAndMinorPerson
import kotlin.Boolean
import kotlin.Long
import kotlin.collections.List

public class PersonParentJoinDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonParentJoinDao,
) : PersonParentJoinDao() {
  override suspend fun insertListAsync(entityList: List<PersonParentJoin>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.ppjUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(512)
        it.ppjUid = _newPk
        _generatedPks += _newPk
      }
      it.ppjLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override suspend fun upsertAsync(entity: PersonParentJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.ppjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(512)
      entity.ppjUid = _newPk
    }
    entity.ppjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.upsertAsync(entity)
    return entity.ppjUid
  }

  override suspend fun findByUidWithMinorAsync(uid: Long): PersonParentJoinAndMinorPerson? =
      _dao.findByUidWithMinorAsync(uid)

  override suspend fun findByUidWithMinorAsyncFromWeb(uid: Long): PersonParentJoinAndMinorPerson? =
      _dao.findByUidWithMinorAsyncFromWeb(uid)

  override suspend fun findByMinorPersonUid(minorPersonUid: Long): List<PersonParentJoin> =
      _dao.findByMinorPersonUid(minorPersonUid)

  override suspend fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<ParentEnrolmentRequired> =
      _dao.findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid, clazzUidFilter)

  override suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean =
      _dao.isParentOf(userPersonUid, minorPersonUid)

  override suspend fun updateAsync(personParentJoin: PersonParentJoin) {
    personParentJoin.ppjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(personParentJoin)
  }

  override suspend fun isMinorApproved(minorPersonUid: Long): Boolean =
      _dao.isMinorApproved(minorPersonUid)
}
