package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.PersonAndListDisplayDetails
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.composites.PersonNames
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAndDisplayDetail
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonUidAndPasswordHash
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class PersonDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonDao,
) : PersonDao() {
  override suspend fun insertListAsync(entityList: List<Person>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.personUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(9)
        it.personUid = _newPk
        _generatedPks += _newPk
      }
      it.personLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override suspend fun insertOrReplace(person: Person) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(person.personUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(9)
      person.personUid = _newPk
    }
    person.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertOrReplace(person)
  }

  override suspend fun countUsername(username: String): Int = _dao.countUsername(username)

  override suspend fun findUidAndPasswordHashAsync(username: String): PersonUidAndPasswordHash? =
      _dao.findUidAndPasswordHashAsync(username)

  override suspend fun findByUsernameAndPasswordHash2(username: String, passwordHash: String):
      Person? = _dao.findByUsernameAndPasswordHash2(username, passwordHash)

  override fun insertPersonAuth(personAuth: PersonAuth): Unit = throw
      IllegalStateException("insertPersonAuth: synchronous db access is NOT possible on Javascript!")

  override fun findByUsername(username: String?): Person? = throw
      IllegalStateException("findByUsername: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUsernameAsync(username: String): Person? =
      _dao.findByUsernameAsync(username)

  override suspend fun findSystemAccount(nodeId: Long): Person? = _dao.findSystemAccount(nodeId)

  override fun findByUid(uid: Long): Person? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidWithPicture(accountPersonUid: Long): PersonAndPicture? =
      _dao.findByUidWithPicture(accountPersonUid)

  override fun findByUidWithPictureAsFlow(uid: Long): Flow<PersonAndPicture?> =
      _dao.findByUidWithPictureAsFlow(uid)

  override fun findByUidLive(uid: Long): Flow<Person?> = _dao.findByUidLive(uid)

  override suspend fun findByUidAsync(uid: Long): Person? = _dao.findByUidAsync(uid)

  override fun findByUidAsFlow(uid: Long): Flow<Person?> = _dao.findByUidAsFlow(uid)

  override suspend fun updateAsync(entity: Person): Int {
    entity.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personGroup.groupUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(43)
      personGroup.groupUid = _newPk
    }
    personGroup.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertPersonGroup(personGroup)
    return personGroup.groupUid
  }

  override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personGroupMember.groupMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(44)
      personGroupMember.groupMemberUid = _newPk
    }
    personGroupMember.groupMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertPersonGroupMember(personGroupMember)
    return personGroupMember.groupMemberUid
  }

  override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): List<PersonAndListDisplayDetails> = throw
      IllegalStateException("findPersonsWithPermissionAsList: synchronous db access is NOT possible on Javascript!")

  override fun findPersonsWithPermissionAsPagingSource(
    timestamp: Long,
    excludeClazz: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): PagingSource<Int, PersonAndListDisplayDetails> =
      _dao.findPersonsWithPermissionAsPagingSource(timestamp, excludeClazz, excludeSelected,
      accountPersonUid, sortOrder, searchText)

  override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      Flow<PersonAndDisplayDetail?> = _dao.findByUidWithDisplayDetailsLive(personUid,
      activeUserPersonUid)

  override fun findByUidWithDisplayDetailsFlow(personUid: Long, accountPersonUid: Long):
      Flow<PersonAndDisplayDetail?> = _dao.findByUidWithDisplayDetailsFlow(personUid,
      accountPersonUid)

  override fun getAllPerson(): List<Person> = throw
      IllegalStateException("getAllPerson: synchronous db access is NOT possible on Javascript!")

  override fun getNamesByUid(uid: Long): Flow<PersonNames?> = _dao.getNamesByUid(uid)

  override suspend fun getNamesByUidAsync(uid: Long): PersonNames? = _dao.getNamesByUidAsync(uid)

  override suspend fun getMinorByParentPersonUidAsync(uid: Long): List<Person>? =
      _dao.getMinorByParentPersonUidAsync(uid)

  override suspend fun updateUsername(
    personUid: Long,
    username: String,
    currentTime: Long,
  ): Int = _dao.updateUsername(personUid, username, currentTime)

  override suspend fun selectExistingUsernames(usernames: List<String>): List<String?> =
      _dao.selectExistingUsernames(usernames)

  public override fun insert(entity: Person): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Person): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.personUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(9)
      entity.personUid = _newPk
    }
    entity.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.personUid
  }

  public override fun insertList(entityList: List<Person>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Person): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
