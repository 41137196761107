package com.ustadmobile.core.db.dao.xapi

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.lib.db.composites.BlockStatus
import com.ustadmobile.lib.db.composites.xapi.StatementEntityAndRelated
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.StatementEntityAndDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.xapi.StatementEntity
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class StatementDao {
  public actual abstract suspend fun insertOrIgnoreListAsync(entityList: List<StatementEntity>)

  public actual abstract fun getOneStatement(): Flow<StatementEntity?>

  public actual abstract suspend fun getResults(query: DoorQuery): List<StatementReportData>

  public actual abstract fun getListResults(query: DoorQuery):
      PagingSource<Int, StatementEntityAndDisplayDetails>

  public actual abstract fun getPerson(): Person?

  public actual abstract suspend fun getStatements(statementIdHi: Long, statementIdLo: Long):
      List<StatementEntity>

  public actual abstract suspend fun findById(statementIdHi: Long, statementIdLo: Long):
      StatementEntity?

  public actual abstract suspend fun findStatusStatementsByContentEntryUid(
    contentEntryUid: Long,
    courseBlockUid: Long,
    accountPersonUid: Long,
  ): List<StatementEntity>

  public actual abstract suspend fun findStatusStatementByParentContentEntryUid(parentUid: Long,
      accountPersonUid: Long): List<StatementEntity>

  public actual abstract suspend fun findStatusStatementsForStudentByClazzUid(clazzUid: Long,
      accountPersonUid: Long): List<StatementEntity>

  public actual abstract suspend fun findStatusForStudentsInClazzStatements(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    studentsLimit: Int,
    studentsOffset: Int,
    completionOrProgressTrueVal: Boolean,
  ): List<StatementEntityAndRelated>

  public actual abstract suspend fun findStatusForStudentsInClazz(clazzUid: Long,
      studentPersonUids: List<Long>): List<BlockStatus>

  public actual abstract suspend fun findResumableRegistration(
    activityUid: Long,
    accountPersonUid: Long,
    actorUid: Long,
  ): StatementEntity?
}
