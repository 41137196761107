package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_Repo
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_Repo
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_Repo
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_Repo
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_Repo
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_Repo
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_Repo
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao_Repo
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_Repo
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_Repo
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao_Repo
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_Repo
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao_Repo
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_Repo
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_Repo
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePermissionDao_Repo
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_Repo
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_Repo
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DeletedItemDao_Repo
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_Repo
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao_Repo
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_Repo
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_Repo
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_Repo
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_Repo
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_Repo
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_Repo
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_Repo
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OfflineItemDao_Repo
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao_Repo
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_Repo
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_Repo
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_Repo
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_Repo
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_Repo
import com.ustadmobile.core.db.dao.PersonPasskeyDao
import com.ustadmobile.core.db.dao.PersonPasskeyDao_Repo
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_Repo
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_Repo
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_Repo
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_Repo
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_Repo
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_Repo
import com.ustadmobile.core.db.dao.StudentResultDao
import com.ustadmobile.core.db.dao.StudentResultDao_Repo
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.SystemPermissionDao_Repo
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_Repo
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao_Repo
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao_Repo
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao_Repo
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao_Repo
import com.ustadmobile.core.db.dao.xapi.ActorDao
import com.ustadmobile.core.db.dao.xapi.ActorDao_Repo
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao_Repo
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao_Repo
import com.ustadmobile.core.db.dao.xapi.StateEntityDao
import com.ustadmobile.core.db.dao.xapi.StateEntityDao_Repo
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao_Repo
import com.ustadmobile.core.db.dao.xapi.StatementDao
import com.ustadmobile.core.db.dao.xapi.StatementDao_Repo
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao_Repo
import com.ustadmobile.core.db.dao.xapi.VerbDao
import com.ustadmobile.core.db.dao.xapi.VerbDao_Repo
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao_Repo
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao_Repo
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.RepositoryConfig
import com.ustadmobile.door.RepositoryHelper
import com.ustadmobile.door.replication.DoorRepositoryReplicationClient
import com.ustadmobile.door.room.InvalidationTracker
import io.ktor.client.HttpClient
import kotlin.Any
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("LocalVariableName", "PropertyName", "FunctionName", "ClassName")
public class UmAppDatabase_Repo(
  override val db: UmAppDatabase,
  dbUnwrapped: UmAppDatabase,
  override val config: RepositoryConfig,
) : UmAppDatabase(), DoorDatabaseRepository {
  public val context: Any
    get() = config.context

  private val _db: UmAppDatabase = dbUnwrapped

  private val _endpoint: String
    get() = config.endpoint

  public val _httpClient: HttpClient
    get() = config.httpClient

  public val _repositoryHelper: RepositoryHelper = RepositoryHelper(db, config)


  public val clientId: Long
    get() = config.nodeId

  override val dbName: String
    get() = "Repository for [${_db.toString()}] - ${config.endpoint}"

  override var connectivityStatus: Int
    get() = _repositoryHelper.connectivityStatus
    set(newValue) {
      _repositoryHelper.connectivityStatus = newValue
    }

  override val clientState: Flow<DoorRepositoryReplicationClient.ClientState>
    get() = _repositoryHelper.clientState

  override val invalidationTracker: InvalidationTracker
    get() = _db.invalidationTracker

  override val dbVersion: Int
    get() = 202

  private val _PersonDao: PersonDao_Repo by lazy {
        PersonDao_Repo(db, this, db.personDao(), _httpClient, clientId, _endpoint)
      }


  private val _ClazzDao: ClazzDao_Repo by lazy {
        ClazzDao_Repo(db, this, db.clazzDao(), _httpClient, clientId, _endpoint)
      }


  private val _CourseBlockDao: CourseBlockDao_Repo by lazy {
        CourseBlockDao_Repo(db, this, db.courseBlockDao(), _httpClient, clientId, _endpoint)
      }


  private val _CourseTerminologyDao: CourseTerminologyDao_Repo by lazy {
        CourseTerminologyDao_Repo(db, this, db.courseTerminologyDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _CourseGroupSetDao: CourseGroupSetDao_Repo by lazy {
        CourseGroupSetDao_Repo(db, this, db.courseGroupSetDao(), _httpClient, clientId, _endpoint)
      }


  private val _CourseGroupMemberDao: CourseGroupMemberDao_Repo by lazy {
        CourseGroupMemberDao_Repo(db, this, db.courseGroupMemberDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ClazzEnrolmentDao: ClazzEnrolmentDao_Repo by lazy {
        ClazzEnrolmentDao_Repo(db, this, db.clazzEnrolmentDao(), _httpClient, clientId, _endpoint)
      }


  private val _LeavingReasonDao: LeavingReasonDao_Repo by lazy {
        LeavingReasonDao_Repo(db, this, db.leavingReasonDao(), _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryDao: ContentEntryDao_Repo by lazy {
        ContentEntryDao_Repo(db, this, db.contentEntryDao(), _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao_Repo by lazy {
        ContentEntryContentCategoryJoinDao_Repo(db, this, db.contentEntryContentCategoryJoinDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao_Repo by lazy {
        ContentEntryParentChildJoinDao_Repo(db, this, db.contentEntryParentChildJoinDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao_Repo by lazy {
        ContentEntryRelatedEntryJoinDao_Repo(db, this, db.contentEntryRelatedEntryJoinDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _ContentCategorySchemaDao: ContentCategorySchemaDao_Repo by lazy {
        ContentCategorySchemaDao_Repo(db, this, db.contentCategorySchemaDao(), _httpClient,
            clientId, _endpoint)
      }


  private val _ContentCategoryDao: ContentCategoryDao_Repo by lazy {
        ContentCategoryDao_Repo(db, this, db.contentCategoryDao(), _httpClient, clientId, _endpoint)
      }


  private val _LanguageDao: LanguageDao_Repo by lazy {
        LanguageDao_Repo(db, this, db.languageDao(), _httpClient, clientId, _endpoint)
      }


  private val _LanguageVariantDao: LanguageVariantDao_Repo by lazy {
        LanguageVariantDao_Repo(db, this, db.languageVariantDao(), _httpClient, clientId, _endpoint)
      }


  private val _PersonGroupDao: PersonGroupDao_Repo by lazy {
        PersonGroupDao_Repo(db, this, db.personGroupDao(), _httpClient, clientId, _endpoint)
      }


  private val _PersonGroupMemberDao: PersonGroupMemberDao_Repo by lazy {
        PersonGroupMemberDao_Repo(db, this, db.personGroupMemberDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _PersonPictureDao: PersonPictureDao_Repo by lazy {
        PersonPictureDao_Repo(db, this, db.personPictureDao(), _httpClient, clientId, _endpoint)
      }


  private val _VerbDao: VerbDao_Repo by lazy {
        VerbDao_Repo(db, this, db.verbDao(), _httpClient, clientId, _endpoint)
      }


  private val _ActivityEntityDao: ActivityEntityDao_Repo by lazy {
        ActivityEntityDao_Repo(db, this, db.activityEntityDao(), _httpClient, clientId, _endpoint)
      }


  private val _ReportDao: ReportDao_Repo by lazy {
        ReportDao_Repo(db, this, db.reportDao(), _httpClient, clientId, _endpoint)
      }


  private val _StatementDao: StatementDao_Repo by lazy {
        StatementDao_Repo(db, this, db.statementDao(), _httpClient, clientId, _endpoint)
      }


  private val _ActorDao: ActorDao_Repo by lazy {
        ActorDao_Repo(db, this, db.actorDao(), _httpClient, clientId, _endpoint)
      }


  private val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao_Repo by lazy {
        ClazzLogAttendanceRecordDao_Repo(db, this, db.clazzLogAttendanceRecordDao(), _httpClient,
            clientId, _endpoint)
      }


  private val _ClazzLogDao: ClazzLogDao_Repo by lazy {
        ClazzLogDao_Repo(db, this, db.clazzLogDao(), _httpClient, clientId, _endpoint)
      }


  private val _ScheduleDao: ScheduleDao_Repo by lazy {
        ScheduleDao_Repo(db, this, db.scheduleDao(), _httpClient, clientId, _endpoint)
      }


  private val _HolidayCalendarDao: HolidayCalendarDao_Repo by lazy {
        HolidayCalendarDao_Repo(db, this, db.holidayCalendarDao(), _httpClient, clientId, _endpoint)
      }


  private val _HolidayDao: HolidayDao_Repo by lazy {
        HolidayDao_Repo(db, this, db.holidayDao(), _httpClient, clientId, _endpoint)
      }


  private val _ClazzAssignmentDao: ClazzAssignmentDao_Repo by lazy {
        ClazzAssignmentDao_Repo(db, this, db.clazzAssignmentDao(), _httpClient, clientId, _endpoint)
      }


  private val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao_Repo by lazy {
        CourseAssignmentSubmissionDao_Repo(db, this, db.courseAssignmentSubmissionDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _CourseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao_Repo by lazy {
        CourseAssignmentSubmissionFileDao_Repo(db, this, db.courseAssignmentSubmissionFileDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _CourseAssignmentMarkDao: CourseAssignmentMarkDao_Repo by lazy {
        CourseAssignmentMarkDao_Repo(db, this, db.courseAssignmentMarkDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _CommentsDao: CommentsDao_Repo by lazy {
        CommentsDao_Repo(db, this, db.commentsDao(), _httpClient, clientId, _endpoint)
      }


  private val _SiteDao: SiteDao_Repo by lazy {
        SiteDao_Repo(db, this, db.siteDao(), _httpClient, clientId, _endpoint)
      }


  private val _SiteTermsDao: SiteTermsDao_Repo by lazy {
        SiteTermsDao_Repo(db, this, db.siteTermsDao(), _httpClient, clientId, _endpoint)
      }


  private val _PersonParentJoinDao: PersonParentJoinDao_Repo by lazy {
        PersonParentJoinDao_Repo(db, this, db.personParentJoinDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ScopedGrantDao: ScopedGrantDao_Repo by lazy {
        ScopedGrantDao_Repo(db, this, db.scopedGrantDao(), _httpClient, clientId, _endpoint)
      }


  private val _ErrorReportDao: ErrorReportDao_Repo by lazy {
        ErrorReportDao_Repo(db, this, db.errorReportDao(), _httpClient, clientId, _endpoint)
      }


  private val _PersonAuth2Dao: PersonAuth2Dao_Repo by lazy {
        PersonAuth2Dao_Repo(db, this, db.personAuth2Dao(), _httpClient, clientId, _endpoint)
      }


  private val _UserSessionDao: UserSessionDao_Repo by lazy {
        UserSessionDao_Repo(db, this, db.userSessionDao(), _httpClient, clientId, _endpoint)
      }


  private val _CoursePictureDao: CoursePictureDao_Repo by lazy {
        CoursePictureDao_Repo(db, this, db.coursePictureDao(), _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryPictureDao: ContentEntryPictureDao_Repo by lazy {
        ContentEntryPictureDao_Repo(db, this, db.contentEntryPictureDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _MessageDao: MessageDao_Repo by lazy {
        MessageDao_Repo(db, this, db.messageDao(), _httpClient, clientId, _endpoint)
      }


  private val _PeerReviewerAllocationDao: PeerReviewerAllocationDao_Repo by lazy {
        PeerReviewerAllocationDao_Repo(db, this, db.peerReviewerAllocationDao(), _httpClient,
            clientId, _endpoint)
      }


  private val _DiscussionPostDao: DiscussionPostDao_Repo by lazy {
        DiscussionPostDao_Repo(db, this, db.discussionPostDao(), _httpClient, clientId, _endpoint)
      }


  private val _ContentEntryVersionDao: ContentEntryVersionDao_Repo by lazy {
        ContentEntryVersionDao_Repo(db, this, db.contentEntryVersionDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _OfflineItemDao: OfflineItemDao_Repo by lazy {
        OfflineItemDao_Repo(db, this, db.offlineItemDao(), _httpClient, clientId, _endpoint)
      }


  private val _DeletedItemDao: DeletedItemDao_Repo by lazy {
        DeletedItemDao_Repo(db, this, db.deletedItemDao(), _httpClient, clientId, _endpoint)
      }


  private val _EnrolmentRequestDao: EnrolmentRequestDao_Repo by lazy {
        EnrolmentRequestDao_Repo(db, this, db.enrolmentRequestDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _CoursePermissionDao: CoursePermissionDao_Repo by lazy {
        CoursePermissionDao_Repo(db, this, db.coursePermissionDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _SystemPermissionDao: SystemPermissionDao_Repo by lazy {
        SystemPermissionDao_Repo(db, this, db.systemPermissionDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _CourseBlockPictureDao: CourseBlockPictureDao_Repo by lazy {
        CourseBlockPictureDao_Repo(db, this, db.courseBlockPictureDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ContentEntryPicture2Dao: ContentEntryPicture2Dao_Repo by lazy {
        ContentEntryPicture2Dao_Repo(db, this, db.contentEntryPicture2Dao(), _httpClient, clientId,
            _endpoint)
      }


  private val _StudentResultDao: StudentResultDao_Repo by lazy {
        StudentResultDao_Repo(db, this, db.studentResultDao(), _httpClient, clientId, _endpoint)
      }


  private val _VerbLangMapEntryDao: VerbLangMapEntryDao_Repo by lazy {
        VerbLangMapEntryDao_Repo(db, this, db.verbLangMapEntryDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _GroupMemberActorJoinDao: GroupMemberActorJoinDao_Repo by lazy {
        GroupMemberActorJoinDao_Repo(db, this, db.groupMemberActorJoinDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ActivityLangMapEntryDao: ActivityLangMapEntryDao_Repo by lazy {
        ActivityLangMapEntryDao_Repo(db, this, db.activityLangMapEntryDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ActivityInteractionDao: ActivityInteractionDao_Repo by lazy {
        ActivityInteractionDao_Repo(db, this, db.activityInteractionDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _ActivityExtensionDao: ActivityExtensionDao_Repo by lazy {
        ActivityExtensionDao_Repo(db, this, db.activityExtensionDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _StatementContextActivityJoinDao: StatementContextActivityJoinDao_Repo by lazy {
        StatementContextActivityJoinDao_Repo(db, this, db.statementContextActivityJoinDao(),
            _httpClient, clientId, _endpoint)
      }


  private val _XapiSessionEntityDao: XapiSessionEntityDao_Repo by lazy {
        XapiSessionEntityDao_Repo(db, this, db.xapiSessionEntityDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _StatementEntityJsonDao: StatementEntityJsonDao_Repo by lazy {
        StatementEntityJsonDao_Repo(db, this, db.statementEntityJsonDao(), _httpClient, clientId,
            _endpoint)
      }


  private val _PersonPasskeyDao: PersonPasskeyDao_Repo by lazy {
        PersonPasskeyDao_Repo(db, this, db.personPasskeyDao(), _httpClient, clientId, _endpoint)
      }


  private val _StateEntityDao: StateEntityDao_Repo by lazy {
        StateEntityDao_Repo(db, this, db.stateEntityDao(), _httpClient, clientId, _endpoint)
      }


  private val _StateDeleteCommandDao: StateDeleteCommandDao_Repo by lazy {
        StateDeleteCommandDao_Repo(db, this, db.stateDeleteCommandDao(), _httpClient, clientId,
            _endpoint)
      }


  override fun clearAllTables(): Unit = throw
      IllegalStateException("Cannot use a repository to clearAllTables!")

  override suspend fun clearAllTablesAsync(): Unit = throw
      IllegalStateException("Cannot use this to run clearAllTablesAsync")

  override fun close() {
    _repositoryHelper.close()
  }

  override fun remoteNodeIdOrNull(): Long? = _repositoryHelper.remoteNodeIdOrNull()

  override fun remoteNodeIdOrFake(): Long = _repositoryHelper.remoteNodeIdOrFake()

  override fun createAllTables(): List<String> = throw
      IllegalStateException("Cannot use a repository to createAllTables!")

  override fun personDao(): PersonDao =  _PersonDao

  override fun clazzDao(): ClazzDao =  _ClazzDao

  override fun courseBlockDao(): CourseBlockDao =  _CourseBlockDao

  override fun courseTerminologyDao(): CourseTerminologyDao =  _CourseTerminologyDao

  override fun courseGroupSetDao(): CourseGroupSetDao =  _CourseGroupSetDao

  override fun courseGroupMemberDao(): CourseGroupMemberDao =  _CourseGroupMemberDao

  override fun clazzEnrolmentDao(): ClazzEnrolmentDao =  _ClazzEnrolmentDao

  override fun leavingReasonDao(): LeavingReasonDao =  _LeavingReasonDao

  override fun contentEntryDao(): ContentEntryDao =  _ContentEntryDao

  override fun contentEntryContentCategoryJoinDao(): ContentEntryContentCategoryJoinDao = 
      _ContentEntryContentCategoryJoinDao

  override fun contentEntryParentChildJoinDao(): ContentEntryParentChildJoinDao = 
      _ContentEntryParentChildJoinDao

  override fun contentEntryRelatedEntryJoinDao(): ContentEntryRelatedEntryJoinDao = 
      _ContentEntryRelatedEntryJoinDao

  override fun contentCategorySchemaDao(): ContentCategorySchemaDao =  _ContentCategorySchemaDao

  override fun contentCategoryDao(): ContentCategoryDao =  _ContentCategoryDao

  override fun languageDao(): LanguageDao =  _LanguageDao

  override fun languageVariantDao(): LanguageVariantDao =  _LanguageVariantDao

  override fun personAuthDao(): PersonAuthDao = throw
      IllegalStateException("PersonAuthDao is not annotated with @Repository")

  override fun personGroupDao(): PersonGroupDao =  _PersonGroupDao

  override fun personGroupMemberDao(): PersonGroupMemberDao =  _PersonGroupMemberDao

  override fun personPictureDao(): PersonPictureDao =  _PersonPictureDao

  override fun verbDao(): VerbDao =  _VerbDao

  override fun activityEntityDao(): ActivityEntityDao =  _ActivityEntityDao

  override fun reportDao(): ReportDao =  _ReportDao

  override fun statementDao(): StatementDao =  _StatementDao

  override fun actorDao(): ActorDao =  _ActorDao

  override fun clazzLogAttendanceRecordDao(): ClazzLogAttendanceRecordDao = 
      _ClazzLogAttendanceRecordDao

  override fun clazzLogDao(): ClazzLogDao =  _ClazzLogDao

  override fun scheduleDao(): ScheduleDao =  _ScheduleDao

  override fun holidayCalendarDao(): HolidayCalendarDao =  _HolidayCalendarDao

  override fun holidayDao(): HolidayDao =  _HolidayDao

  override fun clazzAssignmentDao(): ClazzAssignmentDao =  _ClazzAssignmentDao

  override fun courseAssignmentSubmissionDao(): CourseAssignmentSubmissionDao = 
      _CourseAssignmentSubmissionDao

  override fun courseAssignmentSubmissionFileDao(): CourseAssignmentSubmissionFileDao = 
      _CourseAssignmentSubmissionFileDao

  override fun courseAssignmentMarkDao(): CourseAssignmentMarkDao =  _CourseAssignmentMarkDao

  override fun commentsDao(): CommentsDao =  _CommentsDao

  override fun syncNodeDao(): SyncNodeDao = throw
      IllegalStateException("SyncNodeDao is not annotated with @Repository")

  override fun siteDao(): SiteDao =  _SiteDao

  override fun siteTermsDao(): SiteTermsDao =  _SiteTermsDao

  override fun personParentJoinDao(): PersonParentJoinDao =  _PersonParentJoinDao

  override fun scopedGrantDao(): ScopedGrantDao =  _ScopedGrantDao

  override fun errorReportDao(): ErrorReportDao =  _ErrorReportDao

  override fun personAuth2Dao(): PersonAuth2Dao =  _PersonAuth2Dao

  override fun userSessionDao(): UserSessionDao =  _UserSessionDao

  override fun contentEntryImportJobDao(): ContentEntryImportJobDao = throw
      IllegalStateException("ContentEntryImportJobDao is not annotated with @Repository")

  override fun coursePictureDao(): CoursePictureDao =  _CoursePictureDao

  override fun contentEntryPictureDao(): ContentEntryPictureDao =  _ContentEntryPictureDao

  override fun messageDao(): MessageDao =  _MessageDao

  override fun peerReviewerAllocationDao(): PeerReviewerAllocationDao =  _PeerReviewerAllocationDao

  override fun discussionPostDao(): DiscussionPostDao =  _DiscussionPostDao

  override fun externalAppPermissionDao(): ExternalAppPermissionDao = throw
      IllegalStateException("ExternalAppPermissionDao is not annotated with @Repository")

  override fun contentEntryVersionDao(): ContentEntryVersionDao =  _ContentEntryVersionDao

  override fun outgoingReplicationDao(): OutgoingReplicationDao = throw
      IllegalStateException("OutgoingReplicationDao is not annotated with @Repository")

  override fun transferJobDao(): TransferJobDao = throw
      IllegalStateException("TransferJobDao is not annotated with @Repository")

  override fun transferJobItemDao(): TransferJobItemDao = throw
      IllegalStateException("TransferJobItemDao is not annotated with @Repository")

  override fun cacheLockJoinDao(): CacheLockJoinDao = throw
      IllegalStateException("CacheLockJoinDao is not annotated with @Repository")

  override fun offlineItemDao(): OfflineItemDao =  _OfflineItemDao

  override fun deletedItemDao(): DeletedItemDao =  _DeletedItemDao

  override fun enrolmentRequestDao(): EnrolmentRequestDao =  _EnrolmentRequestDao

  override fun coursePermissionDao(): CoursePermissionDao =  _CoursePermissionDao

  override fun systemPermissionDao(): SystemPermissionDao =  _SystemPermissionDao

  override fun courseBlockPictureDao(): CourseBlockPictureDao =  _CourseBlockPictureDao

  override fun contentEntryPicture2Dao(): ContentEntryPicture2Dao =  _ContentEntryPicture2Dao

  override fun transferJobErrorDao(): TransferJobErrorDao = throw
      IllegalStateException("TransferJobErrorDao is not annotated with @Repository")

  override fun studentResultDao(): StudentResultDao =  _StudentResultDao

  override fun verbLangMapEntryDao(): VerbLangMapEntryDao =  _VerbLangMapEntryDao

  override fun groupMemberActorJoinDao(): GroupMemberActorJoinDao =  _GroupMemberActorJoinDao

  override fun activityLangMapEntryDao(): ActivityLangMapEntryDao =  _ActivityLangMapEntryDao

  override fun activityInteractionDao(): ActivityInteractionDao =  _ActivityInteractionDao

  override fun activityExtensionDao(): ActivityExtensionDao =  _ActivityExtensionDao

  override fun statementContextActivityJoinDao(): StatementContextActivityJoinDao = 
      _StatementContextActivityJoinDao

  override fun xapiSessionEntityDao(): XapiSessionEntityDao =  _XapiSessionEntityDao

  override fun statementEntityJsonDao(): StatementEntityJsonDao =  _StatementEntityJsonDao

  override fun personPasskeyDao(): PersonPasskeyDao =  _PersonPasskeyDao

  override fun stateEntityDao(): StateEntityDao =  _StateEntityDao

  override fun stateDeleteCommandDao(): StateDeleteCommandDao =  _StateDeleteCommandDao

  public companion object {
    public const val _DB_NAME: String = "UmAppDatabase"
  }
}
