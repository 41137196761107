package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonPasskey
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class PersonPasskeyDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: PersonPasskeyDao,
) : PersonPasskeyDao() {
  override suspend fun insertAsync(personPasskey: PersonPasskey): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personPasskey.personPasskeyUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(892)
      personPasskey.personPasskeyUid = _newPk
    }
    personPasskey.ppPasskeyLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(personPasskey)
    return personPasskey.personPasskeyUid
  }

  override suspend fun allPasskey(): List<String> = _dao.allPasskey()

  override suspend fun findPersonPasskeyFromClientDataJson(id: String): PersonPasskey? =
      _dao.findPersonPasskeyFromClientDataJson(id)
}
