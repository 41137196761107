package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.getStringNonNull
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonPasskey
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class PersonPasskeyDao_JdbcImpl(
  public val _db: RoomDatabase,
) : PersonPasskeyDao() {
  public val _insertAdapterPersonPasskey_abort: EntityInsertionAdapter<PersonPasskey> = object :
      EntityInsertionAdapter<PersonPasskey>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO PersonPasskey (personPasskeyUid, ppPersonUid, ppAttestationObj, ppClientDataJson, ppOriginString, ppRpid, ppId, ppChallengeString, ppPublicKey, isRevoked, ppPasskeyLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonPasskey) {
      if(entity.personPasskeyUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personPasskeyUid)
      }
      stmt.setLong(2, entity.ppPersonUid)
      stmt.setString(3, entity.ppAttestationObj)
      stmt.setString(4, entity.ppClientDataJson)
      stmt.setString(5, entity.ppOriginString)
      stmt.setString(6, entity.ppRpid)
      stmt.setString(7, entity.ppId)
      stmt.setString(8, entity.ppChallengeString)
      stmt.setString(9, entity.ppPublicKey)
      stmt.setInt(10, entity.isRevoked)
      stmt.setLong(11, entity.ppPasskeyLct)
    }
  }

  override suspend fun insertAsync(personPasskey: PersonPasskey): Long {
    val _retVal = _insertAdapterPersonPasskey_abort.insertAndReturnIdAsync(personPasskey)
    return _retVal
  }

  override suspend fun allPasskey(): List<String> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT PersonPasskey.ppId
    |          FROM PersonPasskey
    |              
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getStringNonNull(1)
      }
    }
  }

  override suspend fun findPersonPasskeyFromClientDataJson(id: String): PersonPasskey? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT *
    |          FROM PersonPasskey
    |         WHERE PersonPasskey.ppId = ? 
    |              
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,id)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personPasskeyUid = _result.getLong("personPasskeyUid")
        val _tmp_ppPersonUid = _result.getLong("ppPersonUid")
        val _tmp_ppAttestationObj = _result.getString("ppAttestationObj")
        val _tmp_ppClientDataJson = _result.getString("ppClientDataJson")
        val _tmp_ppOriginString = _result.getString("ppOriginString")
        val _tmp_ppRpid = _result.getString("ppRpid")
        val _tmp_ppId = _result.getString("ppId")
        val _tmp_ppChallengeString = _result.getString("ppChallengeString")
        val _tmp_ppPublicKey = _result.getString("ppPublicKey")
        val _tmp_isRevoked = _result.getInt("isRevoked")
        val _tmp_ppPasskeyLct = _result.getLong("ppPasskeyLct")
        PersonPasskey().apply {
          this.personPasskeyUid = _tmp_personPasskeyUid
          this.ppPersonUid = _tmp_ppPersonUid
          this.ppAttestationObj = _tmp_ppAttestationObj
          this.ppClientDataJson = _tmp_ppClientDataJson
          this.ppOriginString = _tmp_ppOriginString
          this.ppRpid = _tmp_ppRpid
          this.ppId = _tmp_ppId
          this.ppChallengeString = _tmp_ppChallengeString
          this.ppPublicKey = _tmp_ppPublicKey
          this.isRevoked = _tmp_isRevoked
          this.ppPasskeyLct = _tmp_ppPasskeyLct
        }
      }
    }
  }
}
