package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.CacheLockJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_JdbcImpl
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_JdbcImpl
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_JdbcImpl
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_JdbcImpl
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_JdbcImpl
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_JdbcImpl
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePermissionDao_JdbcImpl
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_JdbcImpl
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_JdbcImpl
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DeletedItemDao_JdbcImpl
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_JdbcImpl
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao_JdbcImpl
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_JdbcImpl
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao_JdbcImpl
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_JdbcImpl
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_JdbcImpl
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_JdbcImpl
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_JdbcImpl
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_JdbcImpl
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_JdbcImpl
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OfflineItemDao_JdbcImpl
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.OutgoingReplicationDao_JdbcImpl
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonAuthDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonPasskeyDao
import com.ustadmobile.core.db.dao.PersonPasskeyDao_JdbcImpl
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_JdbcImpl
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_JdbcImpl
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_JdbcImpl
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_JdbcImpl
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_JdbcImpl
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_JdbcImpl
import com.ustadmobile.core.db.dao.StudentResultDao
import com.ustadmobile.core.db.dao.StudentResultDao_JdbcImpl
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SyncNodeDao_JdbcImpl
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.SystemPermissionDao_JdbcImpl
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobDao_JdbcImpl
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao_JdbcImpl
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.TransferJobItemDao_JdbcImpl
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao
import com.ustadmobile.core.db.dao.xapi.ActivityEntityDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao
import com.ustadmobile.core.db.dao.xapi.ActivityExtensionDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao
import com.ustadmobile.core.db.dao.xapi.ActivityInteractionDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.ActivityLangMapEntryDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.ActorDao
import com.ustadmobile.core.db.dao.xapi.ActorDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao
import com.ustadmobile.core.db.dao.xapi.GroupMemberActorJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao
import com.ustadmobile.core.db.dao.xapi.StateDeleteCommandDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.StateEntityDao
import com.ustadmobile.core.db.dao.xapi.StateEntityDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao
import com.ustadmobile.core.db.dao.xapi.StatementContextActivityJoinDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.StatementDao
import com.ustadmobile.core.db.dao.xapi.StatementDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao
import com.ustadmobile.core.db.dao.xapi.StatementEntityJsonDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.VerbDao
import com.ustadmobile.core.db.dao.xapi.VerbDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao
import com.ustadmobile.core.db.dao.xapi.VerbLangMapEntryDao_JdbcImpl
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao
import com.ustadmobile.core.db.dao.xapi.XapiSessionEntityDao_JdbcImpl
import com.ustadmobile.door.DoorDatabaseJdbc
import com.ustadmobile.door.DoorPrimaryKeyManager
import com.ustadmobile.door.ext.doorDatabaseMetadata
import com.ustadmobile.door.ext.mutableLinkedListOf
import com.ustadmobile.door.ext.nodeIdAuthCache
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.jdbc.DataSource
import com.ustadmobile.door.log.DoorLogger
import com.ustadmobile.door.room.InvalidationTracker
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.room.RoomDatabaseJdbcImplHelper
import com.ustadmobile.door.room.RoomJdbcImpl
import com.ustadmobile.door.util.NodeIdAuthCache
import com.ustadmobile.door.util.systemTimeInMillis
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.collections.toTypedArray

public class UmAppDatabase_JdbcImpl(
  override val doorJdbcSourceDatabase: RoomDatabase?,
  override val dataSource: DataSource,
  dbUrl: String,
  override val dbName: String,
  override val jdbcQueryTimeout: Int,
  jdbcDbType: Int,
  logger: DoorLogger,
) : UmAppDatabase(), DoorDatabaseJdbc, RoomJdbcImpl {
  override val dbVersion: Int
    get() = 202

  override val jdbcImplHelper: RoomDatabaseJdbcImplHelper = RoomDatabaseJdbcImplHelper(
        dataSource = dataSource,
        db = this,
        dbUrl = dbUrl,
        dbName = dbName,
        logger = logger,
        tableNames = this::class.doorDatabaseMetadata().allTables,
        invalidationTracker =
            InvalidationTracker(*this::class.doorDatabaseMetadata().allTables.toTypedArray()),
        dbType = jdbcDbType,
      )


  override val realNodeIdAuthCache: NodeIdAuthCache by lazy {
        if(this == rootDatabase) {
          val nodeIdAuthCache = NodeIdAuthCache(this)
          nodeIdAuthCache
        } else {
          rootDatabase.nodeIdAuthCache
        }
      }


  override val realPrimaryKeyManager: DoorPrimaryKeyManager by lazy {
        DoorPrimaryKeyManager(UmAppDatabase::class.doorDatabaseMetadata().replicateEntities.keys)
      }


  override val invalidationTracker: InvalidationTracker
    get() = jdbcImplHelper.invalidationTracker

  public val _PersonDao: PersonDao_JdbcImpl by lazy { PersonDao_JdbcImpl(this) }

  public val _ClazzDao: ClazzDao_JdbcImpl by lazy { ClazzDao_JdbcImpl(this) }

  public val _CourseBlockDao: CourseBlockDao_JdbcImpl by lazy { CourseBlockDao_JdbcImpl(this) }

  public val _CourseTerminologyDao: CourseTerminologyDao_JdbcImpl by
      lazy { CourseTerminologyDao_JdbcImpl(this) }

  public val _CourseGroupSetDao: CourseGroupSetDao_JdbcImpl by
      lazy { CourseGroupSetDao_JdbcImpl(this) }

  public val _CourseGroupMemberDao: CourseGroupMemberDao_JdbcImpl by
      lazy { CourseGroupMemberDao_JdbcImpl(this) }

  public val _ClazzEnrolmentDao: ClazzEnrolmentDao_JdbcImpl by
      lazy { ClazzEnrolmentDao_JdbcImpl(this) }

  public val _LeavingReasonDao: LeavingReasonDao_JdbcImpl by
      lazy { LeavingReasonDao_JdbcImpl(this) }

  public val _ContentEntryDao: ContentEntryDao_JdbcImpl by lazy { ContentEntryDao_JdbcImpl(this) }

  public val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao_JdbcImpl by
      lazy { ContentEntryContentCategoryJoinDao_JdbcImpl(this) }

  public val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao_JdbcImpl by
      lazy { ContentEntryParentChildJoinDao_JdbcImpl(this) }

  public val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao_JdbcImpl by
      lazy { ContentEntryRelatedEntryJoinDao_JdbcImpl(this) }

  public val _ContentCategorySchemaDao: ContentCategorySchemaDao_JdbcImpl by
      lazy { ContentCategorySchemaDao_JdbcImpl(this) }

  public val _ContentCategoryDao: ContentCategoryDao_JdbcImpl by
      lazy { ContentCategoryDao_JdbcImpl(this) }

  public val _LanguageDao: LanguageDao_JdbcImpl by lazy { LanguageDao_JdbcImpl(this) }

  public val _LanguageVariantDao: LanguageVariantDao_JdbcImpl by
      lazy { LanguageVariantDao_JdbcImpl(this) }

  public val _PersonAuthDao: PersonAuthDao_JdbcImpl by lazy { PersonAuthDao_JdbcImpl(this) }

  public val _PersonGroupDao: PersonGroupDao_JdbcImpl by lazy { PersonGroupDao_JdbcImpl(this) }

  public val _PersonGroupMemberDao: PersonGroupMemberDao_JdbcImpl by
      lazy { PersonGroupMemberDao_JdbcImpl(this) }

  public val _PersonPictureDao: PersonPictureDao_JdbcImpl by
      lazy { PersonPictureDao_JdbcImpl(this) }

  public val _VerbDao: VerbDao_JdbcImpl by lazy { VerbDao_JdbcImpl(this) }

  public val _ActivityEntityDao: ActivityEntityDao_JdbcImpl by
      lazy { ActivityEntityDao_JdbcImpl(this) }

  public val _ReportDao: ReportDao_JdbcImpl by lazy { ReportDao_JdbcImpl(this) }

  public val _StatementDao: StatementDao_JdbcImpl by lazy { StatementDao_JdbcImpl(this) }

  public val _ActorDao: ActorDao_JdbcImpl by lazy { ActorDao_JdbcImpl(this) }

  public val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao_JdbcImpl by
      lazy { ClazzLogAttendanceRecordDao_JdbcImpl(this) }

  public val _ClazzLogDao: ClazzLogDao_JdbcImpl by lazy { ClazzLogDao_JdbcImpl(this) }

  public val _ScheduleDao: ScheduleDao_JdbcImpl by lazy { ScheduleDao_JdbcImpl(this) }

  public val _HolidayCalendarDao: HolidayCalendarDao_JdbcImpl by
      lazy { HolidayCalendarDao_JdbcImpl(this) }

  public val _HolidayDao: HolidayDao_JdbcImpl by lazy { HolidayDao_JdbcImpl(this) }

  public val _ClazzAssignmentDao: ClazzAssignmentDao_JdbcImpl by
      lazy { ClazzAssignmentDao_JdbcImpl(this) }

  public val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao_JdbcImpl by
      lazy { CourseAssignmentSubmissionDao_JdbcImpl(this) }

  public val _CourseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao_JdbcImpl by
      lazy { CourseAssignmentSubmissionFileDao_JdbcImpl(this) }

  public val _CourseAssignmentMarkDao: CourseAssignmentMarkDao_JdbcImpl by
      lazy { CourseAssignmentMarkDao_JdbcImpl(this) }

  public val _CommentsDao: CommentsDao_JdbcImpl by lazy { CommentsDao_JdbcImpl(this) }

  public val _SyncNodeDao: SyncNodeDao_JdbcImpl by lazy { SyncNodeDao_JdbcImpl(this) }

  public val _SiteDao: SiteDao_JdbcImpl by lazy { SiteDao_JdbcImpl(this) }

  public val _SiteTermsDao: SiteTermsDao_JdbcImpl by lazy { SiteTermsDao_JdbcImpl(this) }

  public val _PersonParentJoinDao: PersonParentJoinDao_JdbcImpl by
      lazy { PersonParentJoinDao_JdbcImpl(this) }

  public val _ScopedGrantDao: ScopedGrantDao_JdbcImpl by lazy { ScopedGrantDao_JdbcImpl(this) }

  public val _ErrorReportDao: ErrorReportDao_JdbcImpl by lazy { ErrorReportDao_JdbcImpl(this) }

  public val _PersonAuth2Dao: PersonAuth2Dao_JdbcImpl by lazy { PersonAuth2Dao_JdbcImpl(this) }

  public val _UserSessionDao: UserSessionDao_JdbcImpl by lazy { UserSessionDao_JdbcImpl(this) }

  public val _ContentEntryImportJobDao: ContentEntryImportJobDao_JdbcImpl by
      lazy { ContentEntryImportJobDao_JdbcImpl(this) }

  public val _CoursePictureDao: CoursePictureDao_JdbcImpl by
      lazy { CoursePictureDao_JdbcImpl(this) }

  public val _ContentEntryPictureDao: ContentEntryPictureDao_JdbcImpl by
      lazy { ContentEntryPictureDao_JdbcImpl(this) }

  public val _MessageDao: MessageDao_JdbcImpl by lazy { MessageDao_JdbcImpl(this) }

  public val _PeerReviewerAllocationDao: PeerReviewerAllocationDao_JdbcImpl by
      lazy { PeerReviewerAllocationDao_JdbcImpl(this) }

  public val _DiscussionPostDao: DiscussionPostDao_JdbcImpl by
      lazy { DiscussionPostDao_JdbcImpl(this) }

  public val _ExternalAppPermissionDao: ExternalAppPermissionDao_JdbcImpl by
      lazy { ExternalAppPermissionDao_JdbcImpl(this) }

  public val _ContentEntryVersionDao: ContentEntryVersionDao_JdbcImpl by
      lazy { ContentEntryVersionDao_JdbcImpl(this) }

  public val _OutgoingReplicationDao: OutgoingReplicationDao_JdbcImpl by
      lazy { OutgoingReplicationDao_JdbcImpl(this) }

  public val _TransferJobDao: TransferJobDao_JdbcImpl by lazy { TransferJobDao_JdbcImpl(this) }

  public val _TransferJobItemDao: TransferJobItemDao_JdbcImpl by
      lazy { TransferJobItemDao_JdbcImpl(this) }

  public val _CacheLockJoinDao: CacheLockJoinDao_JdbcImpl by
      lazy { CacheLockJoinDao_JdbcImpl(this) }

  public val _OfflineItemDao: OfflineItemDao_JdbcImpl by lazy { OfflineItemDao_JdbcImpl(this) }

  public val _DeletedItemDao: DeletedItemDao_JdbcImpl by lazy { DeletedItemDao_JdbcImpl(this) }

  public val _EnrolmentRequestDao: EnrolmentRequestDao_JdbcImpl by
      lazy { EnrolmentRequestDao_JdbcImpl(this) }

  public val _CoursePermissionDao: CoursePermissionDao_JdbcImpl by
      lazy { CoursePermissionDao_JdbcImpl(this) }

  public val _SystemPermissionDao: SystemPermissionDao_JdbcImpl by
      lazy { SystemPermissionDao_JdbcImpl(this) }

  public val _CourseBlockPictureDao: CourseBlockPictureDao_JdbcImpl by
      lazy { CourseBlockPictureDao_JdbcImpl(this) }

  public val _ContentEntryPicture2Dao: ContentEntryPicture2Dao_JdbcImpl by
      lazy { ContentEntryPicture2Dao_JdbcImpl(this) }

  public val _TransferJobErrorDao: TransferJobErrorDao_JdbcImpl by
      lazy { TransferJobErrorDao_JdbcImpl(this) }

  public val _StudentResultDao: StudentResultDao_JdbcImpl by
      lazy { StudentResultDao_JdbcImpl(this) }

  public val _VerbLangMapEntryDao: VerbLangMapEntryDao_JdbcImpl by
      lazy { VerbLangMapEntryDao_JdbcImpl(this) }

  public val _GroupMemberActorJoinDao: GroupMemberActorJoinDao_JdbcImpl by
      lazy { GroupMemberActorJoinDao_JdbcImpl(this) }

  public val _ActivityLangMapEntryDao: ActivityLangMapEntryDao_JdbcImpl by
      lazy { ActivityLangMapEntryDao_JdbcImpl(this) }

  public val _ActivityInteractionDao: ActivityInteractionDao_JdbcImpl by
      lazy { ActivityInteractionDao_JdbcImpl(this) }

  public val _ActivityExtensionDao: ActivityExtensionDao_JdbcImpl by
      lazy { ActivityExtensionDao_JdbcImpl(this) }

  public val _StatementContextActivityJoinDao: StatementContextActivityJoinDao_JdbcImpl by
      lazy { StatementContextActivityJoinDao_JdbcImpl(this) }

  public val _XapiSessionEntityDao: XapiSessionEntityDao_JdbcImpl by
      lazy { XapiSessionEntityDao_JdbcImpl(this) }

  public val _StatementEntityJsonDao: StatementEntityJsonDao_JdbcImpl by
      lazy { StatementEntityJsonDao_JdbcImpl(this) }

  public val _PersonPasskeyDao: PersonPasskeyDao_JdbcImpl by
      lazy { PersonPasskeyDao_JdbcImpl(this) }

  public val _StateEntityDao: StateEntityDao_JdbcImpl by lazy { StateEntityDao_JdbcImpl(this) }

  public val _StateDeleteCommandDao: StateDeleteCommandDao_JdbcImpl by
      lazy { StateDeleteCommandDao_JdbcImpl(this) }

  override fun createAllTables(): List<String> {
    val _stmtList = mutableLinkedListOf<String>()
    when(jdbcImplHelper.dbType) {
      1 ->  {
        // - create for this SQLite 
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS _doorwayinfo (dbVersion int primary key, dbHash varchar(255))"
         _stmtList += "INSERT INTO _doorwayinfo VALUES (202, '')"
        //Begin: Create table ClazzLog for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzLog RENAME to ClazzLog_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzLog (  clazzLogClazzUid  INTEGER  NOT NULL , logDate  INTEGER  NOT NULL , timeRecorded  INTEGER  NOT NULL , clazzLogDone  INTEGER  NOT NULL , cancellationNote  TEXT , clazzLogCancelled  INTEGER  NOT NULL , clazzLogNumPresent  INTEGER  NOT NULL , clazzLogNumAbsent  INTEGER  NOT NULL , clazzLogNumPartial  INTEGER  NOT NULL , clazzLogScheduleUid  INTEGER  NOT NULL , clazzLogStatusFlag  INTEGER  NOT NULL , clazzLogMSQN  INTEGER  NOT NULL , clazzLogLCSN  INTEGER  NOT NULL , clazzLogLCB  INTEGER  NOT NULL , clazzLogLastChangedTime  INTEGER  NOT NULL , clazzLogUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzLog (clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime, clazzLogUid) SELECT clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime, clazzLogUid FROM ClazzLog_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzLog_OLD")
        END MIGRATION*/
        //End: Create table ClazzLog for SQLite

        //Begin: Create table ClazzLogAttendanceRecord for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzLogAttendanceRecord RENAME to ClazzLogAttendanceRecord_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzLogAttendanceRecord (  clazzLogAttendanceRecordClazzLogUid  INTEGER  NOT NULL , clazzLogAttendanceRecordPersonUid  INTEGER  NOT NULL , attendanceStatus  INTEGER  NOT NULL , clazzLogAttendanceRecordMasterChangeSeqNum  INTEGER  NOT NULL , clazzLogAttendanceRecordLocalChangeSeqNum  INTEGER  NOT NULL , clazzLogAttendanceRecordLastChangedBy  INTEGER  NOT NULL , clazzLogAttendanceRecordLastChangedTime  INTEGER  NOT NULL , clazzLogAttendanceRecordUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime, clazzLogAttendanceRecordUid) SELECT clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime, clazzLogAttendanceRecordUid FROM ClazzLogAttendanceRecord_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzLogAttendanceRecord_OLD")
        END MIGRATION*/
        //End: Create table ClazzLogAttendanceRecord for SQLite

        //Begin: Create table Schedule for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Schedule RENAME to Schedule_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Schedule (  sceduleStartTime  INTEGER  NOT NULL , scheduleEndTime  INTEGER  NOT NULL , scheduleDay  INTEGER  NOT NULL , scheduleMonth  INTEGER  NOT NULL , scheduleFrequency  INTEGER  NOT NULL , umCalendarUid  INTEGER  NOT NULL , scheduleClazzUid  INTEGER  NOT NULL , scheduleMasterChangeSeqNum  INTEGER  NOT NULL , scheduleLocalChangeSeqNum  INTEGER  NOT NULL , scheduleLastChangedBy  INTEGER  NOT NULL , scheduleLastChangedTime  INTEGER  NOT NULL , scheduleActive  INTEGER  NOT NULL , scheduleUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Schedule (sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive, scheduleUid) SELECT sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive, scheduleUid FROM Schedule_OLD")
        _stmt.executeUpdate("DROP TABLE Schedule_OLD")
        END MIGRATION*/
        //End: Create table Schedule for SQLite

        //Begin: Create table HolidayCalendar for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE HolidayCalendar RENAME to HolidayCalendar_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS HolidayCalendar (  umCalendarName  TEXT , umCalendarCategory  INTEGER  NOT NULL , umCalendarActive  INTEGER  NOT NULL , umCalendarMasterChangeSeqNum  INTEGER  NOT NULL , umCalendarLocalChangeSeqNum  INTEGER  NOT NULL , umCalendarLastChangedBy  INTEGER  NOT NULL , umCalendarLct  INTEGER  NOT NULL , umCalendarUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO HolidayCalendar (umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct, umCalendarUid) SELECT umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct, umCalendarUid FROM HolidayCalendar_OLD")
        _stmt.executeUpdate("DROP TABLE HolidayCalendar_OLD")
        END MIGRATION*/
        //End: Create table HolidayCalendar for SQLite

        //Begin: Create table Holiday for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Holiday RENAME to Holiday_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Holiday (  holMasterCsn  INTEGER  NOT NULL , holLocalCsn  INTEGER  NOT NULL , holLastModBy  INTEGER  NOT NULL , holLct  INTEGER  NOT NULL , holActive  INTEGER  NOT NULL , holHolidayCalendarUid  INTEGER  NOT NULL , holStartTime  INTEGER  NOT NULL , holEndTime  INTEGER  NOT NULL , holName  TEXT , holUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Holiday (holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName, holUid) SELECT holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName, holUid FROM Holiday_OLD")
        _stmt.executeUpdate("DROP TABLE Holiday_OLD")
        END MIGRATION*/
        //End: Create table Holiday for SQLite

        //Begin: Create table Person for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Person RENAME to Person_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Person (  username  TEXT , firstNames  TEXT , lastName  TEXT , emailAddr  TEXT , phoneNum  TEXT , gender  INTEGER  NOT NULL , active  INTEGER  NOT NULL , isPersonalAccount  INTEGER  NOT NULL , dateOfBirth  INTEGER  NOT NULL , personAddress  TEXT , personOrgId  TEXT , personGroupUid  INTEGER  NOT NULL , personLct  INTEGER  NOT NULL , personCountry  TEXT , personType  INTEGER  NOT NULL  DEFAULT 0 , personMasterChangeSeqNum  INTEGER  NOT NULL , personLocalChangeSeqNum  INTEGER  NOT NULL , personLastChangedBy  INTEGER  NOT NULL , admin  INTEGER  NOT NULL , personNotes  TEXT , fatherName  TEXT , fatherNumber  TEXT , motherName  TEXT , motherNum  TEXT , personUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Person (username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, personUid) SELECT username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, personUid FROM Person_OLD")
        _stmt.executeUpdate("DROP TABLE Person_OLD")
        END MIGRATION*/
        //End: Create table Person for SQLite

        //Begin: Create table Clazz for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Clazz RENAME to Clazz_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Clazz (  clazzName  TEXT , clazzDesc  TEXT , attendanceAverage  REAl  NOT NULL , clazzHolidayUMCalendarUid  INTEGER  NOT NULL , clazzScheuleUMCalendarUid  INTEGER  NOT NULL , isClazzActive  INTEGER  NOT NULL , clazzLocationUid  INTEGER  NOT NULL , clazzStartTime  INTEGER  NOT NULL , clazzEndTime  INTEGER  NOT NULL , clazzFeatures  INTEGER  NOT NULL , clazzSchoolUid  INTEGER  NOT NULL , clazzEnrolmentPolicy  INTEGER  NOT NULL  DEFAULT 102 , clazzTerminologyUid  INTEGER  NOT NULL  DEFAULT 25966 , clazzMasterChangeSeqNum  INTEGER  NOT NULL , clazzLocalChangeSeqNum  INTEGER  NOT NULL , clazzLastChangedBy  INTEGER  NOT NULL , clazzLct  INTEGER  NOT NULL , clazzTimeZone  TEXT , clazzStudentsPersonGroupUid  INTEGER  NOT NULL , clazzTeachersPersonGroupUid  INTEGER  NOT NULL , clazzPendingStudentsPersonGroupUid  INTEGER  NOT NULL , clazzParentsPersonGroupUid  INTEGER  NOT NULL , clazzCode  TEXT , clazzOwnerPersonUid  INTEGER  NOT NULL  DEFAULT 0 , clazzUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Clazz (clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode, clazzOwnerPersonUid, clazzUid) SELECT clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode, clazzOwnerPersonUid, clazzUid FROM Clazz_OLD")
        _stmt.executeUpdate("DROP TABLE Clazz_OLD")
        END MIGRATION*/
        //End: Create table Clazz for SQLite

        //Begin: Create table ClazzEnrolment for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzEnrolment RENAME to ClazzEnrolment_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzEnrolment (  clazzEnrolmentPersonUid  INTEGER  NOT NULL , clazzEnrolmentClazzUid  INTEGER  NOT NULL , clazzEnrolmentDateJoined  INTEGER  NOT NULL , clazzEnrolmentDateLeft  INTEGER  NOT NULL , clazzEnrolmentRole  INTEGER  NOT NULL , clazzEnrolmentAttendancePercentage  REAl  NOT NULL , clazzEnrolmentActive  INTEGER  NOT NULL , clazzEnrolmentLeavingReasonUid  INTEGER  NOT NULL , clazzEnrolmentOutcome  INTEGER  NOT NULL , clazzEnrolmentLocalChangeSeqNum  INTEGER  NOT NULL , clazzEnrolmentMasterChangeSeqNum  INTEGER  NOT NULL , clazzEnrolmentLastChangedBy  INTEGER  NOT NULL , clazzEnrolmentLct  INTEGER  NOT NULL , clazzEnrolmentUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentPersonUid_clazzEnrolmentClazzUid ON ClazzEnrolment (clazzEnrolmentPersonUid, clazzEnrolmentClazzUid)"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentClazzUid_clazzEnrolmentPersonUid ON ClazzEnrolment (clazzEnrolmentClazzUid, clazzEnrolmentPersonUid)"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentClazzUid_clazzEnrolmentRole ON ClazzEnrolment (clazzEnrolmentClazzUid, clazzEnrolmentRole)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzEnrolment (clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct, clazzEnrolmentUid) SELECT clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct, clazzEnrolmentUid FROM ClazzEnrolment_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzEnrolment_OLD")
        END MIGRATION*/
        //End: Create table ClazzEnrolment for SQLite

        //Begin: Create table LeavingReason for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE LeavingReason RENAME to LeavingReason_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS LeavingReason (  leavingReasonTitle  TEXT , leavingReasonMCSN  INTEGER  NOT NULL , leavingReasonCSN  INTEGER  NOT NULL , leavingReasonLCB  INTEGER  NOT NULL , leavingReasonLct  INTEGER  NOT NULL , leavingReasonUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO LeavingReason (leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct, leavingReasonUid) SELECT leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct, leavingReasonUid FROM LeavingReason_OLD")
        _stmt.executeUpdate("DROP TABLE LeavingReason_OLD")
        END MIGRATION*/
        //End: Create table LeavingReason for SQLite

        //Begin: Create table ContentEntry for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntry RENAME to ContentEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntry (  title  TEXT , description  TEXT , entryId  TEXT , author  TEXT , publisher  TEXT , licenseType  INTEGER  NOT NULL , licenseName  TEXT , licenseUrl  TEXT , sourceUrl  TEXT , thumbnailUrl  TEXT , lastModified  INTEGER  NOT NULL , primaryLanguageUid  INTEGER  NOT NULL , languageVariantUid  INTEGER  NOT NULL , contentFlags  INTEGER  NOT NULL , leaf  INTEGER  NOT NULL , publik  INTEGER  NOT NULL , ceInactive  INTEGER  NOT NULL , completionCriteria  INTEGER  NOT NULL , minScore  INTEGER  NOT NULL , contentTypeFlag  INTEGER  NOT NULL , contentOwner  INTEGER  NOT NULL , contentOwnerType  INTEGER  NOT NULL  DEFAULT 1 , contentEntryLocalChangeSeqNum  INTEGER  NOT NULL , contentEntryMasterChangeSeqNum  INTEGER  NOT NULL , contentEntryLastChangedBy  INTEGER  NOT NULL , contentEntryLct  INTEGER  NOT NULL , contentEntryUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntry (title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct, contentEntryUid) SELECT title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct, contentEntryUid FROM ContentEntry_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntry_OLD")
        END MIGRATION*/
        //End: Create table ContentEntry for SQLite

        //Begin: Create table ContentEntryContentCategoryJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryContentCategoryJoin RENAME to ContentEntryContentCategoryJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryContentCategoryJoin (  ceccjContentEntryUid  INTEGER  NOT NULL , ceccjContentCategoryUid  INTEGER  NOT NULL , ceccjLocalChangeSeqNum  INTEGER  NOT NULL , ceccjMasterChangeSeqNum  INTEGER  NOT NULL , ceccjLastChangedBy  INTEGER  NOT NULL , ceccjLct  INTEGER  NOT NULL , ceccjUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryContentCategoryJoin (ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct, ceccjUid) SELECT ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct, ceccjUid FROM ContentEntryContentCategoryJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryContentCategoryJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryContentCategoryJoin for SQLite

        //Begin: Create table ContentEntryParentChildJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryParentChildJoin RENAME to ContentEntryParentChildJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryParentChildJoin (  cepcjLocalChangeSeqNum  INTEGER  NOT NULL , cepcjMasterChangeSeqNum  INTEGER  NOT NULL , cepcjLastChangedBy  INTEGER  NOT NULL , cepcjLct  INTEGER  NOT NULL , cepcjDeleted  INTEGER  NOT NULL  DEFAULT 0 , cepcjParentContentEntryUid  INTEGER  NOT NULL , cepcjChildContentEntryUid  INTEGER  NOT NULL , childIndex  INTEGER  NOT NULL , cepcjUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX parent_child ON ContentEntryParentChildJoin (cepcjChildContentEntryUid, cepcjParentContentEntryUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryParentChildJoin (cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid) SELECT cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid FROM ContentEntryParentChildJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryParentChildJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryParentChildJoin for SQLite

        //Begin: Create table ContentEntryRelatedEntryJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryRelatedEntryJoin RENAME to ContentEntryRelatedEntryJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryRelatedEntryJoin (  cerejContentEntryUid  INTEGER  NOT NULL , cerejRelatedEntryUid  INTEGER  NOT NULL , cerejLastChangedBy  INTEGER  NOT NULL , relType  INTEGER  NOT NULL , comment  TEXT , cerejRelLanguageUid  INTEGER  NOT NULL , cerejLocalChangeSeqNum  INTEGER  NOT NULL , cerejMasterChangeSeqNum  INTEGER  NOT NULL , cerejLct  INTEGER  NOT NULL , cerejUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryRelatedEntryJoin (cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct, cerejUid) SELECT cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct, cerejUid FROM ContentEntryRelatedEntryJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryRelatedEntryJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryRelatedEntryJoin for SQLite

        //Begin: Create table ContentCategorySchema for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentCategorySchema RENAME to ContentCategorySchema_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentCategorySchema (  schemaName  TEXT , schemaUrl  TEXT , contentCategorySchemaLocalChangeSeqNum  INTEGER  NOT NULL , contentCategorySchemaMasterChangeSeqNum  INTEGER  NOT NULL , contentCategorySchemaLastChangedBy  INTEGER  NOT NULL , contentCategorySchemaLct  INTEGER  NOT NULL , contentCategorySchemaUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentCategorySchema (schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct, contentCategorySchemaUid) SELECT schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct, contentCategorySchemaUid FROM ContentCategorySchema_OLD")
        _stmt.executeUpdate("DROP TABLE ContentCategorySchema_OLD")
        END MIGRATION*/
        //End: Create table ContentCategorySchema for SQLite

        //Begin: Create table ContentCategory for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentCategory RENAME to ContentCategory_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentCategory (  ctnCatContentCategorySchemaUid  INTEGER  NOT NULL , name  TEXT , contentCategoryLocalChangeSeqNum  INTEGER  NOT NULL , contentCategoryMasterChangeSeqNum  INTEGER  NOT NULL , contentCategoryLastChangedBy  INTEGER  NOT NULL , contentCategoryLct  INTEGER  NOT NULL , contentCategoryUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentCategory (ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct, contentCategoryUid) SELECT ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct, contentCategoryUid FROM ContentCategory_OLD")
        _stmt.executeUpdate("DROP TABLE ContentCategory_OLD")
        END MIGRATION*/
        //End: Create table ContentCategory for SQLite

        //Begin: Create table Language for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Language RENAME to Language_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Language (  name  TEXT , iso_639_1_standard  TEXT , iso_639_2_standard  TEXT , iso_639_3_standard  TEXT , Language_Type  TEXT , languageActive  INTEGER  NOT NULL , langLocalChangeSeqNum  INTEGER  NOT NULL , langMasterChangeSeqNum  INTEGER  NOT NULL , langLastChangedBy  INTEGER  NOT NULL , langLct  INTEGER  NOT NULL , langUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Language (name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct, langUid) SELECT name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct, langUid FROM Language_OLD")
        _stmt.executeUpdate("DROP TABLE Language_OLD")
        END MIGRATION*/
        //End: Create table Language for SQLite

        //Begin: Create table LanguageVariant for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE LanguageVariant RENAME to LanguageVariant_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS LanguageVariant (  langUid  INTEGER  NOT NULL , countryCode  TEXT , name  TEXT , langVariantLocalChangeSeqNum  INTEGER  NOT NULL , langVariantMasterChangeSeqNum  INTEGER  NOT NULL , langVariantLastChangedBy  INTEGER  NOT NULL , langVariantLct  INTEGER  NOT NULL , langVariantUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO LanguageVariant (langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct, langVariantUid) SELECT langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct, langVariantUid FROM LanguageVariant_OLD")
        _stmt.executeUpdate("DROP TABLE LanguageVariant_OLD")
        END MIGRATION*/
        //End: Create table LanguageVariant for SQLite

        //Begin: Create table PersonAuth for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonAuth RENAME to PersonAuth_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonAuth (  passwordHash  TEXT , personAuthStatus  INTEGER  NOT NULL , personAuthUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonAuth (passwordHash, personAuthStatus, personAuthUid) SELECT passwordHash, personAuthStatus, personAuthUid FROM PersonAuth_OLD")
        _stmt.executeUpdate("DROP TABLE PersonAuth_OLD")
        END MIGRATION*/
        //End: Create table PersonAuth for SQLite

        //Begin: Create table PersonGroup for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonGroup RENAME to PersonGroup_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonGroup (  groupMasterCsn  INTEGER  NOT NULL , groupLocalCsn  INTEGER  NOT NULL , groupLastChangedBy  INTEGER  NOT NULL , groupLct  INTEGER  NOT NULL , groupName  TEXT , groupActive  INTEGER  NOT NULL , personGroupFlag  INTEGER  NOT NULL , groupUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonGroup (groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag, groupUid) SELECT groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag, groupUid FROM PersonGroup_OLD")
        _stmt.executeUpdate("DROP TABLE PersonGroup_OLD")
        END MIGRATION*/
        //End: Create table PersonGroup for SQLite

        //Begin: Create table PersonGroupMember for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonGroupMember RENAME to PersonGroupMember_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonGroupMember (  groupMemberActive  INTEGER  NOT NULL , groupMemberPersonUid  INTEGER  NOT NULL , groupMemberGroupUid  INTEGER  NOT NULL , groupMemberMasterCsn  INTEGER  NOT NULL , groupMemberLocalCsn  INTEGER  NOT NULL , groupMemberLastChangedBy  INTEGER  NOT NULL , groupMemberLct  INTEGER  NOT NULL , groupMemberUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonGroupMember (groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct, groupMemberUid) SELECT groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct, groupMemberUid FROM PersonGroupMember_OLD")
        _stmt.executeUpdate("DROP TABLE PersonGroupMember_OLD")
        END MIGRATION*/
        //End: Create table PersonGroupMember for SQLite

        //Begin: Create table PersonPicture for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonPicture RENAME to PersonPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonPicture (  personPictureLct  INTEGER  NOT NULL , personPictureUri  TEXT , personPictureThumbnailUri  TEXT , fileSize  INTEGER  NOT NULL , personPictureActive  INTEGER  NOT NULL , personPictureUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonPicture (personPictureLct, personPictureUri, personPictureThumbnailUri, fileSize, personPictureActive, personPictureUid) SELECT personPictureLct, personPictureUri, personPictureThumbnailUri, fileSize, personPictureActive, personPictureUid FROM PersonPicture_OLD")
        _stmt.executeUpdate("DROP TABLE PersonPicture_OLD")
        END MIGRATION*/
        //End: Create table PersonPicture for SQLite

        //Begin: Create table VerbEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE VerbEntity RENAME to VerbEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS VerbEntity (  verbUid  INTEGER  PRIMARY KEY  NOT NULL , verbUrlId  TEXT , verbDeleted  INTEGER  NOT NULL , verbLct  INTEGER  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO VerbEntity (verbUid, verbUrlId, verbDeleted, verbLct) SELECT verbUid, verbUrlId, verbDeleted, verbLct FROM VerbEntity_OLD")
        _stmt.executeUpdate("DROP TABLE VerbEntity_OLD")
        END MIGRATION*/
        //End: Create table VerbEntity for SQLite

        //Begin: Create table ActivityEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityEntity RENAME to ActivityEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityEntity (  actUid  INTEGER  PRIMARY KEY  NOT NULL , actIdIri  TEXT , actType  TEXT , actMoreInfo  TEXT , actInteractionType  INTEGER  NOT NULL , actCorrectResponsePatterns  TEXT , actLct  INTEGER  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityEntity (actUid, actIdIri, actType, actMoreInfo, actInteractionType, actCorrectResponsePatterns, actLct) SELECT actUid, actIdIri, actType, actMoreInfo, actInteractionType, actCorrectResponsePatterns, actLct FROM ActivityEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityEntity for SQLite

        //Begin: Create table StatementEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementEntity RENAME to StatementEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementEntity (  statementIdHi  INTEGER  NOT NULL , statementIdLo  INTEGER  NOT NULL , statementActorPersonUid  INTEGER  NOT NULL , statementVerbUid  INTEGER  NOT NULL , statementObjectType  INTEGER  NOT NULL , statementObjectUid1  INTEGER  NOT NULL , statementObjectUid2  INTEGER  NOT NULL , statementActorUid  INTEGER  NOT NULL , authorityActorUid  INTEGER  NOT NULL , teamUid  INTEGER  NOT NULL , resultCompletion  INTEGER , resultSuccess  INTEGER , resultScoreScaled  REAl , resultScoreRaw  REAl , resultScoreMin  REAl , resultScoreMax  REAl , resultDuration  INTEGER , resultResponse  TEXT , timestamp  INTEGER  NOT NULL , stored  INTEGER  NOT NULL , contextRegistrationHi  INTEGER  NOT NULL , contextRegistrationLo  INTEGER  NOT NULL , contextPlatform  TEXT , contextStatementRefIdHi  INTEGER  NOT NULL , contextStatementRefIdLo  INTEGER  NOT NULL , contextInstructorActorUid  INTEGER  NOT NULL , statementLct  INTEGER  NOT NULL , extensionProgress  INTEGER , completionOrProgress  INTEGER  NOT NULL , statementContentEntryUid  INTEGER  NOT NULL , statementLearnerGroupUid  INTEGER  NOT NULL , statementClazzUid  INTEGER  NOT NULL , statementCbUid  INTEGER  NOT NULL , statementDoorNode  INTEGER  NOT NULL , isSubStatement  INTEGER  NOT NULL , PRIMARY KEY (statementIdHi, statementIdLo) )"
        _stmtList +=
            "CREATE INDEX idx_stmt_actor_person ON StatementEntity (statementActorPersonUid)"
        _stmtList +=
            "CREATE INDEX idx_statement_clazz_person ON StatementEntity (statementClazzUid, statementActorPersonUid)"
        _stmtList +=
            "CREATE INDEX idx_statement_cbuid_actor ON StatementEntity (statementCbUid, statementActorUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementEntity (statementIdHi, statementIdLo, statementActorPersonUid, statementVerbUid, statementObjectType, statementObjectUid1, statementObjectUid2, statementActorUid, authorityActorUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistrationHi, contextRegistrationLo, contextPlatform, contextStatementRefIdHi, contextStatementRefIdLo, contextInstructorActorUid, statementLct, extensionProgress, completionOrProgress, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid, statementCbUid, statementDoorNode, isSubStatement) SELECT statementIdHi, statementIdLo, statementActorPersonUid, statementVerbUid, statementObjectType, statementObjectUid1, statementObjectUid2, statementActorUid, authorityActorUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistrationHi, contextRegistrationLo, contextPlatform, contextStatementRefIdHi, contextStatementRefIdLo, contextInstructorActorUid, statementLct, extensionProgress, completionOrProgress, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid, statementCbUid, statementDoorNode, isSubStatement FROM StatementEntity_OLD")
        _stmt.executeUpdate("DROP TABLE StatementEntity_OLD")
        END MIGRATION*/
        //End: Create table StatementEntity for SQLite

        //Begin: Create table ActorEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActorEntity RENAME to ActorEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActorEntity (  actorPersonUid  INTEGER  NOT NULL , actorName  TEXT , actorMbox  TEXT , actorMbox_sha1sum  TEXT , actorOpenid  TEXT , actorAccountName  TEXT , actorAccountHomePage  TEXT , actorEtag  INTEGER  NOT NULL , actorLct  INTEGER  NOT NULL , actorObjectType  INTEGER  NOT NULL , actorUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList += "CREATE INDEX idx_actorentity_uid_personuid ON ActorEntity (actorPersonUid)"
        _stmtList += "CREATE INDEX idx_actorentity_actorobjecttype ON ActorEntity (actorObjectType)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActorEntity (actorPersonUid, actorName, actorMbox, actorMbox_sha1sum, actorOpenid, actorAccountName, actorAccountHomePage, actorEtag, actorLct, actorObjectType, actorUid) SELECT actorPersonUid, actorName, actorMbox, actorMbox_sha1sum, actorOpenid, actorAccountName, actorAccountHomePage, actorEtag, actorLct, actorObjectType, actorUid FROM ActorEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActorEntity_OLD")
        END MIGRATION*/
        //End: Create table ActorEntity for SQLite

        //Begin: Create table SyncNode for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SyncNode RENAME to SyncNode_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SyncNode (  nodeClientId  INTEGER  NOT NULL , PRIMARY KEY (nodeClientId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SyncNode (nodeClientId) SELECT nodeClientId FROM SyncNode_OLD")
        _stmt.executeUpdate("DROP TABLE SyncNode_OLD")
        END MIGRATION*/
        //End: Create table SyncNode for SQLite

        //Begin: Create table Comments for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Comments RENAME to Comments_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Comments (  commentsText  TEXT , commentsEntityUid  INTEGER  NOT NULL , commentsStatus  INTEGER  NOT NULL , commentsFromPersonUid  INTEGER  NOT NULL , commentsForSubmitterUid  INTEGER  NOT NULL , commentsFromSubmitterUid  INTEGER  NOT NULL , commentsFlagged  INTEGER  NOT NULL , commentsDeleted  INTEGER  NOT NULL , commentsDateTimeAdded  INTEGER  NOT NULL , commentsLct  INTEGER  NOT NULL , commentsUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_comments_entity_submitter ON Comments (commentsEntityUid, commentsForSubmitterUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Comments (commentsText, commentsEntityUid, commentsStatus, commentsFromPersonUid, commentsForSubmitterUid, commentsFromSubmitterUid, commentsFlagged, commentsDeleted, commentsDateTimeAdded, commentsLct, commentsUid) SELECT commentsText, commentsEntityUid, commentsStatus, commentsFromPersonUid, commentsForSubmitterUid, commentsFromSubmitterUid, commentsFlagged, commentsDeleted, commentsDateTimeAdded, commentsLct, commentsUid FROM Comments_OLD")
        _stmt.executeUpdate("DROP TABLE Comments_OLD")
        END MIGRATION*/
        //End: Create table Comments for SQLite

        //Begin: Create table Report for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Report RENAME to Report_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Report (  reportOwnerUid  INTEGER  NOT NULL , xAxis  INTEGER  NOT NULL , reportDateRangeSelection  INTEGER  NOT NULL , fromDate  INTEGER  NOT NULL , fromRelTo  INTEGER  NOT NULL , fromRelOffSet  INTEGER  NOT NULL , fromRelUnit  INTEGER  NOT NULL , toDate  INTEGER  NOT NULL , toRelTo  INTEGER  NOT NULL , toRelOffSet  INTEGER  NOT NULL , toRelUnit  INTEGER  NOT NULL , reportTitle  TEXT , reportDescription  TEXT , reportSeries  TEXT , reportInactive  INTEGER  NOT NULL , isTemplate  INTEGER  NOT NULL , priority  INTEGER  NOT NULL , reportTitleId  INTEGER  NOT NULL , reportDescId  INTEGER  NOT NULL , reportMasterChangeSeqNum  INTEGER  NOT NULL , reportLocalChangeSeqNum  INTEGER  NOT NULL , reportLastChangedBy  INTEGER  NOT NULL , reportLct  INTEGER  NOT NULL , reportUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Report (reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct, reportUid) SELECT reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct, reportUid FROM Report_OLD")
        _stmt.executeUpdate("DROP TABLE Report_OLD")
        END MIGRATION*/
        //End: Create table Report for SQLite

        //Begin: Create table Site for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Site RENAME to Site_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Site (  sitePcsn  INTEGER  NOT NULL , siteLcsn  INTEGER  NOT NULL , siteLcb  INTEGER  NOT NULL , siteLct  INTEGER  NOT NULL , siteName  TEXT , guestLogin  INTEGER  NOT NULL , registrationAllowed  INTEGER  NOT NULL , authSalt  TEXT , siteUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Site (sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt, siteUid) SELECT sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt, siteUid FROM Site_OLD")
        _stmt.executeUpdate("DROP TABLE Site_OLD")
        END MIGRATION*/
        //End: Create table Site for SQLite

        //Begin: Create table SiteTerms for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SiteTerms RENAME to SiteTerms_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SiteTerms (  termsHtml  TEXT , sTermsLang  TEXT , sTermsLangUid  INTEGER  NOT NULL , sTermsActive  INTEGER  NOT NULL , sTermsLastChangedBy  INTEGER  NOT NULL , sTermsPrimaryCsn  INTEGER  NOT NULL , sTermsLocalCsn  INTEGER  NOT NULL , sTermsLct  INTEGER  NOT NULL , sTermsUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SiteTerms (termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct, sTermsUid) SELECT termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct, sTermsUid FROM SiteTerms_OLD")
        _stmt.executeUpdate("DROP TABLE SiteTerms_OLD")
        END MIGRATION*/
        //End: Create table SiteTerms for SQLite

        //Begin: Create table PersonParentJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonParentJoin RENAME to PersonParentJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonParentJoin (  ppjPcsn  INTEGER  NOT NULL , ppjLcsn  INTEGER  NOT NULL , ppjLcb  INTEGER  NOT NULL , ppjLct  INTEGER  NOT NULL , ppjParentPersonUid  INTEGER  NOT NULL , ppjMinorPersonUid  INTEGER  NOT NULL , ppjRelationship  INTEGER  NOT NULL , ppjEmail  TEXT , ppjPhone  TEXT , ppjInactive  INTEGER  NOT NULL , ppjStatus  INTEGER  NOT NULL , ppjApprovalTiemstamp  INTEGER  NOT NULL , ppjApprovalIpAddr  TEXT , ppjUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonParentJoin (ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr, ppjUid) SELECT ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr, ppjUid FROM PersonParentJoin_OLD")
        _stmt.executeUpdate("DROP TABLE PersonParentJoin_OLD")
        END MIGRATION*/
        //End: Create table PersonParentJoin for SQLite

        //Begin: Create table ScopedGrant for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ScopedGrant RENAME to ScopedGrant_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ScopedGrant (  sgPcsn  INTEGER  NOT NULL , sgLcsn  INTEGER  NOT NULL , sgLcb  INTEGER  NOT NULL , sgLct  INTEGER  NOT NULL , sgTableId  INTEGER  NOT NULL , sgEntityUid  INTEGER  NOT NULL , sgPermissions  INTEGER  NOT NULL , sgGroupUid  INTEGER  NOT NULL , sgIndex  INTEGER  NOT NULL , sgFlags  INTEGER  NOT NULL , sgUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_group_to_entity ON ScopedGrant (sgGroupUid, sgPermissions, sgTableId, sgEntityUid)"
        _stmtList +=
            "CREATE INDEX idx_entity_to_group ON ScopedGrant (sgTableId, sgEntityUid, sgPermissions, sgGroupUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ScopedGrant (sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags, sgUid) SELECT sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags, sgUid FROM ScopedGrant_OLD")
        _stmt.executeUpdate("DROP TABLE ScopedGrant_OLD")
        END MIGRATION*/
        //End: Create table ScopedGrant for SQLite

        //Begin: Create table ErrorReport for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ErrorReport RENAME to ErrorReport_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ErrorReport (  errPcsn  INTEGER  NOT NULL , errLcsn  INTEGER  NOT NULL , errLcb  INTEGER  NOT NULL , errLct  INTEGER  NOT NULL , severity  INTEGER  NOT NULL , timestamp  INTEGER  NOT NULL , presenterUri  TEXT , appVersion  TEXT , versionCode  INTEGER  NOT NULL , errorCode  INTEGER  NOT NULL , operatingSys  TEXT , osVersion  TEXT , stackTrace  TEXT , message  TEXT , errUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ErrorReport (errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message, errUid) SELECT errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message, errUid FROM ErrorReport_OLD")
        _stmt.executeUpdate("DROP TABLE ErrorReport_OLD")
        END MIGRATION*/
        //End: Create table ErrorReport for SQLite

        //Begin: Create table ClazzAssignment for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzAssignment RENAME to ClazzAssignment_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzAssignment (  caTitle  TEXT , caDescription  TEXT , caGroupUid  INTEGER  NOT NULL  DEFAULT 0 , caActive  INTEGER  NOT NULL , caClassCommentEnabled  INTEGER  NOT NULL , caPrivateCommentsEnabled  INTEGER  NOT NULL  DEFAULT 1 , caCompletionCriteria  INTEGER  NOT NULL  DEFAULT 100 , caRequireFileSubmission  INTEGER  NOT NULL  DEFAULT 1 , caFileType  INTEGER  NOT NULL  DEFAULT 0 , caSizeLimit  INTEGER  NOT NULL  DEFAULT 50 , caNumberOfFiles  INTEGER  NOT NULL  DEFAULT 1 , caSubmissionPolicy  INTEGER  NOT NULL  DEFAULT 1 , caMarkingType  INTEGER  NOT NULL  DEFAULT 1 , caRequireTextSubmission  INTEGER  NOT NULL  DEFAULT 1 , caTextLimitType  INTEGER  NOT NULL  DEFAULT 1 , caTextLimit  INTEGER  NOT NULL  DEFAULT 500 , caXObjectUid  INTEGER  NOT NULL  DEFAULT 0 , caClazzUid  INTEGER  NOT NULL , caPeerReviewerCount  INTEGER  NOT NULL  DEFAULT 0 , caLocalChangeSeqNum  INTEGER  NOT NULL , caMasterChangeSeqNum  INTEGER  NOT NULL , caLastChangedBy  INTEGER  NOT NULL , caLct  INTEGER  NOT NULL , caUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzAssignment (caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caPeerReviewerCount, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct, caUid) SELECT caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caPeerReviewerCount, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct, caUid FROM ClazzAssignment_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzAssignment_OLD")
        END MIGRATION*/
        //End: Create table ClazzAssignment for SQLite

        //Begin: Create table CourseAssignmentSubmission for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentSubmission RENAME to CourseAssignmentSubmission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentSubmission (  casAssignmentUid  INTEGER  NOT NULL , casSubmitterUid  INTEGER  NOT NULL , casSubmitterPersonUid  INTEGER  NOT NULL , casText  TEXT , casType  INTEGER  NOT NULL , casTimestamp  INTEGER  NOT NULL , casClazzUid  INTEGER  NOT NULL  DEFAULT 0 , casUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentSubmission (casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp, casClazzUid, casUid) SELECT casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp, casClazzUid, casUid FROM CourseAssignmentSubmission_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentSubmission_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentSubmission for SQLite

        //Begin: Create table CourseAssignmentSubmissionFile for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentSubmissionFile RENAME to CourseAssignmentSubmissionFile_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentSubmissionFile (  casaSubmissionUid  INTEGER  NOT NULL , casaSubmitterUid  INTEGER  NOT NULL  DEFAULT 0 , casaCaUid  INTEGER  NOT NULL , casaClazzUid  INTEGER  NOT NULL , casaMimeType  TEXT , casaFileName  TEXT , casaUri  TEXT , casaSize  INTEGER  NOT NULL , casaTimestamp  INTEGER  NOT NULL , casaDeleted  INTEGER  NOT NULL  DEFAULT 0 , casaUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentSubmissionFile (casaSubmissionUid, casaSubmitterUid, casaCaUid, casaClazzUid, casaMimeType, casaFileName, casaUri, casaSize, casaTimestamp, casaDeleted, casaUid) SELECT casaSubmissionUid, casaSubmitterUid, casaCaUid, casaClazzUid, casaMimeType, casaFileName, casaUri, casaSize, casaTimestamp, casaDeleted, casaUid FROM CourseAssignmentSubmissionFile_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentSubmissionFile_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentSubmissionFile for SQLite

        //Begin: Create table CourseAssignmentMark for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentMark RENAME to CourseAssignmentMark_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentMark (  camAssignmentUid  INTEGER  NOT NULL , camSubmitterUid  INTEGER  NOT NULL , camMarkerSubmitterUid  INTEGER  NOT NULL  DEFAULT 0 , camMarkerPersonUid  INTEGER  NOT NULL  DEFAULT 0 , camMarkerComment  TEXT , camMark  REAl  NOT NULL , camMaxMark  REAl  NOT NULL  DEFAULT 1 , camPenalty  REAl  NOT NULL , camLct  INTEGER  NOT NULL , camClazzUid  INTEGER  NOT NULL  DEFAULT 0 , camUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentMark (camAssignmentUid, camSubmitterUid, camMarkerSubmitterUid, camMarkerPersonUid, camMarkerComment, camMark, camMaxMark, camPenalty, camLct, camClazzUid, camUid) SELECT camAssignmentUid, camSubmitterUid, camMarkerSubmitterUid, camMarkerPersonUid, camMarkerComment, camMark, camMaxMark, camPenalty, camLct, camClazzUid, camUid FROM CourseAssignmentMark_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentMark_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentMark for SQLite

        //Begin: Create table PeerReviewerAllocation for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PeerReviewerAllocation RENAME to PeerReviewerAllocation_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PeerReviewerAllocation (  praMarkerSubmitterUid  INTEGER  NOT NULL , praToMarkerSubmitterUid  INTEGER  NOT NULL , praAssignmentUid  INTEGER  NOT NULL , praActive  INTEGER  NOT NULL , praLct  INTEGER  NOT NULL , praUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PeerReviewerAllocation (praMarkerSubmitterUid, praToMarkerSubmitterUid, praAssignmentUid, praActive, praLct, praUid) SELECT praMarkerSubmitterUid, praToMarkerSubmitterUid, praAssignmentUid, praActive, praLct, praUid FROM PeerReviewerAllocation_OLD")
        _stmt.executeUpdate("DROP TABLE PeerReviewerAllocation_OLD")
        END MIGRATION*/
        //End: Create table PeerReviewerAllocation for SQLite

        //Begin: Create table PersonAuth2 for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonAuth2 RENAME to PersonAuth2_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonAuth2 (  pauthUid  INTEGER  PRIMARY KEY  NOT NULL , pauthMechanism  TEXT , pauthAuth  TEXT , pauthLcsn  INTEGER  NOT NULL , pauthPcsn  INTEGER  NOT NULL , pauthLcb  INTEGER  NOT NULL , pauthLct  INTEGER  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonAuth2 (pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct) SELECT pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct FROM PersonAuth2_OLD")
        _stmt.executeUpdate("DROP TABLE PersonAuth2_OLD")
        END MIGRATION*/
        //End: Create table PersonAuth2 for SQLite

        //Begin: Create table UserSession for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE UserSession RENAME to UserSession_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS UserSession (  usPcsn  INTEGER  NOT NULL , usLcsn  INTEGER  NOT NULL , usLcb  INTEGER  NOT NULL , usLct  INTEGER  NOT NULL , usPersonUid  INTEGER  NOT NULL , usClientNodeId  INTEGER  NOT NULL , usStartTime  INTEGER  NOT NULL , usEndTime  INTEGER  NOT NULL , usStatus  INTEGER  NOT NULL , usReason  INTEGER  NOT NULL , usAuth  TEXT , usSessionType  INTEGER  NOT NULL , usUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX person_status_node_idx ON UserSession (usPersonUid, usStatus, usClientNodeId)"
        _stmtList +=
            "CREATE INDEX node_status_person_idx ON UserSession (usClientNodeId, usStatus, usPersonUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO UserSession (usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType, usUid) SELECT usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType, usUid FROM UserSession_OLD")
        _stmt.executeUpdate("DROP TABLE UserSession_OLD")
        END MIGRATION*/
        //End: Create table UserSession for SQLite

        //Begin: Create table ContentJob for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentJob RENAME to ContentJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentJob (  toUri  TEXT , cjProgress  INTEGER  NOT NULL , cjTotal  INTEGER  NOT NULL , cjNotificationTitle  TEXT , cjIsMeteredAllowed  INTEGER  NOT NULL , params  TEXT , cjLct  INTEGER  NOT NULL , cjUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentJob (toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params, cjLct, cjUid) SELECT toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params, cjLct, cjUid FROM ContentJob_OLD")
        _stmt.executeUpdate("DROP TABLE ContentJob_OLD")
        END MIGRATION*/
        //End: Create table ContentJob for SQLite

        //Begin: Create table ContentEntryImportJob for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryImportJob RENAME to ContentEntryImportJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryImportJob (  sourceUri  TEXT , cjiOriginalFilename  TEXT , cjiContentEntryUid  INTEGER  NOT NULL , cjiParentContentEntryUid  INTEGER  NOT NULL , cjiContentEntryVersion  INTEGER  NOT NULL , cjiItemProgress  INTEGER  NOT NULL , cjiItemTotal  INTEGER  NOT NULL , cjiStatus  INTEGER  NOT NULL , cjiRecursiveStatus  INTEGER  NOT NULL , cjiPluginId  INTEGER  NOT NULL , cjiParentCjiUid  INTEGER  NOT NULL , cjiStartTime  INTEGER  NOT NULL , cjiFinishTime  INTEGER  NOT NULL , cjiContentDeletedOnCancellation  INTEGER  NOT NULL , cjiCompressionLevel  INTEGER  NOT NULL  DEFAULT 3 , cjiError  TEXT , cjiErrorDismissed  INTEGER  NOT NULL , cjiOwnerPersonUid  INTEGER  NOT NULL , cjiUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX index_ContentEntryImportJob_cjiContentEntryUid_cjiFinishTime ON ContentEntryImportJob (cjiContentEntryUid, cjiFinishTime)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryImportJob (sourceUri, cjiOriginalFilename, cjiContentEntryUid, cjiParentContentEntryUid, cjiContentEntryVersion, cjiItemProgress, cjiItemTotal, cjiStatus, cjiRecursiveStatus, cjiPluginId, cjiParentCjiUid, cjiStartTime, cjiFinishTime, cjiContentDeletedOnCancellation, cjiCompressionLevel, cjiError, cjiErrorDismissed, cjiOwnerPersonUid, cjiUid) SELECT sourceUri, cjiOriginalFilename, cjiContentEntryUid, cjiParentContentEntryUid, cjiContentEntryVersion, cjiItemProgress, cjiItemTotal, cjiStatus, cjiRecursiveStatus, cjiPluginId, cjiParentCjiUid, cjiStartTime, cjiFinishTime, cjiContentDeletedOnCancellation, cjiCompressionLevel, cjiError, cjiErrorDismissed, cjiOwnerPersonUid, cjiUid FROM ContentEntryImportJob_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryImportJob_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryImportJob for SQLite

        //Begin: Create table CourseBlock for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseBlock RENAME to CourseBlock_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseBlock (  cbType  INTEGER  NOT NULL , cbIndentLevel  INTEGER  NOT NULL , cbModuleParentBlockUid  INTEGER  NOT NULL , cbTitle  TEXT , cbDescription  TEXT , cbCompletionCriteria  INTEGER  NOT NULL , cbHideUntilDate  INTEGER  NOT NULL , cbDeadlineDate  INTEGER  NOT NULL , cbLateSubmissionPenalty  INTEGER  NOT NULL , cbGracePeriodDate  INTEGER  NOT NULL , cbMaxPoints  REAl , cbMinPoints  REAl , cbIndex  INTEGER  NOT NULL , cbClazzUid  INTEGER  NOT NULL , cbClazzSourcedId  TEXT , cbActive  INTEGER  NOT NULL , cbHidden  INTEGER  NOT NULL , cbEntityUid  INTEGER  NOT NULL , cbLct  INTEGER  NOT NULL , cbSourcedId  TEXT , cbMetadata  TEXT , cbCreatedByAppId  TEXT , cbUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList += "CREATE INDEX idx_courseblock_cbclazzuid ON CourseBlock (cbClazzUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseBlock (cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId, cbUid) SELECT cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId, cbUid FROM CourseBlock_OLD")
        _stmt.executeUpdate("DROP TABLE CourseBlock_OLD")
        END MIGRATION*/
        //End: Create table CourseBlock for SQLite

        //Begin: Create table CourseTerminology for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseTerminology RENAME to CourseTerminology_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseTerminology (  ctTitle  TEXT , ctTerminology  TEXT , ctLct  INTEGER  NOT NULL , ctUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseTerminology (ctTitle, ctTerminology, ctLct, ctUid) SELECT ctTitle, ctTerminology, ctLct, ctUid FROM CourseTerminology_OLD")
        _stmt.executeUpdate("DROP TABLE CourseTerminology_OLD")
        END MIGRATION*/
        //End: Create table CourseTerminology for SQLite

        //Begin: Create table CourseGroupSet for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseGroupSet RENAME to CourseGroupSet_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseGroupSet (  cgsName  TEXT , cgsTotalGroups  INTEGER  NOT NULL , cgsActive  INTEGER  NOT NULL , cgsClazzUid  INTEGER  NOT NULL , cgsLct  INTEGER  NOT NULL , cgsUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseGroupSet (cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct, cgsUid) SELECT cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct, cgsUid FROM CourseGroupSet_OLD")
        _stmt.executeUpdate("DROP TABLE CourseGroupSet_OLD")
        END MIGRATION*/
        //End: Create table CourseGroupSet for SQLite

        //Begin: Create table CourseGroupMember for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseGroupMember RENAME to CourseGroupMember_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseGroupMember (  cgmSetUid  INTEGER  NOT NULL , cgmGroupNumber  INTEGER  NOT NULL , cgmPersonUid  INTEGER  NOT NULL , cgmLct  INTEGER  NOT NULL , cgmUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseGroupMember (cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct, cgmUid) SELECT cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct, cgmUid FROM CourseGroupMember_OLD")
        _stmt.executeUpdate("DROP TABLE CourseGroupMember_OLD")
        END MIGRATION*/
        //End: Create table CourseGroupMember for SQLite

        //Begin: Create table ContentEntryPicture for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryPicture RENAME to ContentEntryPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryPicture (  cepContentEntryUid  INTEGER  NOT NULL , cepUri  TEXT , cepMd5  TEXT , cepFileSize  INTEGER  NOT NULL , cepTimestamp  INTEGER  NOT NULL , cepMimeType  TEXT , cepActive  INTEGER  NOT NULL , cepUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryPicture (cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive, cepUid) SELECT cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive, cepUid FROM ContentEntryPicture_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryPicture_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryPicture for SQLite

        //Begin: Create table ActivityInteractionEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityInteractionEntity RENAME to ActivityInteractionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityInteractionEntity (  aieActivityUid  INTEGER  NOT NULL , aieHash  INTEGER  NOT NULL , aieProp  INTEGER  NOT NULL , aieId  TEXT , aieLastMod  INTEGER  NOT NULL , aieIsDeleted  INTEGER  NOT NULL , PRIMARY KEY (aieActivityUid, aieHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityInteractionEntity (aieActivityUid, aieHash, aieProp, aieId, aieLastMod, aieIsDeleted) SELECT aieActivityUid, aieHash, aieProp, aieId, aieLastMod, aieIsDeleted FROM ActivityInteractionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityInteractionEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityInteractionEntity for SQLite

        //Begin: Create table CoursePicture for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CoursePicture RENAME to CoursePicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CoursePicture (  coursePictureLct  INTEGER  NOT NULL , coursePictureUri  TEXT , coursePictureThumbnailUri  TEXT , coursePictureActive  INTEGER  NOT NULL , coursePictureUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CoursePicture (coursePictureLct, coursePictureUri, coursePictureThumbnailUri, coursePictureActive, coursePictureUid) SELECT coursePictureLct, coursePictureUri, coursePictureThumbnailUri, coursePictureActive, coursePictureUid FROM CoursePicture_OLD")
        _stmt.executeUpdate("DROP TABLE CoursePicture_OLD")
        END MIGRATION*/
        //End: Create table CoursePicture for SQLite

        //Begin: Create table DiscussionPost for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DiscussionPost RENAME to DiscussionPost_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DiscussionPost (  discussionPostReplyToPostUid  INTEGER  NOT NULL , discussionPostTitle  TEXT , discussionPostMessage  TEXT , discussionPostStartDate  INTEGER  NOT NULL , discussionPostCourseBlockUid  INTEGER  NOT NULL , dpDeleted  INTEGER  NOT NULL , discussionPostStartedPersonUid  INTEGER  NOT NULL , discussionPostClazzUid  INTEGER  NOT NULL , discussionPostLct  INTEGER  NOT NULL , discussionPostVisible  INTEGER  NOT NULL , discussionPostArchive  INTEGER  NOT NULL , discussionPostUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DiscussionPost (discussionPostReplyToPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostCourseBlockUid, dpDeleted, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct, discussionPostVisible, discussionPostArchive, discussionPostUid) SELECT discussionPostReplyToPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostCourseBlockUid, dpDeleted, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct, discussionPostVisible, discussionPostArchive, discussionPostUid FROM DiscussionPost_OLD")
        _stmt.executeUpdate("DROP TABLE DiscussionPost_OLD")
        END MIGRATION*/
        //End: Create table DiscussionPost for SQLite

        //Begin: Create table ExternalAppPermission for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ExternalAppPermission RENAME to ExternalAppPermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ExternalAppPermission (  eapPersonUid  INTEGER  NOT NULL , eapPackageId  TEXT , eapStartTime  INTEGER  NOT NULL , eapExpireTime  INTEGER  NOT NULL , eapAuthToken  TEXT , eapAndroidAccountName  TEXT , eapUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ExternalAppPermission (eapPersonUid, eapPackageId, eapStartTime, eapExpireTime, eapAuthToken, eapAndroidAccountName, eapUid) SELECT eapPersonUid, eapPackageId, eapStartTime, eapExpireTime, eapAuthToken, eapAndroidAccountName, eapUid FROM ExternalAppPermission_OLD")
        _stmt.executeUpdate("DROP TABLE ExternalAppPermission_OLD")
        END MIGRATION*/
        //End: Create table ExternalAppPermission for SQLite

        //Begin: Create table Message for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Message RENAME to Message_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Message (  messageSenderPersonUid  INTEGER  NOT NULL , messageToPersonUid  INTEGER  NOT NULL , messageText  TEXT , messageTimestamp  INTEGER  NOT NULL , messageLct  INTEGER  NOT NULL , messageUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX message_idx_send_to_time ON Message (messageSenderPersonUid, messageToPersonUid, messageTimestamp)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Message (messageSenderPersonUid, messageToPersonUid, messageText, messageTimestamp, messageLct, messageUid) SELECT messageSenderPersonUid, messageToPersonUid, messageText, messageTimestamp, messageLct, messageUid FROM Message_OLD")
        _stmt.executeUpdate("DROP TABLE Message_OLD")
        END MIGRATION*/
        //End: Create table Message for SQLite

        //Begin: Create table StudentResult for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StudentResult RENAME to StudentResult_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StudentResult (  srUid  INTEGER  PRIMARY KEY  NOT NULL , srSourcedId  TEXT , srCourseBlockUid  INTEGER  NOT NULL , srLineItemSourcedId  TEXT , srLineItemHref  TEXT , srClazzUid  INTEGER  NOT NULL , srAssignmentUid  INTEGER  NOT NULL , srStatus  INTEGER  NOT NULL , srMetaData  TEXT , srStudentPersonUid  INTEGER  NOT NULL , srStudentPersonSourcedId  TEXT , srStudentGroupId  INTEGER  NOT NULL , srMarkerPersonUid  INTEGER  NOT NULL , srMarkerGroupId  INTEGER  NOT NULL , srScoreStatus  INTEGER  NOT NULL , srScore  REAl  NOT NULL , srScoreDate  INTEGER  NOT NULL , srLastModified  INTEGER  NOT NULL , srComment  TEXT , srAppId  TEXT , srDeleted  INTEGER  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StudentResult (srUid, srSourcedId, srCourseBlockUid, srLineItemSourcedId, srLineItemHref, srClazzUid, srAssignmentUid, srStatus, srMetaData, srStudentPersonUid, srStudentPersonSourcedId, srStudentGroupId, srMarkerPersonUid, srMarkerGroupId, srScoreStatus, srScore, srScoreDate, srLastModified, srComment, srAppId, srDeleted) SELECT srUid, srSourcedId, srCourseBlockUid, srLineItemSourcedId, srLineItemHref, srClazzUid, srAssignmentUid, srStatus, srMetaData, srStudentPersonUid, srStudentPersonSourcedId, srStudentGroupId, srMarkerPersonUid, srMarkerGroupId, srScoreStatus, srScore, srScoreDate, srLastModified, srComment, srAppId, srDeleted FROM StudentResult_OLD")
        _stmt.executeUpdate("DROP TABLE StudentResult_OLD")
        END MIGRATION*/
        //End: Create table StudentResult for SQLite

        //Begin: Create table ContentEntryVersion for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryVersion RENAME to ContentEntryVersion_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryVersion (  cevContentEntryUid  INTEGER  NOT NULL , cevOpenUri  TEXT , cevContentType  TEXT , cevManifestUrl  TEXT , cevSize  INTEGER  NOT NULL , cevInActive  INTEGER  NOT NULL , cevLastModified  INTEGER  NOT NULL , cevLct  INTEGER  NOT NULL , cevStorageSize  INTEGER  NOT NULL  DEFAULT 0 , cevOriginalSize  INTEGER  NOT NULL  DEFAULT 0 , cevUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryVersion (cevContentEntryUid, cevOpenUri, cevContentType, cevManifestUrl, cevSize, cevInActive, cevLastModified, cevLct, cevStorageSize, cevOriginalSize, cevUid) SELECT cevContentEntryUid, cevOpenUri, cevContentType, cevManifestUrl, cevSize, cevInActive, cevLastModified, cevLct, cevStorageSize, cevOriginalSize, cevUid FROM ContentEntryVersion_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryVersion_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryVersion for SQLite

        //Begin: Create table TransferJob for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJob RENAME to TransferJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJob (  tjType  INTEGER  NOT NULL , tjStatus  INTEGER  NOT NULL , tjName  TEXT , tjUuid  TEXT , tjTableId  INTEGER  NOT NULL  DEFAULT 0 , tjEntityUid  INTEGER  NOT NULL  DEFAULT 0 , tjTimeCreated  INTEGER  NOT NULL  DEFAULT 0 , tjCreationType  INTEGER  NOT NULL  DEFAULT 0 , tjOiUid  INTEGER  NOT NULL  DEFAULT 0 , tjUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX TransferJob_idx_tjTableId_EntityUid ON TransferJob (tjTableId, tjEntityUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJob (tjType, tjStatus, tjName, tjUuid, tjTableId, tjEntityUid, tjTimeCreated, tjCreationType, tjOiUid, tjUid) SELECT tjType, tjStatus, tjName, tjUuid, tjTableId, tjEntityUid, tjTimeCreated, tjCreationType, tjOiUid, tjUid FROM TransferJob_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJob_OLD")
        END MIGRATION*/
        //End: Create table TransferJob for SQLite

        //Begin: Create table TransferJobItem for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJobItem RENAME to TransferJobItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJobItem (  tjiTjUid  INTEGER  NOT NULL , tjTotalSize  INTEGER  NOT NULL , tjTransferred  INTEGER  NOT NULL , tjAttemptCount  INTEGER  NOT NULL , tjiSrc  TEXT , tjiDest  TEXT , tjiType  INTEGER  NOT NULL , tjiStatus  INTEGER  NOT NULL , tjiTableId  INTEGER  NOT NULL , tjiEntityUid  INTEGER  NOT NULL , tjiEntityEtag  INTEGER  NOT NULL  DEFAULT 0 , tjiLockIdToRelease  INTEGER  NOT NULL  DEFAULT 0 , tjiPartialTmpFile  TEXT , tjiUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX tji_table_entity_etag ON TransferJobItem (tjiTableId, tjiEntityUid, tjiEntityEtag)"
        _stmtList += "CREATE INDEX transferjob_tjuid ON TransferJobItem (tjiTjUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJobItem (tjiTjUid, tjTotalSize, tjTransferred, tjAttemptCount, tjiSrc, tjiDest, tjiType, tjiStatus, tjiTableId, tjiEntityUid, tjiEntityEtag, tjiLockIdToRelease, tjiPartialTmpFile, tjiUid) SELECT tjiTjUid, tjTotalSize, tjTransferred, tjAttemptCount, tjiSrc, tjiDest, tjiType, tjiStatus, tjiTableId, tjiEntityUid, tjiEntityEtag, tjiLockIdToRelease, tjiPartialTmpFile, tjiUid FROM TransferJobItem_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJobItem_OLD")
        END MIGRATION*/
        //End: Create table TransferJobItem for SQLite

        //Begin: Create table CacheLockJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CacheLockJoin RENAME to CacheLockJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CacheLockJoin (  cljTableId  INTEGER  NOT NULL , cljEntityUid  INTEGER  NOT NULL , cljUrl  TEXT , cljLockId  INTEGER  NOT NULL , cljStatus  INTEGER  NOT NULL , cljType  INTEGER  NOT NULL , cljOiUid  INTEGER  NOT NULL  DEFAULT 0 , cljId  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_clj_table_entity_url ON CacheLockJoin (cljTableId, cljEntityUid, cljUrl)"
        _stmtList += "CREATE INDEX idx_clj_offline_item_uid ON CacheLockJoin (cljOiUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CacheLockJoin (cljTableId, cljEntityUid, cljUrl, cljLockId, cljStatus, cljType, cljOiUid, cljId) SELECT cljTableId, cljEntityUid, cljUrl, cljLockId, cljStatus, cljType, cljOiUid, cljId FROM CacheLockJoin_OLD")
        _stmt.executeUpdate("DROP TABLE CacheLockJoin_OLD")
        END MIGRATION*/
        //End: Create table CacheLockJoin for SQLite

        //Begin: Create table OfflineItem for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OfflineItem RENAME to OfflineItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OfflineItem (  oiNodeId  INTEGER  NOT NULL , oiClazzUid  INTEGER  NOT NULL , oiCourseBlockUid  INTEGER  NOT NULL , oiContentEntryUid  INTEGER  NOT NULL , oiActive  INTEGER  NOT NULL , oiLct  INTEGER  NOT NULL , oiUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX offline_item_node_content_entry ON OfflineItem (oiNodeId, oiContentEntryUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OfflineItem (oiNodeId, oiClazzUid, oiCourseBlockUid, oiContentEntryUid, oiActive, oiLct, oiUid) SELECT oiNodeId, oiClazzUid, oiCourseBlockUid, oiContentEntryUid, oiActive, oiLct, oiUid FROM OfflineItem_OLD")
        _stmt.executeUpdate("DROP TABLE OfflineItem_OLD")
        END MIGRATION*/
        //End: Create table OfflineItem for SQLite

        //Begin: Create table OfflineItemPendingTransferJob for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OfflineItemPendingTransferJob RENAME to OfflineItemPendingTransferJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OfflineItemPendingTransferJob (  oiptjOiUid  INTEGER  NOT NULL , oiptjTableId  INTEGER  NOT NULL , oiptjEntityUid  INTEGER  NOT NULL , oiptjUrl  TEXT , oiptjType  INTEGER  NOT NULL , oiptjId  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OfflineItemPendingTransferJob (oiptjOiUid, oiptjTableId, oiptjEntityUid, oiptjUrl, oiptjType, oiptjId) SELECT oiptjOiUid, oiptjTableId, oiptjEntityUid, oiptjUrl, oiptjType, oiptjId FROM OfflineItemPendingTransferJob_OLD")
        _stmt.executeUpdate("DROP TABLE OfflineItemPendingTransferJob_OLD")
        END MIGRATION*/
        //End: Create table OfflineItemPendingTransferJob for SQLite

        //Begin: Create table DeletedItem for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DeletedItem RENAME to DeletedItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DeletedItem (  delItemName  TEXT , delItemIconUri  TEXT , delItemLastModTime  INTEGER  NOT NULL , delItemTimeDeleted  INTEGER  NOT NULL , delItemEntityTable  INTEGER  NOT NULL , delItemEntityUid  INTEGER  NOT NULL , delItemDeletedByPersonUid  INTEGER  NOT NULL , delItemStatus  INTEGER  NOT NULL , delItemIsFolder  INTEGER  NOT NULL  DEFAULT 0 , delItemUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX delitem_idx_status_time ON DeletedItem (delItemStatus, delItemTimeDeleted)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DeletedItem (delItemName, delItemIconUri, delItemLastModTime, delItemTimeDeleted, delItemEntityTable, delItemEntityUid, delItemDeletedByPersonUid, delItemStatus, delItemIsFolder, delItemUid) SELECT delItemName, delItemIconUri, delItemLastModTime, delItemTimeDeleted, delItemEntityTable, delItemEntityUid, delItemDeletedByPersonUid, delItemStatus, delItemIsFolder, delItemUid FROM DeletedItem_OLD")
        _stmt.executeUpdate("DROP TABLE DeletedItem_OLD")
        END MIGRATION*/
        //End: Create table DeletedItem for SQLite

        //Begin: Create table EnrolmentRequest for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE EnrolmentRequest RENAME to EnrolmentRequest_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS EnrolmentRequest (  erClazzUid  INTEGER  NOT NULL , erClazzName  TEXT , erPersonUid  INTEGER  NOT NULL , erPersonFullname  TEXT , erPersonPictureUri  TEXT , erPersonUsername  TEXT , erRole  INTEGER  NOT NULL , erRequestTime  INTEGER  NOT NULL , erStatus  INTEGER  NOT NULL , erStatusSetByPersonUid  INTEGER  NOT NULL , erDeleted  INTEGER  NOT NULL , erStatusSetAuth  TEXT , erLastModified  INTEGER  NOT NULL , erUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_enrolmentrequest_by_clazz ON EnrolmentRequest (erClazzUid, erStatus)"
        _stmtList +=
            "CREATE INDEX idx_enrolmentrequest_by_person ON EnrolmentRequest (erPersonUid, erStatus)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO EnrolmentRequest (erClazzUid, erClazzName, erPersonUid, erPersonFullname, erPersonPictureUri, erPersonUsername, erRole, erRequestTime, erStatus, erStatusSetByPersonUid, erDeleted, erStatusSetAuth, erLastModified, erUid) SELECT erClazzUid, erClazzName, erPersonUid, erPersonFullname, erPersonPictureUri, erPersonUsername, erRole, erRequestTime, erStatus, erStatusSetByPersonUid, erDeleted, erStatusSetAuth, erLastModified, erUid FROM EnrolmentRequest_OLD")
        _stmt.executeUpdate("DROP TABLE EnrolmentRequest_OLD")
        END MIGRATION*/
        //End: Create table EnrolmentRequest for SQLite

        //Begin: Create table CoursePermission for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CoursePermission RENAME to CoursePermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CoursePermission (  cpLastModified  INTEGER  NOT NULL , cpClazzUid  INTEGER  NOT NULL , cpToEnrolmentRole  INTEGER  NOT NULL , cpToPersonUid  INTEGER  NOT NULL , cpToGroupUid  INTEGER  NOT NULL , cpPermissionsFlag  INTEGER  NOT NULL , cpIsDeleted  INTEGER  NOT NULL , cpUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList += "CREATE INDEX idx_coursepermission_clazzuid ON CoursePermission (cpClazzUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CoursePermission (cpLastModified, cpClazzUid, cpToEnrolmentRole, cpToPersonUid, cpToGroupUid, cpPermissionsFlag, cpIsDeleted, cpUid) SELECT cpLastModified, cpClazzUid, cpToEnrolmentRole, cpToPersonUid, cpToGroupUid, cpPermissionsFlag, cpIsDeleted, cpUid FROM CoursePermission_OLD")
        _stmt.executeUpdate("DROP TABLE CoursePermission_OLD")
        END MIGRATION*/
        //End: Create table CoursePermission for SQLite

        //Begin: Create table SystemPermission for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SystemPermission RENAME to SystemPermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SystemPermission (  spToPersonUid  INTEGER  NOT NULL , spToGroupUid  INTEGER  NOT NULL , spPermissionsFlag  INTEGER  NOT NULL , spLastModified  INTEGER  NOT NULL , spIsDeleted  INTEGER  NOT NULL , spUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_systempermission_personuid ON SystemPermission (spToPersonUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SystemPermission (spToPersonUid, spToGroupUid, spPermissionsFlag, spLastModified, spIsDeleted, spUid) SELECT spToPersonUid, spToGroupUid, spPermissionsFlag, spLastModified, spIsDeleted, spUid FROM SystemPermission_OLD")
        _stmt.executeUpdate("DROP TABLE SystemPermission_OLD")
        END MIGRATION*/
        //End: Create table SystemPermission for SQLite

        //Begin: Create table CourseBlockPicture for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseBlockPicture RENAME to CourseBlockPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseBlockPicture (  cbpUid  INTEGER  PRIMARY KEY  NOT NULL , cbpLct  INTEGER  NOT NULL , cbpPictureUri  TEXT , cbpThumbnailUri  TEXT )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseBlockPicture (cbpUid, cbpLct, cbpPictureUri, cbpThumbnailUri) SELECT cbpUid, cbpLct, cbpPictureUri, cbpThumbnailUri FROM CourseBlockPicture_OLD")
        _stmt.executeUpdate("DROP TABLE CourseBlockPicture_OLD")
        END MIGRATION*/
        //End: Create table CourseBlockPicture for SQLite

        //Begin: Create table ContentEntryPicture2 for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryPicture2 RENAME to ContentEntryPicture2_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryPicture2 (  cepUid  INTEGER  PRIMARY KEY  NOT NULL , cepLct  INTEGER  NOT NULL , cepPictureUri  TEXT , cepThumbnailUri  TEXT )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryPicture2 (cepUid, cepLct, cepPictureUri, cepThumbnailUri) SELECT cepUid, cepLct, cepPictureUri, cepThumbnailUri FROM ContentEntryPicture2_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryPicture2_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryPicture2 for SQLite

        //Begin: Create table TransferJobError for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJobError RENAME to TransferJobError_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJobError (  tjeTjUid  INTEGER  NOT NULL , tjeTime  INTEGER  NOT NULL , tjeErrorStr  TEXT , tjeDismissed  INTEGER  NOT NULL , tjeId  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        _stmtList += "CREATE INDEX idx_transferjoberror_tjetjuid ON TransferJobError (tjeTjUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJobError (tjeTjUid, tjeTime, tjeErrorStr, tjeDismissed, tjeId) SELECT tjeTjUid, tjeTime, tjeErrorStr, tjeDismissed, tjeId FROM TransferJobError_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJobError_OLD")
        END MIGRATION*/
        //End: Create table TransferJobError for SQLite

        //Begin: Create table VerbLangMapEntry for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE VerbLangMapEntry RENAME to VerbLangMapEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS VerbLangMapEntry (  vlmeVerbUid  INTEGER  NOT NULL , vlmeLangHash  INTEGER  NOT NULL , vlmeLangCode  TEXT , vlmeEntryString  TEXT , vlmeLastModified  INTEGER  NOT NULL , PRIMARY KEY (vlmeVerbUid, vlmeLangHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO VerbLangMapEntry (vlmeVerbUid, vlmeLangHash, vlmeLangCode, vlmeEntryString, vlmeLastModified) SELECT vlmeVerbUid, vlmeLangHash, vlmeLangCode, vlmeEntryString, vlmeLastModified FROM VerbLangMapEntry_OLD")
        _stmt.executeUpdate("DROP TABLE VerbLangMapEntry_OLD")
        END MIGRATION*/
        //End: Create table VerbLangMapEntry for SQLite

        //Begin: Create table GroupMemberActorJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE GroupMemberActorJoin RENAME to GroupMemberActorJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS GroupMemberActorJoin (  gmajGroupActorUid  INTEGER  NOT NULL , gmajMemberActorUid  INTEGER  NOT NULL , gmajLastMod  INTEGER  NOT NULL , PRIMARY KEY (gmajGroupActorUid, gmajMemberActorUid) )"
        _stmtList +=
            "CREATE INDEX idx_groupmemberactorjoin_gmajgroupactoruid ON GroupMemberActorJoin (gmajGroupActorUid)"
        _stmtList +=
            "CREATE INDEX idx_groupmemberactorjoin_gmajmemberactoruid ON GroupMemberActorJoin (gmajMemberActorUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO GroupMemberActorJoin (gmajGroupActorUid, gmajMemberActorUid, gmajLastMod) SELECT gmajGroupActorUid, gmajMemberActorUid, gmajLastMod FROM GroupMemberActorJoin_OLD")
        _stmt.executeUpdate("DROP TABLE GroupMemberActorJoin_OLD")
        END MIGRATION*/
        //End: Create table GroupMemberActorJoin for SQLite

        //Begin: Create table ActivityLangMapEntry for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityLangMapEntry RENAME to ActivityLangMapEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityLangMapEntry (  almeActivityUid  INTEGER  NOT NULL , almeHash  INTEGER  NOT NULL , almeLangCode  TEXT , almeValue  TEXT , almeAieHash  INTEGER  NOT NULL , almeLastMod  INTEGER  NOT NULL , PRIMARY KEY (almeActivityUid, almeHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityLangMapEntry (almeActivityUid, almeHash, almeLangCode, almeValue, almeAieHash, almeLastMod) SELECT almeActivityUid, almeHash, almeLangCode, almeValue, almeAieHash, almeLastMod FROM ActivityLangMapEntry_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityLangMapEntry_OLD")
        END MIGRATION*/
        //End: Create table ActivityLangMapEntry for SQLite

        //Begin: Create table ActivityExtensionEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityExtensionEntity RENAME to ActivityExtensionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityExtensionEntity (  aeeActivityUid  INTEGER  NOT NULL , aeeKeyHash  INTEGER  NOT NULL , aeeKey  TEXT , aeeJson  TEXT , aeeLastMod  INTEGER  NOT NULL , aeeIsDeleted  INTEGER  NOT NULL , PRIMARY KEY (aeeActivityUid, aeeKeyHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityExtensionEntity (aeeActivityUid, aeeKeyHash, aeeKey, aeeJson, aeeLastMod, aeeIsDeleted) SELECT aeeActivityUid, aeeKeyHash, aeeKey, aeeJson, aeeLastMod, aeeIsDeleted FROM ActivityExtensionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityExtensionEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityExtensionEntity for SQLite

        //Begin: Create table StatementContextActivityJoin for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementContextActivityJoin RENAME to StatementContextActivityJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementContextActivityJoin (  scajFromStatementIdHi  INTEGER  NOT NULL , scajFromStatementIdLo  INTEGER  NOT NULL , scajToHash  INTEGER  NOT NULL , scajContextType  INTEGER  NOT NULL , scajToActivityUid  INTEGER  NOT NULL , scajToActivityId  TEXT , scajEtag  INTEGER  NOT NULL , PRIMARY KEY (scajFromStatementIdHi, scajFromStatementIdLo, scajToHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementContextActivityJoin (scajFromStatementIdHi, scajFromStatementIdLo, scajToHash, scajContextType, scajToActivityUid, scajToActivityId, scajEtag) SELECT scajFromStatementIdHi, scajFromStatementIdLo, scajToHash, scajContextType, scajToActivityUid, scajToActivityId, scajEtag FROM StatementContextActivityJoin_OLD")
        _stmt.executeUpdate("DROP TABLE StatementContextActivityJoin_OLD")
        END MIGRATION*/
        //End: Create table StatementContextActivityJoin for SQLite

        //Begin: Create table XapiSessionEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE XapiSessionEntity RENAME to XapiSessionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS XapiSessionEntity (  xseLastMod  INTEGER  NOT NULL , xseRegistrationHi  INTEGER  NOT NULL , xseRegistrationLo  INTEGER  NOT NULL , xseUsUid  INTEGER  NOT NULL , xseAccountPersonUid  INTEGER  NOT NULL , xseActorUid  INTEGER  NOT NULL , xseAccountUsername  TEXT  NOT NULL , xseClazzUid  INTEGER  NOT NULL , xseCbUid  INTEGER  NOT NULL , xseContentEntryUid  INTEGER  NOT NULL , xseRootActivityId  TEXT  NOT NULL , xseRootActivityUid  INTEGER  NOT NULL , xseStartTime  INTEGER  NOT NULL , xseExpireTime  INTEGER  NOT NULL , xseAuth  TEXT , xseCompleted  INTEGER  NOT NULL  DEFAULT 0 , knownActorUidToPersonUids  TEXT  NOT NULL , xseUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO XapiSessionEntity (xseLastMod, xseRegistrationHi, xseRegistrationLo, xseUsUid, xseAccountPersonUid, xseActorUid, xseAccountUsername, xseClazzUid, xseCbUid, xseContentEntryUid, xseRootActivityId, xseRootActivityUid, xseStartTime, xseExpireTime, xseAuth, xseCompleted, knownActorUidToPersonUids, xseUid) SELECT xseLastMod, xseRegistrationHi, xseRegistrationLo, xseUsUid, xseAccountPersonUid, xseActorUid, xseAccountUsername, xseClazzUid, xseCbUid, xseContentEntryUid, xseRootActivityId, xseRootActivityUid, xseStartTime, xseExpireTime, xseAuth, xseCompleted, knownActorUidToPersonUids, xseUid FROM XapiSessionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE XapiSessionEntity_OLD")
        END MIGRATION*/
        //End: Create table XapiSessionEntity for SQLite

        //Begin: Create table StatementEntityJson for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementEntityJson RENAME to StatementEntityJson_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementEntityJson (  stmtJsonIdHi  INTEGER  NOT NULL , stmtJsonIdLo  INTEGER  NOT NULL , stmtEtag  INTEGER  NOT NULL , fullStatement  TEXT , PRIMARY KEY (stmtJsonIdHi, stmtJsonIdLo) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementEntityJson (stmtJsonIdHi, stmtJsonIdLo, stmtEtag, fullStatement) SELECT stmtJsonIdHi, stmtJsonIdLo, stmtEtag, fullStatement FROM StatementEntityJson_OLD")
        _stmt.executeUpdate("DROP TABLE StatementEntityJson_OLD")
        END MIGRATION*/
        //End: Create table StatementEntityJson for SQLite

        //Begin: Create table PersonPasskey for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonPasskey RENAME to PersonPasskey_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonPasskey (  ppPersonUid  INTEGER  NOT NULL , ppAttestationObj  TEXT , ppClientDataJson  TEXT , ppOriginString  TEXT , ppRpid  TEXT , ppId  TEXT , ppChallengeString  TEXT , ppPublicKey  TEXT , isRevoked  INTEGER  NOT NULL , ppPasskeyLct  INTEGER  NOT NULL , personPasskeyUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonPasskey (ppPersonUid, ppAttestationObj, ppClientDataJson, ppOriginString, ppRpid, ppId, ppChallengeString, ppPublicKey, isRevoked, ppPasskeyLct, personPasskeyUid) SELECT ppPersonUid, ppAttestationObj, ppClientDataJson, ppOriginString, ppRpid, ppId, ppChallengeString, ppPublicKey, isRevoked, ppPasskeyLct, personPasskeyUid FROM PersonPasskey_OLD")
        _stmt.executeUpdate("DROP TABLE PersonPasskey_OLD")
        END MIGRATION*/
        //End: Create table PersonPasskey for SQLite

        //Begin: Create table StateEntity for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StateEntity RENAME to StateEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StateEntity (  seActorUid  INTEGER  NOT NULL , seHash  INTEGER  NOT NULL , seActivityUid  INTEGER  NOT NULL , seStateId  TEXT  NOT NULL , seLastMod  INTEGER  NOT NULL , seTimeStored  INTEGER  NOT NULL , seContentType  TEXT  NOT NULL , seCompressed  INTEGER  NOT NULL , seContent  TEXT  NOT NULL , seDeleted  INTEGER  NOT NULL , seRegistrationHi  INTEGER , seRegistrationLo  INTEGER , seH5PPreloaded  INTEGER  NOT NULL , seH5PSubContentId  TEXT , PRIMARY KEY (seActorUid, seHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StateEntity (seActorUid, seHash, seActivityUid, seStateId, seLastMod, seTimeStored, seContentType, seCompressed, seContent, seDeleted, seRegistrationHi, seRegistrationLo, seH5PPreloaded, seH5PSubContentId) SELECT seActorUid, seHash, seActivityUid, seStateId, seLastMod, seTimeStored, seContentType, seCompressed, seContent, seDeleted, seRegistrationHi, seRegistrationLo, seH5PPreloaded, seH5PSubContentId FROM StateEntity_OLD")
        _stmt.executeUpdate("DROP TABLE StateEntity_OLD")
        END MIGRATION*/
        //End: Create table StateEntity for SQLite

        //Begin: Create table StateDeleteCommand for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StateDeleteCommand RENAME to StateDeleteCommand_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StateDeleteCommand (  sdcActorUid  INTEGER  NOT NULL , sdcHash  INTEGER  NOT NULL , sdcActivityUid  INTEGER  NOT NULL , sdcStateId  TEXT , sdcLastMod  INTEGER  NOT NULL , sdcRegistrationHi  INTEGER , sdcRegistrationLo  INTEGER , PRIMARY KEY (sdcActorUid, sdcHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StateDeleteCommand (sdcActorUid, sdcHash, sdcActivityUid, sdcStateId, sdcLastMod, sdcRegistrationHi, sdcRegistrationLo) SELECT sdcActorUid, sdcHash, sdcActivityUid, sdcStateId, sdcLastMod, sdcRegistrationHi, sdcRegistrationLo FROM StateDeleteCommand_OLD")
        _stmt.executeUpdate("DROP TABLE StateDeleteCommand_OLD")
        END MIGRATION*/
        //End: Create table StateDeleteCommand for SQLite

        //Begin: Create table OutgoingReplication for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OutgoingReplication RENAME to OutgoingReplication_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OutgoingReplication (  destNodeId  INTEGER  NOT NULL , orPk1  INTEGER  NOT NULL , orPk2  INTEGER  NOT NULL  DEFAULT 0 , orPk3  INTEGER  NOT NULL  DEFAULT 0 , orPk4  INTEGER  NOT NULL  DEFAULT 0 , orTableId  INTEGER  NOT NULL , orUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OutgoingReplication (destNodeId, orPk1, orPk2, orPk3, orPk4, orTableId, orUid) SELECT destNodeId, orPk1, orPk2, orPk3, orPk4, orTableId, orUid FROM OutgoingReplication_OLD")
        _stmt.executeUpdate("DROP TABLE OutgoingReplication_OLD")
        END MIGRATION*/
        //End: Create table OutgoingReplication for SQLite

        //Begin: Create table ReplicationOperation for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ReplicationOperation RENAME to ReplicationOperation_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ReplicationOperation (  repOpRemoteNodeId  INTEGER  NOT NULL , repOpStatus  INTEGER  NOT NULL , repOpTableId  INTEGER  NOT NULL , PRIMARY KEY (repOpRemoteNodeId, repOpTableId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ReplicationOperation (repOpRemoteNodeId, repOpStatus, repOpTableId) SELECT repOpRemoteNodeId, repOpStatus, repOpTableId FROM ReplicationOperation_OLD")
        _stmt.executeUpdate("DROP TABLE ReplicationOperation_OLD")
        END MIGRATION*/
        //End: Create table ReplicationOperation for SQLite

        //Begin: Create table PendingRepositorySession for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PendingRepositorySession RENAME to PendingRepositorySession_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PendingRepositorySession (  endpointUrl  TEXT , remoteNodeId  INTEGER  NOT NULL , rsUid  INTEGER  PRIMARY KEY  AUTOINCREMENT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PendingRepositorySession (endpointUrl, remoteNodeId, rsUid) SELECT endpointUrl, remoteNodeId, rsUid FROM PendingRepositorySession_OLD")
        _stmt.executeUpdate("DROP TABLE PendingRepositorySession_OLD")
        END MIGRATION*/
        //End: Create table PendingRepositorySession for SQLite

        //Begin: Create table DoorNode for SQLite
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DoorNode RENAME to DoorNode_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DoorNode (  auth  TEXT , nodeId  INTEGER  NOT NULL , rel  INTEGER  NOT NULL  DEFAULT 2 , PRIMARY KEY (nodeId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DoorNode (auth, nodeId, rel) SELECT auth, nodeId, rel FROM DoorNode_OLD")
        _stmt.executeUpdate("DROP TABLE DoorNode_OLD")
        END MIGRATION*/
        //End: Create table DoorNode for SQLite

      }
      2 ->  {
        // - create for this PostgreSQL 
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS _doorwayinfo (dbVersion int primary key, dbHash varchar(255))"
         _stmtList += "INSERT INTO _doorwayinfo VALUES (202, '')"
        //Begin: Create table ClazzLog for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzLog RENAME to ClazzLog_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzLog (  clazzLogClazzUid  BIGINT  NOT NULL , logDate  BIGINT  NOT NULL , timeRecorded  BIGINT  NOT NULL , clazzLogDone  BOOL  NOT NULL , cancellationNote  TEXT , clazzLogCancelled  BOOL  NOT NULL , clazzLogNumPresent  INTEGER  NOT NULL , clazzLogNumAbsent  INTEGER  NOT NULL , clazzLogNumPartial  INTEGER  NOT NULL , clazzLogScheduleUid  BIGINT  NOT NULL , clazzLogStatusFlag  INTEGER  NOT NULL , clazzLogMSQN  BIGINT  NOT NULL , clazzLogLCSN  BIGINT  NOT NULL , clazzLogLCB  INTEGER  NOT NULL , clazzLogLastChangedTime  BIGINT  NOT NULL , clazzLogUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzLog (clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime, clazzLogUid) SELECT clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime, clazzLogUid FROM ClazzLog_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzLog_OLD")
        END MIGRATION*/
        //End: Create table ClazzLog for PostgreSQL

        //Begin: Create table ClazzLogAttendanceRecord for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzLogAttendanceRecord RENAME to ClazzLogAttendanceRecord_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzLogAttendanceRecord (  clazzLogAttendanceRecordClazzLogUid  BIGINT  NOT NULL , clazzLogAttendanceRecordPersonUid  BIGINT  NOT NULL , attendanceStatus  INTEGER  NOT NULL , clazzLogAttendanceRecordMasterChangeSeqNum  BIGINT  NOT NULL , clazzLogAttendanceRecordLocalChangeSeqNum  BIGINT  NOT NULL , clazzLogAttendanceRecordLastChangedBy  INTEGER  NOT NULL , clazzLogAttendanceRecordLastChangedTime  BIGINT  NOT NULL , clazzLogAttendanceRecordUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime, clazzLogAttendanceRecordUid) SELECT clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime, clazzLogAttendanceRecordUid FROM ClazzLogAttendanceRecord_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzLogAttendanceRecord_OLD")
        END MIGRATION*/
        //End: Create table ClazzLogAttendanceRecord for PostgreSQL

        //Begin: Create table Schedule for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Schedule RENAME to Schedule_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Schedule (  sceduleStartTime  BIGINT  NOT NULL , scheduleEndTime  BIGINT  NOT NULL , scheduleDay  INTEGER  NOT NULL , scheduleMonth  INTEGER  NOT NULL , scheduleFrequency  INTEGER  NOT NULL , umCalendarUid  BIGINT  NOT NULL , scheduleClazzUid  BIGINT  NOT NULL , scheduleMasterChangeSeqNum  BIGINT  NOT NULL , scheduleLocalChangeSeqNum  BIGINT  NOT NULL , scheduleLastChangedBy  INTEGER  NOT NULL , scheduleLastChangedTime  BIGINT  NOT NULL , scheduleActive  BOOL  NOT NULL , scheduleUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Schedule (sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive, scheduleUid) SELECT sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive, scheduleUid FROM Schedule_OLD")
        _stmt.executeUpdate("DROP TABLE Schedule_OLD")
        END MIGRATION*/
        //End: Create table Schedule for PostgreSQL

        //Begin: Create table HolidayCalendar for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE HolidayCalendar RENAME to HolidayCalendar_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS HolidayCalendar (  umCalendarName  TEXT , umCalendarCategory  INTEGER  NOT NULL , umCalendarActive  BOOL  NOT NULL , umCalendarMasterChangeSeqNum  BIGINT  NOT NULL , umCalendarLocalChangeSeqNum  BIGINT  NOT NULL , umCalendarLastChangedBy  INTEGER  NOT NULL , umCalendarLct  BIGINT  NOT NULL , umCalendarUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO HolidayCalendar (umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct, umCalendarUid) SELECT umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct, umCalendarUid FROM HolidayCalendar_OLD")
        _stmt.executeUpdate("DROP TABLE HolidayCalendar_OLD")
        END MIGRATION*/
        //End: Create table HolidayCalendar for PostgreSQL

        //Begin: Create table Holiday for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Holiday RENAME to Holiday_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Holiday (  holMasterCsn  BIGINT  NOT NULL , holLocalCsn  BIGINT  NOT NULL , holLastModBy  INTEGER  NOT NULL , holLct  BIGINT  NOT NULL , holActive  BOOL  NOT NULL , holHolidayCalendarUid  BIGINT  NOT NULL , holStartTime  BIGINT  NOT NULL , holEndTime  BIGINT  NOT NULL , holName  TEXT , holUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Holiday (holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName, holUid) SELECT holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName, holUid FROM Holiday_OLD")
        _stmt.executeUpdate("DROP TABLE Holiday_OLD")
        END MIGRATION*/
        //End: Create table Holiday for PostgreSQL

        //Begin: Create table Person for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Person RENAME to Person_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Person (  username  TEXT , firstNames  TEXT , lastName  TEXT , emailAddr  TEXT , phoneNum  TEXT , gender  INTEGER  NOT NULL , active  BOOL  NOT NULL , isPersonalAccount  BOOL  NOT NULL , dateOfBirth  BIGINT  NOT NULL , personAddress  TEXT , personOrgId  TEXT , personGroupUid  BIGINT  NOT NULL , personLct  BIGINT  NOT NULL , personCountry  TEXT , personType  INTEGER  NOT NULL  DEFAULT 0 , personMasterChangeSeqNum  BIGINT  NOT NULL , personLocalChangeSeqNum  BIGINT  NOT NULL , personLastChangedBy  INTEGER  NOT NULL , admin  BOOL  NOT NULL , personNotes  TEXT , fatherName  TEXT , fatherNumber  TEXT , motherName  TEXT , motherNum  TEXT , personUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Person (username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, personUid) SELECT username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, personUid FROM Person_OLD")
        _stmt.executeUpdate("DROP TABLE Person_OLD")
        END MIGRATION*/
        //End: Create table Person for PostgreSQL

        //Begin: Create table Clazz for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Clazz RENAME to Clazz_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Clazz (  clazzName  TEXT , clazzDesc  TEXT , attendanceAverage  FLOAT  NOT NULL , clazzHolidayUMCalendarUid  BIGINT  NOT NULL , clazzScheuleUMCalendarUid  BIGINT  NOT NULL , isClazzActive  BOOL  NOT NULL , clazzLocationUid  BIGINT  NOT NULL , clazzStartTime  BIGINT  NOT NULL , clazzEndTime  BIGINT  NOT NULL , clazzFeatures  BIGINT  NOT NULL , clazzSchoolUid  BIGINT  NOT NULL , clazzEnrolmentPolicy  INTEGER  NOT NULL  DEFAULT 102 , clazzTerminologyUid  BIGINT  NOT NULL  DEFAULT 25966 , clazzMasterChangeSeqNum  BIGINT  NOT NULL , clazzLocalChangeSeqNum  BIGINT  NOT NULL , clazzLastChangedBy  INTEGER  NOT NULL , clazzLct  BIGINT  NOT NULL , clazzTimeZone  TEXT , clazzStudentsPersonGroupUid  BIGINT  NOT NULL , clazzTeachersPersonGroupUid  BIGINT  NOT NULL , clazzPendingStudentsPersonGroupUid  BIGINT  NOT NULL , clazzParentsPersonGroupUid  BIGINT  NOT NULL , clazzCode  TEXT , clazzOwnerPersonUid  BIGINT  NOT NULL  DEFAULT 0 , clazzUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Clazz (clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode, clazzOwnerPersonUid, clazzUid) SELECT clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode, clazzOwnerPersonUid, clazzUid FROM Clazz_OLD")
        _stmt.executeUpdate("DROP TABLE Clazz_OLD")
        END MIGRATION*/
        //End: Create table Clazz for PostgreSQL

        //Begin: Create table ClazzEnrolment for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzEnrolment RENAME to ClazzEnrolment_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzEnrolment (  clazzEnrolmentPersonUid  BIGINT  NOT NULL , clazzEnrolmentClazzUid  BIGINT  NOT NULL , clazzEnrolmentDateJoined  BIGINT  NOT NULL , clazzEnrolmentDateLeft  BIGINT  NOT NULL , clazzEnrolmentRole  INTEGER  NOT NULL , clazzEnrolmentAttendancePercentage  FLOAT  NOT NULL , clazzEnrolmentActive  BOOL  NOT NULL , clazzEnrolmentLeavingReasonUid  BIGINT  NOT NULL , clazzEnrolmentOutcome  INTEGER  NOT NULL , clazzEnrolmentLocalChangeSeqNum  BIGINT  NOT NULL , clazzEnrolmentMasterChangeSeqNum  BIGINT  NOT NULL , clazzEnrolmentLastChangedBy  INTEGER  NOT NULL , clazzEnrolmentLct  BIGINT  NOT NULL , clazzEnrolmentUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentPersonUid_clazzEnrolmentClazzUid ON ClazzEnrolment (clazzEnrolmentPersonUid, clazzEnrolmentClazzUid)"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentClazzUid_clazzEnrolmentPersonUid ON ClazzEnrolment (clazzEnrolmentClazzUid, clazzEnrolmentPersonUid)"
        _stmtList +=
            "CREATE INDEX index_ClazzEnrolment_clazzEnrolmentClazzUid_clazzEnrolmentRole ON ClazzEnrolment (clazzEnrolmentClazzUid, clazzEnrolmentRole)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzEnrolment (clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct, clazzEnrolmentUid) SELECT clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct, clazzEnrolmentUid FROM ClazzEnrolment_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzEnrolment_OLD")
        END MIGRATION*/
        //End: Create table ClazzEnrolment for PostgreSQL

        //Begin: Create table LeavingReason for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE LeavingReason RENAME to LeavingReason_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS LeavingReason (  leavingReasonTitle  TEXT , leavingReasonMCSN  BIGINT  NOT NULL , leavingReasonCSN  BIGINT  NOT NULL , leavingReasonLCB  INTEGER  NOT NULL , leavingReasonLct  BIGINT  NOT NULL , leavingReasonUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO LeavingReason (leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct, leavingReasonUid) SELECT leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct, leavingReasonUid FROM LeavingReason_OLD")
        _stmt.executeUpdate("DROP TABLE LeavingReason_OLD")
        END MIGRATION*/
        //End: Create table LeavingReason for PostgreSQL

        //Begin: Create table ContentEntry for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntry RENAME to ContentEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntry (  title  TEXT , description  TEXT , entryId  TEXT , author  TEXT , publisher  TEXT , licenseType  INTEGER  NOT NULL , licenseName  TEXT , licenseUrl  TEXT , sourceUrl  TEXT , thumbnailUrl  TEXT , lastModified  BIGINT  NOT NULL , primaryLanguageUid  BIGINT  NOT NULL , languageVariantUid  BIGINT  NOT NULL , contentFlags  INTEGER  NOT NULL , leaf  BOOL  NOT NULL , publik  BOOL  NOT NULL , ceInactive  BOOL  NOT NULL , completionCriteria  INTEGER  NOT NULL , minScore  INTEGER  NOT NULL , contentTypeFlag  INTEGER  NOT NULL , contentOwner  BIGINT  NOT NULL , contentOwnerType  INTEGER  NOT NULL  DEFAULT 1 , contentEntryLocalChangeSeqNum  BIGINT  NOT NULL , contentEntryMasterChangeSeqNum  BIGINT  NOT NULL , contentEntryLastChangedBy  INTEGER  NOT NULL , contentEntryLct  BIGINT  NOT NULL , contentEntryUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntry (title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct, contentEntryUid) SELECT title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct, contentEntryUid FROM ContentEntry_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntry_OLD")
        END MIGRATION*/
        //End: Create table ContentEntry for PostgreSQL

        //Begin: Create table ContentEntryContentCategoryJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryContentCategoryJoin RENAME to ContentEntryContentCategoryJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryContentCategoryJoin (  ceccjContentEntryUid  BIGINT  NOT NULL , ceccjContentCategoryUid  BIGINT  NOT NULL , ceccjLocalChangeSeqNum  BIGINT  NOT NULL , ceccjMasterChangeSeqNum  BIGINT  NOT NULL , ceccjLastChangedBy  INTEGER  NOT NULL , ceccjLct  BIGINT  NOT NULL , ceccjUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryContentCategoryJoin (ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct, ceccjUid) SELECT ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct, ceccjUid FROM ContentEntryContentCategoryJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryContentCategoryJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryContentCategoryJoin for PostgreSQL

        //Begin: Create table ContentEntryParentChildJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryParentChildJoin RENAME to ContentEntryParentChildJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryParentChildJoin (  cepcjLocalChangeSeqNum  BIGINT  NOT NULL , cepcjMasterChangeSeqNum  BIGINT  NOT NULL , cepcjLastChangedBy  INTEGER  NOT NULL , cepcjLct  BIGINT  NOT NULL , cepcjDeleted  BOOL  NOT NULL  DEFAULT false, cepcjParentContentEntryUid  BIGINT  NOT NULL , cepcjChildContentEntryUid  BIGINT  NOT NULL , childIndex  INTEGER  NOT NULL , cepcjUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX parent_child ON ContentEntryParentChildJoin (cepcjChildContentEntryUid, cepcjParentContentEntryUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryParentChildJoin (cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid) SELECT cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid FROM ContentEntryParentChildJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryParentChildJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryParentChildJoin for PostgreSQL

        //Begin: Create table ContentEntryRelatedEntryJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryRelatedEntryJoin RENAME to ContentEntryRelatedEntryJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryRelatedEntryJoin (  cerejContentEntryUid  BIGINT  NOT NULL , cerejRelatedEntryUid  BIGINT  NOT NULL , cerejLastChangedBy  INTEGER  NOT NULL , relType  INTEGER  NOT NULL , comment  TEXT , cerejRelLanguageUid  BIGINT  NOT NULL , cerejLocalChangeSeqNum  BIGINT  NOT NULL , cerejMasterChangeSeqNum  BIGINT  NOT NULL , cerejLct  BIGINT  NOT NULL , cerejUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryRelatedEntryJoin (cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct, cerejUid) SELECT cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct, cerejUid FROM ContentEntryRelatedEntryJoin_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryRelatedEntryJoin_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryRelatedEntryJoin for PostgreSQL

        //Begin: Create table ContentCategorySchema for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentCategorySchema RENAME to ContentCategorySchema_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentCategorySchema (  schemaName  TEXT , schemaUrl  TEXT , contentCategorySchemaLocalChangeSeqNum  BIGINT  NOT NULL , contentCategorySchemaMasterChangeSeqNum  BIGINT  NOT NULL , contentCategorySchemaLastChangedBy  INTEGER  NOT NULL , contentCategorySchemaLct  BIGINT  NOT NULL , contentCategorySchemaUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentCategorySchema (schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct, contentCategorySchemaUid) SELECT schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct, contentCategorySchemaUid FROM ContentCategorySchema_OLD")
        _stmt.executeUpdate("DROP TABLE ContentCategorySchema_OLD")
        END MIGRATION*/
        //End: Create table ContentCategorySchema for PostgreSQL

        //Begin: Create table ContentCategory for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentCategory RENAME to ContentCategory_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentCategory (  ctnCatContentCategorySchemaUid  BIGINT  NOT NULL , name  TEXT , contentCategoryLocalChangeSeqNum  BIGINT  NOT NULL , contentCategoryMasterChangeSeqNum  BIGINT  NOT NULL , contentCategoryLastChangedBy  INTEGER  NOT NULL , contentCategoryLct  BIGINT  NOT NULL , contentCategoryUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentCategory (ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct, contentCategoryUid) SELECT ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct, contentCategoryUid FROM ContentCategory_OLD")
        _stmt.executeUpdate("DROP TABLE ContentCategory_OLD")
        END MIGRATION*/
        //End: Create table ContentCategory for PostgreSQL

        //Begin: Create table Language for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Language RENAME to Language_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Language (  name  TEXT , iso_639_1_standard  TEXT , iso_639_2_standard  TEXT , iso_639_3_standard  TEXT , Language_Type  TEXT , languageActive  BOOL  NOT NULL , langLocalChangeSeqNum  BIGINT  NOT NULL , langMasterChangeSeqNum  BIGINT  NOT NULL , langLastChangedBy  INTEGER  NOT NULL , langLct  BIGINT  NOT NULL , langUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Language (name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct, langUid) SELECT name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct, langUid FROM Language_OLD")
        _stmt.executeUpdate("DROP TABLE Language_OLD")
        END MIGRATION*/
        //End: Create table Language for PostgreSQL

        //Begin: Create table LanguageVariant for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE LanguageVariant RENAME to LanguageVariant_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS LanguageVariant (  langUid  BIGINT  NOT NULL , countryCode  TEXT , name  TEXT , langVariantLocalChangeSeqNum  BIGINT  NOT NULL , langVariantMasterChangeSeqNum  BIGINT  NOT NULL , langVariantLastChangedBy  INTEGER  NOT NULL , langVariantLct  BIGINT  NOT NULL , langVariantUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO LanguageVariant (langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct, langVariantUid) SELECT langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct, langVariantUid FROM LanguageVariant_OLD")
        _stmt.executeUpdate("DROP TABLE LanguageVariant_OLD")
        END MIGRATION*/
        //End: Create table LanguageVariant for PostgreSQL

        //Begin: Create table PersonAuth for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonAuth RENAME to PersonAuth_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonAuth (  passwordHash  TEXT , personAuthStatus  INTEGER  NOT NULL , personAuthUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonAuth (passwordHash, personAuthStatus, personAuthUid) SELECT passwordHash, personAuthStatus, personAuthUid FROM PersonAuth_OLD")
        _stmt.executeUpdate("DROP TABLE PersonAuth_OLD")
        END MIGRATION*/
        //End: Create table PersonAuth for PostgreSQL

        //Begin: Create table PersonGroup for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonGroup RENAME to PersonGroup_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonGroup (  groupMasterCsn  BIGINT  NOT NULL , groupLocalCsn  BIGINT  NOT NULL , groupLastChangedBy  INTEGER  NOT NULL , groupLct  BIGINT  NOT NULL , groupName  TEXT , groupActive  BOOL  NOT NULL , personGroupFlag  INTEGER  NOT NULL , groupUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonGroup (groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag, groupUid) SELECT groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag, groupUid FROM PersonGroup_OLD")
        _stmt.executeUpdate("DROP TABLE PersonGroup_OLD")
        END MIGRATION*/
        //End: Create table PersonGroup for PostgreSQL

        //Begin: Create table PersonGroupMember for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonGroupMember RENAME to PersonGroupMember_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonGroupMember (  groupMemberActive  BOOL  NOT NULL , groupMemberPersonUid  BIGINT  NOT NULL , groupMemberGroupUid  BIGINT  NOT NULL , groupMemberMasterCsn  BIGINT  NOT NULL , groupMemberLocalCsn  BIGINT  NOT NULL , groupMemberLastChangedBy  INTEGER  NOT NULL , groupMemberLct  BIGINT  NOT NULL , groupMemberUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonGroupMember (groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct, groupMemberUid) SELECT groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct, groupMemberUid FROM PersonGroupMember_OLD")
        _stmt.executeUpdate("DROP TABLE PersonGroupMember_OLD")
        END MIGRATION*/
        //End: Create table PersonGroupMember for PostgreSQL

        //Begin: Create table PersonPicture for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonPicture RENAME to PersonPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonPicture (  personPictureLct  BIGINT  NOT NULL , personPictureUri  TEXT , personPictureThumbnailUri  TEXT , fileSize  INTEGER  NOT NULL , personPictureActive  BOOL  NOT NULL , personPictureUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonPicture (personPictureLct, personPictureUri, personPictureThumbnailUri, fileSize, personPictureActive, personPictureUid) SELECT personPictureLct, personPictureUri, personPictureThumbnailUri, fileSize, personPictureActive, personPictureUid FROM PersonPicture_OLD")
        _stmt.executeUpdate("DROP TABLE PersonPicture_OLD")
        END MIGRATION*/
        //End: Create table PersonPicture for PostgreSQL

        //Begin: Create table VerbEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE VerbEntity RENAME to VerbEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS VerbEntity (  verbUid  BIGINT  PRIMARY KEY  NOT NULL , verbUrlId  TEXT , verbDeleted  BOOL  NOT NULL , verbLct  BIGINT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO VerbEntity (verbUid, verbUrlId, verbDeleted, verbLct) SELECT verbUid, verbUrlId, verbDeleted, verbLct FROM VerbEntity_OLD")
        _stmt.executeUpdate("DROP TABLE VerbEntity_OLD")
        END MIGRATION*/
        //End: Create table VerbEntity for PostgreSQL

        //Begin: Create table ActivityEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityEntity RENAME to ActivityEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityEntity (  actUid  BIGINT  PRIMARY KEY  NOT NULL , actIdIri  TEXT , actType  TEXT , actMoreInfo  TEXT , actInteractionType  INTEGER  NOT NULL , actCorrectResponsePatterns  TEXT , actLct  BIGINT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityEntity (actUid, actIdIri, actType, actMoreInfo, actInteractionType, actCorrectResponsePatterns, actLct) SELECT actUid, actIdIri, actType, actMoreInfo, actInteractionType, actCorrectResponsePatterns, actLct FROM ActivityEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityEntity for PostgreSQL

        //Begin: Create table StatementEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementEntity RENAME to StatementEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementEntity (  statementIdHi  BIGINT  NOT NULL , statementIdLo  BIGINT  NOT NULL , statementActorPersonUid  BIGINT  NOT NULL , statementVerbUid  BIGINT  NOT NULL , statementObjectType  INTEGER  NOT NULL , statementObjectUid1  BIGINT  NOT NULL , statementObjectUid2  BIGINT  NOT NULL , statementActorUid  BIGINT  NOT NULL , authorityActorUid  BIGINT  NOT NULL , teamUid  BIGINT  NOT NULL , resultCompletion  BOOL , resultSuccess  BOOL , resultScoreScaled  FLOAT , resultScoreRaw  FLOAT , resultScoreMin  FLOAT , resultScoreMax  FLOAT , resultDuration  BIGINT , resultResponse  TEXT , timestamp  BIGINT  NOT NULL , stored  BIGINT  NOT NULL , contextRegistrationHi  BIGINT  NOT NULL , contextRegistrationLo  BIGINT  NOT NULL , contextPlatform  TEXT , contextStatementRefIdHi  BIGINT  NOT NULL , contextStatementRefIdLo  BIGINT  NOT NULL , contextInstructorActorUid  BIGINT  NOT NULL , statementLct  BIGINT  NOT NULL , extensionProgress  INTEGER , completionOrProgress  BOOL  NOT NULL , statementContentEntryUid  BIGINT  NOT NULL , statementLearnerGroupUid  BIGINT  NOT NULL , statementClazzUid  BIGINT  NOT NULL , statementCbUid  BIGINT  NOT NULL , statementDoorNode  BIGINT  NOT NULL , isSubStatement  BOOL  NOT NULL , PRIMARY KEY (statementIdHi, statementIdLo) )"
        _stmtList +=
            "CREATE INDEX idx_stmt_actor_person ON StatementEntity (statementActorPersonUid)"
        _stmtList +=
            "CREATE INDEX idx_statement_clazz_person ON StatementEntity (statementClazzUid, statementActorPersonUid)"
        _stmtList +=
            "CREATE INDEX idx_statement_cbuid_actor ON StatementEntity (statementCbUid, statementActorUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementEntity (statementIdHi, statementIdLo, statementActorPersonUid, statementVerbUid, statementObjectType, statementObjectUid1, statementObjectUid2, statementActorUid, authorityActorUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistrationHi, contextRegistrationLo, contextPlatform, contextStatementRefIdHi, contextStatementRefIdLo, contextInstructorActorUid, statementLct, extensionProgress, completionOrProgress, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid, statementCbUid, statementDoorNode, isSubStatement) SELECT statementIdHi, statementIdLo, statementActorPersonUid, statementVerbUid, statementObjectType, statementObjectUid1, statementObjectUid2, statementActorUid, authorityActorUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistrationHi, contextRegistrationLo, contextPlatform, contextStatementRefIdHi, contextStatementRefIdLo, contextInstructorActorUid, statementLct, extensionProgress, completionOrProgress, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid, statementCbUid, statementDoorNode, isSubStatement FROM StatementEntity_OLD")
        _stmt.executeUpdate("DROP TABLE StatementEntity_OLD")
        END MIGRATION*/
        //End: Create table StatementEntity for PostgreSQL

        //Begin: Create table ActorEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActorEntity RENAME to ActorEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActorEntity (  actorPersonUid  BIGINT  NOT NULL , actorName  TEXT , actorMbox  TEXT , actorMbox_sha1sum  TEXT , actorOpenid  TEXT , actorAccountName  TEXT , actorAccountHomePage  TEXT , actorEtag  BIGINT  NOT NULL , actorLct  BIGINT  NOT NULL , actorObjectType  INTEGER  NOT NULL , actorUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList += "CREATE INDEX idx_actorentity_uid_personuid ON ActorEntity (actorPersonUid)"
        _stmtList += "CREATE INDEX idx_actorentity_actorobjecttype ON ActorEntity (actorObjectType)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActorEntity (actorPersonUid, actorName, actorMbox, actorMbox_sha1sum, actorOpenid, actorAccountName, actorAccountHomePage, actorEtag, actorLct, actorObjectType, actorUid) SELECT actorPersonUid, actorName, actorMbox, actorMbox_sha1sum, actorOpenid, actorAccountName, actorAccountHomePage, actorEtag, actorLct, actorObjectType, actorUid FROM ActorEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActorEntity_OLD")
        END MIGRATION*/
        //End: Create table ActorEntity for PostgreSQL

        //Begin: Create table SyncNode for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SyncNode RENAME to SyncNode_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SyncNode (  nodeClientId  BIGINT  NOT NULL , PRIMARY KEY (nodeClientId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SyncNode (nodeClientId) SELECT nodeClientId FROM SyncNode_OLD")
        _stmt.executeUpdate("DROP TABLE SyncNode_OLD")
        END MIGRATION*/
        //End: Create table SyncNode for PostgreSQL

        //Begin: Create table Comments for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Comments RENAME to Comments_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Comments (  commentsText  TEXT , commentsEntityUid  BIGINT  NOT NULL , commentsStatus  INTEGER  NOT NULL , commentsFromPersonUid  BIGINT  NOT NULL , commentsForSubmitterUid  BIGINT  NOT NULL , commentsFromSubmitterUid  BIGINT  NOT NULL , commentsFlagged  BOOL  NOT NULL , commentsDeleted  BOOL  NOT NULL , commentsDateTimeAdded  BIGINT  NOT NULL , commentsLct  BIGINT  NOT NULL , commentsUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_comments_entity_submitter ON Comments (commentsEntityUid, commentsForSubmitterUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Comments (commentsText, commentsEntityUid, commentsStatus, commentsFromPersonUid, commentsForSubmitterUid, commentsFromSubmitterUid, commentsFlagged, commentsDeleted, commentsDateTimeAdded, commentsLct, commentsUid) SELECT commentsText, commentsEntityUid, commentsStatus, commentsFromPersonUid, commentsForSubmitterUid, commentsFromSubmitterUid, commentsFlagged, commentsDeleted, commentsDateTimeAdded, commentsLct, commentsUid FROM Comments_OLD")
        _stmt.executeUpdate("DROP TABLE Comments_OLD")
        END MIGRATION*/
        //End: Create table Comments for PostgreSQL

        //Begin: Create table Report for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Report RENAME to Report_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Report (  reportOwnerUid  BIGINT  NOT NULL , xAxis  INTEGER  NOT NULL , reportDateRangeSelection  INTEGER  NOT NULL , fromDate  BIGINT  NOT NULL , fromRelTo  INTEGER  NOT NULL , fromRelOffSet  INTEGER  NOT NULL , fromRelUnit  INTEGER  NOT NULL , toDate  BIGINT  NOT NULL , toRelTo  INTEGER  NOT NULL , toRelOffSet  INTEGER  NOT NULL , toRelUnit  INTEGER  NOT NULL , reportTitle  TEXT , reportDescription  TEXT , reportSeries  TEXT , reportInactive  BOOL  NOT NULL , isTemplate  BOOL  NOT NULL , priority  INTEGER  NOT NULL , reportTitleId  INTEGER  NOT NULL , reportDescId  INTEGER  NOT NULL , reportMasterChangeSeqNum  BIGINT  NOT NULL , reportLocalChangeSeqNum  BIGINT  NOT NULL , reportLastChangedBy  INTEGER  NOT NULL , reportLct  BIGINT  NOT NULL , reportUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Report (reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct, reportUid) SELECT reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct, reportUid FROM Report_OLD")
        _stmt.executeUpdate("DROP TABLE Report_OLD")
        END MIGRATION*/
        //End: Create table Report for PostgreSQL

        //Begin: Create table Site for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Site RENAME to Site_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Site (  sitePcsn  BIGINT  NOT NULL , siteLcsn  BIGINT  NOT NULL , siteLcb  INTEGER  NOT NULL , siteLct  BIGINT  NOT NULL , siteName  TEXT , guestLogin  BOOL  NOT NULL , registrationAllowed  BOOL  NOT NULL , authSalt  TEXT , siteUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Site (sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt, siteUid) SELECT sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt, siteUid FROM Site_OLD")
        _stmt.executeUpdate("DROP TABLE Site_OLD")
        END MIGRATION*/
        //End: Create table Site for PostgreSQL

        //Begin: Create table SiteTerms for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SiteTerms RENAME to SiteTerms_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SiteTerms (  termsHtml  TEXT , sTermsLang  TEXT , sTermsLangUid  BIGINT  NOT NULL , sTermsActive  BOOL  NOT NULL , sTermsLastChangedBy  INTEGER  NOT NULL , sTermsPrimaryCsn  BIGINT  NOT NULL , sTermsLocalCsn  BIGINT  NOT NULL , sTermsLct  BIGINT  NOT NULL , sTermsUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SiteTerms (termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct, sTermsUid) SELECT termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct, sTermsUid FROM SiteTerms_OLD")
        _stmt.executeUpdate("DROP TABLE SiteTerms_OLD")
        END MIGRATION*/
        //End: Create table SiteTerms for PostgreSQL

        //Begin: Create table PersonParentJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonParentJoin RENAME to PersonParentJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonParentJoin (  ppjPcsn  BIGINT  NOT NULL , ppjLcsn  BIGINT  NOT NULL , ppjLcb  INTEGER  NOT NULL , ppjLct  BIGINT  NOT NULL , ppjParentPersonUid  BIGINT  NOT NULL , ppjMinorPersonUid  BIGINT  NOT NULL , ppjRelationship  INTEGER  NOT NULL , ppjEmail  TEXT , ppjPhone  TEXT , ppjInactive  BOOL  NOT NULL , ppjStatus  INTEGER  NOT NULL , ppjApprovalTiemstamp  BIGINT  NOT NULL , ppjApprovalIpAddr  TEXT , ppjUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonParentJoin (ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr, ppjUid) SELECT ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr, ppjUid FROM PersonParentJoin_OLD")
        _stmt.executeUpdate("DROP TABLE PersonParentJoin_OLD")
        END MIGRATION*/
        //End: Create table PersonParentJoin for PostgreSQL

        //Begin: Create table ScopedGrant for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ScopedGrant RENAME to ScopedGrant_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ScopedGrant (  sgPcsn  BIGINT  NOT NULL , sgLcsn  BIGINT  NOT NULL , sgLcb  INTEGER  NOT NULL , sgLct  BIGINT  NOT NULL , sgTableId  INTEGER  NOT NULL , sgEntityUid  BIGINT  NOT NULL , sgPermissions  BIGINT  NOT NULL , sgGroupUid  BIGINT  NOT NULL , sgIndex  INTEGER  NOT NULL , sgFlags  INTEGER  NOT NULL , sgUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_group_to_entity ON ScopedGrant (sgGroupUid, sgPermissions, sgTableId, sgEntityUid)"
        _stmtList +=
            "CREATE INDEX idx_entity_to_group ON ScopedGrant (sgTableId, sgEntityUid, sgPermissions, sgGroupUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ScopedGrant (sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags, sgUid) SELECT sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags, sgUid FROM ScopedGrant_OLD")
        _stmt.executeUpdate("DROP TABLE ScopedGrant_OLD")
        END MIGRATION*/
        //End: Create table ScopedGrant for PostgreSQL

        //Begin: Create table ErrorReport for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ErrorReport RENAME to ErrorReport_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ErrorReport (  errPcsn  BIGINT  NOT NULL , errLcsn  BIGINT  NOT NULL , errLcb  INTEGER  NOT NULL , errLct  BIGINT  NOT NULL , severity  INTEGER  NOT NULL , timestamp  BIGINT  NOT NULL , presenterUri  TEXT , appVersion  TEXT , versionCode  INTEGER  NOT NULL , errorCode  INTEGER  NOT NULL , operatingSys  TEXT , osVersion  TEXT , stackTrace  TEXT , message  TEXT , errUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ErrorReport (errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message, errUid) SELECT errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message, errUid FROM ErrorReport_OLD")
        _stmt.executeUpdate("DROP TABLE ErrorReport_OLD")
        END MIGRATION*/
        //End: Create table ErrorReport for PostgreSQL

        //Begin: Create table ClazzAssignment for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ClazzAssignment RENAME to ClazzAssignment_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ClazzAssignment (  caTitle  TEXT , caDescription  TEXT , caGroupUid  BIGINT  NOT NULL  DEFAULT 0 , caActive  BOOL  NOT NULL , caClassCommentEnabled  BOOL  NOT NULL , caPrivateCommentsEnabled  BOOL  NOT NULL  DEFAULT true, caCompletionCriteria  INTEGER  NOT NULL  DEFAULT 100 , caRequireFileSubmission  BOOL  NOT NULL  DEFAULT true, caFileType  INTEGER  NOT NULL  DEFAULT 0 , caSizeLimit  INTEGER  NOT NULL  DEFAULT 50 , caNumberOfFiles  INTEGER  NOT NULL  DEFAULT 1 , caSubmissionPolicy  INTEGER  NOT NULL  DEFAULT 1 , caMarkingType  INTEGER  NOT NULL  DEFAULT 1 , caRequireTextSubmission  BOOL  NOT NULL  DEFAULT true, caTextLimitType  INTEGER  NOT NULL  DEFAULT 1 , caTextLimit  INTEGER  NOT NULL  DEFAULT 500 , caXObjectUid  BIGINT  NOT NULL  DEFAULT 0 , caClazzUid  BIGINT  NOT NULL , caPeerReviewerCount  INTEGER  NOT NULL  DEFAULT 0 , caLocalChangeSeqNum  BIGINT  NOT NULL , caMasterChangeSeqNum  BIGINT  NOT NULL , caLastChangedBy  INTEGER  NOT NULL , caLct  BIGINT  NOT NULL , caUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ClazzAssignment (caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caPeerReviewerCount, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct, caUid) SELECT caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caPeerReviewerCount, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct, caUid FROM ClazzAssignment_OLD")
        _stmt.executeUpdate("DROP TABLE ClazzAssignment_OLD")
        END MIGRATION*/
        //End: Create table ClazzAssignment for PostgreSQL

        //Begin: Create table CourseAssignmentSubmission for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentSubmission RENAME to CourseAssignmentSubmission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentSubmission (  casAssignmentUid  BIGINT  NOT NULL , casSubmitterUid  BIGINT  NOT NULL , casSubmitterPersonUid  BIGINT  NOT NULL , casText  TEXT , casType  INTEGER  NOT NULL , casTimestamp  BIGINT  NOT NULL , casClazzUid  BIGINT  NOT NULL  DEFAULT 0 , casUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentSubmission (casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp, casClazzUid, casUid) SELECT casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp, casClazzUid, casUid FROM CourseAssignmentSubmission_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentSubmission_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentSubmission for PostgreSQL

        //Begin: Create table CourseAssignmentSubmissionFile for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentSubmissionFile RENAME to CourseAssignmentSubmissionFile_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentSubmissionFile (  casaSubmissionUid  BIGINT  NOT NULL , casaSubmitterUid  BIGINT  NOT NULL  DEFAULT 0 , casaCaUid  BIGINT  NOT NULL , casaClazzUid  BIGINT  NOT NULL , casaMimeType  TEXT , casaFileName  TEXT , casaUri  TEXT , casaSize  INTEGER  NOT NULL , casaTimestamp  BIGINT  NOT NULL , casaDeleted  BOOL  NOT NULL  DEFAULT false, casaUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentSubmissionFile (casaSubmissionUid, casaSubmitterUid, casaCaUid, casaClazzUid, casaMimeType, casaFileName, casaUri, casaSize, casaTimestamp, casaDeleted, casaUid) SELECT casaSubmissionUid, casaSubmitterUid, casaCaUid, casaClazzUid, casaMimeType, casaFileName, casaUri, casaSize, casaTimestamp, casaDeleted, casaUid FROM CourseAssignmentSubmissionFile_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentSubmissionFile_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentSubmissionFile for PostgreSQL

        //Begin: Create table CourseAssignmentMark for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseAssignmentMark RENAME to CourseAssignmentMark_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseAssignmentMark (  camAssignmentUid  BIGINT  NOT NULL , camSubmitterUid  BIGINT  NOT NULL , camMarkerSubmitterUid  BIGINT  NOT NULL  DEFAULT 0 , camMarkerPersonUid  BIGINT  NOT NULL  DEFAULT 0 , camMarkerComment  TEXT , camMark  FLOAT  NOT NULL , camMaxMark  FLOAT  NOT NULL  DEFAULT 1 , camPenalty  FLOAT  NOT NULL , camLct  BIGINT  NOT NULL , camClazzUid  BIGINT  NOT NULL  DEFAULT 0 , camUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseAssignmentMark (camAssignmentUid, camSubmitterUid, camMarkerSubmitterUid, camMarkerPersonUid, camMarkerComment, camMark, camMaxMark, camPenalty, camLct, camClazzUid, camUid) SELECT camAssignmentUid, camSubmitterUid, camMarkerSubmitterUid, camMarkerPersonUid, camMarkerComment, camMark, camMaxMark, camPenalty, camLct, camClazzUid, camUid FROM CourseAssignmentMark_OLD")
        _stmt.executeUpdate("DROP TABLE CourseAssignmentMark_OLD")
        END MIGRATION*/
        //End: Create table CourseAssignmentMark for PostgreSQL

        //Begin: Create table PeerReviewerAllocation for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PeerReviewerAllocation RENAME to PeerReviewerAllocation_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PeerReviewerAllocation (  praMarkerSubmitterUid  BIGINT  NOT NULL , praToMarkerSubmitterUid  BIGINT  NOT NULL , praAssignmentUid  BIGINT  NOT NULL , praActive  BOOL  NOT NULL , praLct  BIGINT  NOT NULL , praUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PeerReviewerAllocation (praMarkerSubmitterUid, praToMarkerSubmitterUid, praAssignmentUid, praActive, praLct, praUid) SELECT praMarkerSubmitterUid, praToMarkerSubmitterUid, praAssignmentUid, praActive, praLct, praUid FROM PeerReviewerAllocation_OLD")
        _stmt.executeUpdate("DROP TABLE PeerReviewerAllocation_OLD")
        END MIGRATION*/
        //End: Create table PeerReviewerAllocation for PostgreSQL

        //Begin: Create table PersonAuth2 for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonAuth2 RENAME to PersonAuth2_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonAuth2 (  pauthUid  BIGINT  PRIMARY KEY  NOT NULL , pauthMechanism  TEXT , pauthAuth  TEXT , pauthLcsn  BIGINT  NOT NULL , pauthPcsn  BIGINT  NOT NULL , pauthLcb  BIGINT  NOT NULL , pauthLct  BIGINT  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonAuth2 (pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct) SELECT pauthUid, pauthMechanism, pauthAuth, pauthLcsn, pauthPcsn, pauthLcb, pauthLct FROM PersonAuth2_OLD")
        _stmt.executeUpdate("DROP TABLE PersonAuth2_OLD")
        END MIGRATION*/
        //End: Create table PersonAuth2 for PostgreSQL

        //Begin: Create table UserSession for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE UserSession RENAME to UserSession_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS UserSession (  usPcsn  BIGINT  NOT NULL , usLcsn  BIGINT  NOT NULL , usLcb  BIGINT  NOT NULL , usLct  BIGINT  NOT NULL , usPersonUid  BIGINT  NOT NULL , usClientNodeId  BIGINT  NOT NULL , usStartTime  BIGINT  NOT NULL , usEndTime  BIGINT  NOT NULL , usStatus  INTEGER  NOT NULL , usReason  INTEGER  NOT NULL , usAuth  TEXT , usSessionType  INTEGER  NOT NULL , usUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX person_status_node_idx ON UserSession (usPersonUid, usStatus, usClientNodeId)"
        _stmtList +=
            "CREATE INDEX node_status_person_idx ON UserSession (usClientNodeId, usStatus, usPersonUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO UserSession (usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType, usUid) SELECT usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType, usUid FROM UserSession_OLD")
        _stmt.executeUpdate("DROP TABLE UserSession_OLD")
        END MIGRATION*/
        //End: Create table UserSession for PostgreSQL

        //Begin: Create table ContentJob for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentJob RENAME to ContentJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentJob (  toUri  TEXT , cjProgress  BIGINT  NOT NULL , cjTotal  BIGINT  NOT NULL , cjNotificationTitle  TEXT , cjIsMeteredAllowed  BOOL  NOT NULL , params  TEXT , cjLct  BIGINT  NOT NULL , cjUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentJob (toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params, cjLct, cjUid) SELECT toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params, cjLct, cjUid FROM ContentJob_OLD")
        _stmt.executeUpdate("DROP TABLE ContentJob_OLD")
        END MIGRATION*/
        //End: Create table ContentJob for PostgreSQL

        //Begin: Create table ContentEntryImportJob for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryImportJob RENAME to ContentEntryImportJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryImportJob (  sourceUri  TEXT , cjiOriginalFilename  TEXT , cjiContentEntryUid  BIGINT  NOT NULL , cjiParentContentEntryUid  BIGINT  NOT NULL , cjiContentEntryVersion  BIGINT  NOT NULL , cjiItemProgress  BIGINT  NOT NULL , cjiItemTotal  BIGINT  NOT NULL , cjiStatus  INTEGER  NOT NULL , cjiRecursiveStatus  INTEGER  NOT NULL , cjiPluginId  INTEGER  NOT NULL , cjiParentCjiUid  BIGINT  NOT NULL , cjiStartTime  BIGINT  NOT NULL , cjiFinishTime  BIGINT  NOT NULL , cjiContentDeletedOnCancellation  BOOL  NOT NULL , cjiCompressionLevel  INTEGER  NOT NULL  DEFAULT 3 , cjiError  TEXT , cjiErrorDismissed  BOOL  NOT NULL , cjiOwnerPersonUid  BIGINT  NOT NULL , cjiUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX index_ContentEntryImportJob_cjiContentEntryUid_cjiFinishTime ON ContentEntryImportJob (cjiContentEntryUid, cjiFinishTime)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryImportJob (sourceUri, cjiOriginalFilename, cjiContentEntryUid, cjiParentContentEntryUid, cjiContentEntryVersion, cjiItemProgress, cjiItemTotal, cjiStatus, cjiRecursiveStatus, cjiPluginId, cjiParentCjiUid, cjiStartTime, cjiFinishTime, cjiContentDeletedOnCancellation, cjiCompressionLevel, cjiError, cjiErrorDismissed, cjiOwnerPersonUid, cjiUid) SELECT sourceUri, cjiOriginalFilename, cjiContentEntryUid, cjiParentContentEntryUid, cjiContentEntryVersion, cjiItemProgress, cjiItemTotal, cjiStatus, cjiRecursiveStatus, cjiPluginId, cjiParentCjiUid, cjiStartTime, cjiFinishTime, cjiContentDeletedOnCancellation, cjiCompressionLevel, cjiError, cjiErrorDismissed, cjiOwnerPersonUid, cjiUid FROM ContentEntryImportJob_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryImportJob_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryImportJob for PostgreSQL

        //Begin: Create table CourseBlock for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseBlock RENAME to CourseBlock_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseBlock (  cbType  INTEGER  NOT NULL , cbIndentLevel  INTEGER  NOT NULL , cbModuleParentBlockUid  BIGINT  NOT NULL , cbTitle  TEXT , cbDescription  TEXT , cbCompletionCriteria  INTEGER  NOT NULL , cbHideUntilDate  BIGINT  NOT NULL , cbDeadlineDate  BIGINT  NOT NULL , cbLateSubmissionPenalty  INTEGER  NOT NULL , cbGracePeriodDate  BIGINT  NOT NULL , cbMaxPoints  FLOAT , cbMinPoints  FLOAT , cbIndex  INTEGER  NOT NULL , cbClazzUid  BIGINT  NOT NULL , cbClazzSourcedId  TEXT , cbActive  BOOL  NOT NULL , cbHidden  BOOL  NOT NULL , cbEntityUid  BIGINT  NOT NULL , cbLct  BIGINT  NOT NULL , cbSourcedId  TEXT , cbMetadata  TEXT , cbCreatedByAppId  TEXT , cbUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList += "CREATE INDEX idx_courseblock_cbclazzuid ON CourseBlock (cbClazzUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseBlock (cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId, cbUid) SELECT cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbClazzSourcedId, cbActive, cbHidden, cbEntityUid, cbLct, cbSourcedId, cbMetadata, cbCreatedByAppId, cbUid FROM CourseBlock_OLD")
        _stmt.executeUpdate("DROP TABLE CourseBlock_OLD")
        END MIGRATION*/
        //End: Create table CourseBlock for PostgreSQL

        //Begin: Create table CourseTerminology for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseTerminology RENAME to CourseTerminology_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseTerminology (  ctTitle  TEXT , ctTerminology  TEXT , ctLct  BIGINT  NOT NULL , ctUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseTerminology (ctTitle, ctTerminology, ctLct, ctUid) SELECT ctTitle, ctTerminology, ctLct, ctUid FROM CourseTerminology_OLD")
        _stmt.executeUpdate("DROP TABLE CourseTerminology_OLD")
        END MIGRATION*/
        //End: Create table CourseTerminology for PostgreSQL

        //Begin: Create table CourseGroupSet for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseGroupSet RENAME to CourseGroupSet_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseGroupSet (  cgsName  TEXT , cgsTotalGroups  INTEGER  NOT NULL , cgsActive  BOOL  NOT NULL , cgsClazzUid  BIGINT  NOT NULL , cgsLct  BIGINT  NOT NULL , cgsUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseGroupSet (cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct, cgsUid) SELECT cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct, cgsUid FROM CourseGroupSet_OLD")
        _stmt.executeUpdate("DROP TABLE CourseGroupSet_OLD")
        END MIGRATION*/
        //End: Create table CourseGroupSet for PostgreSQL

        //Begin: Create table CourseGroupMember for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseGroupMember RENAME to CourseGroupMember_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseGroupMember (  cgmSetUid  BIGINT  NOT NULL , cgmGroupNumber  INTEGER  NOT NULL , cgmPersonUid  BIGINT  NOT NULL , cgmLct  BIGINT  NOT NULL , cgmUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseGroupMember (cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct, cgmUid) SELECT cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct, cgmUid FROM CourseGroupMember_OLD")
        _stmt.executeUpdate("DROP TABLE CourseGroupMember_OLD")
        END MIGRATION*/
        //End: Create table CourseGroupMember for PostgreSQL

        //Begin: Create table ContentEntryPicture for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryPicture RENAME to ContentEntryPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryPicture (  cepContentEntryUid  BIGINT  NOT NULL , cepUri  TEXT , cepMd5  TEXT , cepFileSize  INTEGER  NOT NULL , cepTimestamp  BIGINT  NOT NULL , cepMimeType  TEXT , cepActive  BOOL  NOT NULL , cepUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryPicture (cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive, cepUid) SELECT cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive, cepUid FROM ContentEntryPicture_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryPicture_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryPicture for PostgreSQL

        //Begin: Create table ActivityInteractionEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityInteractionEntity RENAME to ActivityInteractionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityInteractionEntity (  aieActivityUid  BIGINT  NOT NULL , aieHash  BIGINT  NOT NULL , aieProp  INTEGER  NOT NULL , aieId  TEXT , aieLastMod  BIGINT  NOT NULL , aieIsDeleted  BOOL  NOT NULL , PRIMARY KEY (aieActivityUid, aieHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityInteractionEntity (aieActivityUid, aieHash, aieProp, aieId, aieLastMod, aieIsDeleted) SELECT aieActivityUid, aieHash, aieProp, aieId, aieLastMod, aieIsDeleted FROM ActivityInteractionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityInteractionEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityInteractionEntity for PostgreSQL

        //Begin: Create table CoursePicture for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CoursePicture RENAME to CoursePicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CoursePicture (  coursePictureLct  BIGINT  NOT NULL , coursePictureUri  TEXT , coursePictureThumbnailUri  TEXT , coursePictureActive  BOOL  NOT NULL , coursePictureUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CoursePicture (coursePictureLct, coursePictureUri, coursePictureThumbnailUri, coursePictureActive, coursePictureUid) SELECT coursePictureLct, coursePictureUri, coursePictureThumbnailUri, coursePictureActive, coursePictureUid FROM CoursePicture_OLD")
        _stmt.executeUpdate("DROP TABLE CoursePicture_OLD")
        END MIGRATION*/
        //End: Create table CoursePicture for PostgreSQL

        //Begin: Create table DiscussionPost for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DiscussionPost RENAME to DiscussionPost_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DiscussionPost (  discussionPostReplyToPostUid  BIGINT  NOT NULL , discussionPostTitle  TEXT , discussionPostMessage  TEXT , discussionPostStartDate  BIGINT  NOT NULL , discussionPostCourseBlockUid  BIGINT  NOT NULL , dpDeleted  BOOL  NOT NULL , discussionPostStartedPersonUid  BIGINT  NOT NULL , discussionPostClazzUid  BIGINT  NOT NULL , discussionPostLct  BIGINT  NOT NULL , discussionPostVisible  BOOL  NOT NULL , discussionPostArchive  BOOL  NOT NULL , discussionPostUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DiscussionPost (discussionPostReplyToPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostCourseBlockUid, dpDeleted, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct, discussionPostVisible, discussionPostArchive, discussionPostUid) SELECT discussionPostReplyToPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostCourseBlockUid, dpDeleted, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct, discussionPostVisible, discussionPostArchive, discussionPostUid FROM DiscussionPost_OLD")
        _stmt.executeUpdate("DROP TABLE DiscussionPost_OLD")
        END MIGRATION*/
        //End: Create table DiscussionPost for PostgreSQL

        //Begin: Create table ExternalAppPermission for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ExternalAppPermission RENAME to ExternalAppPermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ExternalAppPermission (  eapPersonUid  BIGINT  NOT NULL , eapPackageId  TEXT , eapStartTime  BIGINT  NOT NULL , eapExpireTime  BIGINT  NOT NULL , eapAuthToken  TEXT , eapAndroidAccountName  TEXT , eapUid  SERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ExternalAppPermission (eapPersonUid, eapPackageId, eapStartTime, eapExpireTime, eapAuthToken, eapAndroidAccountName, eapUid) SELECT eapPersonUid, eapPackageId, eapStartTime, eapExpireTime, eapAuthToken, eapAndroidAccountName, eapUid FROM ExternalAppPermission_OLD")
        _stmt.executeUpdate("DROP TABLE ExternalAppPermission_OLD")
        END MIGRATION*/
        //End: Create table ExternalAppPermission for PostgreSQL

        //Begin: Create table Message for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE Message RENAME to Message_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS Message (  messageSenderPersonUid  BIGINT  NOT NULL , messageToPersonUid  BIGINT  NOT NULL , messageText  TEXT , messageTimestamp  BIGINT  NOT NULL , messageLct  BIGINT  NOT NULL , messageUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX message_idx_send_to_time ON Message (messageSenderPersonUid, messageToPersonUid, messageTimestamp)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO Message (messageSenderPersonUid, messageToPersonUid, messageText, messageTimestamp, messageLct, messageUid) SELECT messageSenderPersonUid, messageToPersonUid, messageText, messageTimestamp, messageLct, messageUid FROM Message_OLD")
        _stmt.executeUpdate("DROP TABLE Message_OLD")
        END MIGRATION*/
        //End: Create table Message for PostgreSQL

        //Begin: Create table StudentResult for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StudentResult RENAME to StudentResult_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StudentResult (  srUid  BIGINT  PRIMARY KEY  NOT NULL , srSourcedId  TEXT , srCourseBlockUid  BIGINT  NOT NULL , srLineItemSourcedId  TEXT , srLineItemHref  TEXT , srClazzUid  BIGINT  NOT NULL , srAssignmentUid  BIGINT  NOT NULL , srStatus  INTEGER  NOT NULL , srMetaData  TEXT , srStudentPersonUid  BIGINT  NOT NULL , srStudentPersonSourcedId  TEXT , srStudentGroupId  INTEGER  NOT NULL , srMarkerPersonUid  BIGINT  NOT NULL , srMarkerGroupId  INTEGER  NOT NULL , srScoreStatus  INTEGER  NOT NULL , srScore  FLOAT  NOT NULL , srScoreDate  BIGINT  NOT NULL , srLastModified  BIGINT  NOT NULL , srComment  TEXT , srAppId  TEXT , srDeleted  BOOL  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StudentResult (srUid, srSourcedId, srCourseBlockUid, srLineItemSourcedId, srLineItemHref, srClazzUid, srAssignmentUid, srStatus, srMetaData, srStudentPersonUid, srStudentPersonSourcedId, srStudentGroupId, srMarkerPersonUid, srMarkerGroupId, srScoreStatus, srScore, srScoreDate, srLastModified, srComment, srAppId, srDeleted) SELECT srUid, srSourcedId, srCourseBlockUid, srLineItemSourcedId, srLineItemHref, srClazzUid, srAssignmentUid, srStatus, srMetaData, srStudentPersonUid, srStudentPersonSourcedId, srStudentGroupId, srMarkerPersonUid, srMarkerGroupId, srScoreStatus, srScore, srScoreDate, srLastModified, srComment, srAppId, srDeleted FROM StudentResult_OLD")
        _stmt.executeUpdate("DROP TABLE StudentResult_OLD")
        END MIGRATION*/
        //End: Create table StudentResult for PostgreSQL

        //Begin: Create table ContentEntryVersion for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryVersion RENAME to ContentEntryVersion_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryVersion (  cevContentEntryUid  BIGINT  NOT NULL , cevOpenUri  TEXT , cevContentType  TEXT , cevManifestUrl  TEXT , cevSize  BIGINT  NOT NULL , cevInActive  BOOL  NOT NULL , cevLastModified  BIGINT  NOT NULL , cevLct  BIGINT  NOT NULL , cevStorageSize  BIGINT  NOT NULL  DEFAULT 0 , cevOriginalSize  BIGINT  NOT NULL  DEFAULT 0 , cevUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryVersion (cevContentEntryUid, cevOpenUri, cevContentType, cevManifestUrl, cevSize, cevInActive, cevLastModified, cevLct, cevStorageSize, cevOriginalSize, cevUid) SELECT cevContentEntryUid, cevOpenUri, cevContentType, cevManifestUrl, cevSize, cevInActive, cevLastModified, cevLct, cevStorageSize, cevOriginalSize, cevUid FROM ContentEntryVersion_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryVersion_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryVersion for PostgreSQL

        //Begin: Create table TransferJob for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJob RENAME to TransferJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJob (  tjType  INTEGER  NOT NULL , tjStatus  INTEGER  NOT NULL , tjName  TEXT , tjUuid  TEXT , tjTableId  INTEGER  NOT NULL  DEFAULT 0 , tjEntityUid  BIGINT  NOT NULL  DEFAULT 0 , tjTimeCreated  BIGINT  NOT NULL  DEFAULT 0 , tjCreationType  INTEGER  NOT NULL  DEFAULT 0 , tjOiUid  BIGINT  NOT NULL  DEFAULT 0 , tjUid  SERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX TransferJob_idx_tjTableId_EntityUid ON TransferJob (tjTableId, tjEntityUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJob (tjType, tjStatus, tjName, tjUuid, tjTableId, tjEntityUid, tjTimeCreated, tjCreationType, tjOiUid, tjUid) SELECT tjType, tjStatus, tjName, tjUuid, tjTableId, tjEntityUid, tjTimeCreated, tjCreationType, tjOiUid, tjUid FROM TransferJob_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJob_OLD")
        END MIGRATION*/
        //End: Create table TransferJob for PostgreSQL

        //Begin: Create table TransferJobItem for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJobItem RENAME to TransferJobItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJobItem (  tjiTjUid  INTEGER  NOT NULL , tjTotalSize  BIGINT  NOT NULL , tjTransferred  BIGINT  NOT NULL , tjAttemptCount  INTEGER  NOT NULL , tjiSrc  TEXT , tjiDest  TEXT , tjiType  INTEGER  NOT NULL , tjiStatus  INTEGER  NOT NULL , tjiTableId  INTEGER  NOT NULL , tjiEntityUid  BIGINT  NOT NULL , tjiEntityEtag  BIGINT  NOT NULL  DEFAULT 0 , tjiLockIdToRelease  BIGINT  NOT NULL  DEFAULT 0 , tjiPartialTmpFile  TEXT , tjiUid  SERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX tji_table_entity_etag ON TransferJobItem (tjiTableId, tjiEntityUid, tjiEntityEtag)"
        _stmtList += "CREATE INDEX transferjob_tjuid ON TransferJobItem (tjiTjUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJobItem (tjiTjUid, tjTotalSize, tjTransferred, tjAttemptCount, tjiSrc, tjiDest, tjiType, tjiStatus, tjiTableId, tjiEntityUid, tjiEntityEtag, tjiLockIdToRelease, tjiPartialTmpFile, tjiUid) SELECT tjiTjUid, tjTotalSize, tjTransferred, tjAttemptCount, tjiSrc, tjiDest, tjiType, tjiStatus, tjiTableId, tjiEntityUid, tjiEntityEtag, tjiLockIdToRelease, tjiPartialTmpFile, tjiUid FROM TransferJobItem_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJobItem_OLD")
        END MIGRATION*/
        //End: Create table TransferJobItem for PostgreSQL

        //Begin: Create table CacheLockJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CacheLockJoin RENAME to CacheLockJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CacheLockJoin (  cljTableId  INTEGER  NOT NULL , cljEntityUid  BIGINT  NOT NULL , cljUrl  TEXT , cljLockId  BIGINT  NOT NULL , cljStatus  INTEGER  NOT NULL , cljType  INTEGER  NOT NULL , cljOiUid  BIGINT  NOT NULL  DEFAULT 0 , cljId  SERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_clj_table_entity_url ON CacheLockJoin (cljTableId, cljEntityUid, cljUrl)"
        _stmtList += "CREATE INDEX idx_clj_offline_item_uid ON CacheLockJoin (cljOiUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CacheLockJoin (cljTableId, cljEntityUid, cljUrl, cljLockId, cljStatus, cljType, cljOiUid, cljId) SELECT cljTableId, cljEntityUid, cljUrl, cljLockId, cljStatus, cljType, cljOiUid, cljId FROM CacheLockJoin_OLD")
        _stmt.executeUpdate("DROP TABLE CacheLockJoin_OLD")
        END MIGRATION*/
        //End: Create table CacheLockJoin for PostgreSQL

        //Begin: Create table OfflineItem for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OfflineItem RENAME to OfflineItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OfflineItem (  oiNodeId  BIGINT  NOT NULL , oiClazzUid  BIGINT  NOT NULL , oiCourseBlockUid  BIGINT  NOT NULL , oiContentEntryUid  BIGINT  NOT NULL , oiActive  BOOL  NOT NULL , oiLct  BIGINT  NOT NULL , oiUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX offline_item_node_content_entry ON OfflineItem (oiNodeId, oiContentEntryUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OfflineItem (oiNodeId, oiClazzUid, oiCourseBlockUid, oiContentEntryUid, oiActive, oiLct, oiUid) SELECT oiNodeId, oiClazzUid, oiCourseBlockUid, oiContentEntryUid, oiActive, oiLct, oiUid FROM OfflineItem_OLD")
        _stmt.executeUpdate("DROP TABLE OfflineItem_OLD")
        END MIGRATION*/
        //End: Create table OfflineItem for PostgreSQL

        //Begin: Create table OfflineItemPendingTransferJob for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OfflineItemPendingTransferJob RENAME to OfflineItemPendingTransferJob_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OfflineItemPendingTransferJob (  oiptjOiUid  BIGINT  NOT NULL , oiptjTableId  INTEGER  NOT NULL , oiptjEntityUid  BIGINT  NOT NULL , oiptjUrl  TEXT , oiptjType  INTEGER  NOT NULL , oiptjId  SERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OfflineItemPendingTransferJob (oiptjOiUid, oiptjTableId, oiptjEntityUid, oiptjUrl, oiptjType, oiptjId) SELECT oiptjOiUid, oiptjTableId, oiptjEntityUid, oiptjUrl, oiptjType, oiptjId FROM OfflineItemPendingTransferJob_OLD")
        _stmt.executeUpdate("DROP TABLE OfflineItemPendingTransferJob_OLD")
        END MIGRATION*/
        //End: Create table OfflineItemPendingTransferJob for PostgreSQL

        //Begin: Create table DeletedItem for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DeletedItem RENAME to DeletedItem_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DeletedItem (  delItemName  TEXT , delItemIconUri  TEXT , delItemLastModTime  BIGINT  NOT NULL , delItemTimeDeleted  BIGINT  NOT NULL , delItemEntityTable  INTEGER  NOT NULL , delItemEntityUid  BIGINT  NOT NULL , delItemDeletedByPersonUid  BIGINT  NOT NULL , delItemStatus  INTEGER  NOT NULL , delItemIsFolder  BOOL  NOT NULL  DEFAULT false, delItemUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX delitem_idx_status_time ON DeletedItem (delItemStatus, delItemTimeDeleted)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DeletedItem (delItemName, delItemIconUri, delItemLastModTime, delItemTimeDeleted, delItemEntityTable, delItemEntityUid, delItemDeletedByPersonUid, delItemStatus, delItemIsFolder, delItemUid) SELECT delItemName, delItemIconUri, delItemLastModTime, delItemTimeDeleted, delItemEntityTable, delItemEntityUid, delItemDeletedByPersonUid, delItemStatus, delItemIsFolder, delItemUid FROM DeletedItem_OLD")
        _stmt.executeUpdate("DROP TABLE DeletedItem_OLD")
        END MIGRATION*/
        //End: Create table DeletedItem for PostgreSQL

        //Begin: Create table EnrolmentRequest for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE EnrolmentRequest RENAME to EnrolmentRequest_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS EnrolmentRequest (  erClazzUid  BIGINT  NOT NULL , erClazzName  TEXT , erPersonUid  BIGINT  NOT NULL , erPersonFullname  TEXT , erPersonPictureUri  TEXT , erPersonUsername  TEXT , erRole  INTEGER  NOT NULL , erRequestTime  BIGINT  NOT NULL , erStatus  INTEGER  NOT NULL , erStatusSetByPersonUid  BIGINT  NOT NULL , erDeleted  BOOL  NOT NULL , erStatusSetAuth  TEXT , erLastModified  BIGINT  NOT NULL , erUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_enrolmentrequest_by_clazz ON EnrolmentRequest (erClazzUid, erStatus)"
        _stmtList +=
            "CREATE INDEX idx_enrolmentrequest_by_person ON EnrolmentRequest (erPersonUid, erStatus)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO EnrolmentRequest (erClazzUid, erClazzName, erPersonUid, erPersonFullname, erPersonPictureUri, erPersonUsername, erRole, erRequestTime, erStatus, erStatusSetByPersonUid, erDeleted, erStatusSetAuth, erLastModified, erUid) SELECT erClazzUid, erClazzName, erPersonUid, erPersonFullname, erPersonPictureUri, erPersonUsername, erRole, erRequestTime, erStatus, erStatusSetByPersonUid, erDeleted, erStatusSetAuth, erLastModified, erUid FROM EnrolmentRequest_OLD")
        _stmt.executeUpdate("DROP TABLE EnrolmentRequest_OLD")
        END MIGRATION*/
        //End: Create table EnrolmentRequest for PostgreSQL

        //Begin: Create table CoursePermission for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CoursePermission RENAME to CoursePermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CoursePermission (  cpLastModified  BIGINT  NOT NULL , cpClazzUid  BIGINT  NOT NULL , cpToEnrolmentRole  INTEGER  NOT NULL , cpToPersonUid  BIGINT  NOT NULL , cpToGroupUid  BIGINT  NOT NULL , cpPermissionsFlag  BIGINT  NOT NULL , cpIsDeleted  BOOL  NOT NULL , cpUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList += "CREATE INDEX idx_coursepermission_clazzuid ON CoursePermission (cpClazzUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CoursePermission (cpLastModified, cpClazzUid, cpToEnrolmentRole, cpToPersonUid, cpToGroupUid, cpPermissionsFlag, cpIsDeleted, cpUid) SELECT cpLastModified, cpClazzUid, cpToEnrolmentRole, cpToPersonUid, cpToGroupUid, cpPermissionsFlag, cpIsDeleted, cpUid FROM CoursePermission_OLD")
        _stmt.executeUpdate("DROP TABLE CoursePermission_OLD")
        END MIGRATION*/
        //End: Create table CoursePermission for PostgreSQL

        //Begin: Create table SystemPermission for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE SystemPermission RENAME to SystemPermission_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS SystemPermission (  spToPersonUid  BIGINT  NOT NULL , spToGroupUid  BIGINT  NOT NULL , spPermissionsFlag  BIGINT  NOT NULL , spLastModified  BIGINT  NOT NULL , spIsDeleted  BOOL  NOT NULL , spUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList +=
            "CREATE INDEX idx_systempermission_personuid ON SystemPermission (spToPersonUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO SystemPermission (spToPersonUid, spToGroupUid, spPermissionsFlag, spLastModified, spIsDeleted, spUid) SELECT spToPersonUid, spToGroupUid, spPermissionsFlag, spLastModified, spIsDeleted, spUid FROM SystemPermission_OLD")
        _stmt.executeUpdate("DROP TABLE SystemPermission_OLD")
        END MIGRATION*/
        //End: Create table SystemPermission for PostgreSQL

        //Begin: Create table CourseBlockPicture for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE CourseBlockPicture RENAME to CourseBlockPicture_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS CourseBlockPicture (  cbpUid  BIGINT  PRIMARY KEY  NOT NULL , cbpLct  BIGINT  NOT NULL , cbpPictureUri  TEXT , cbpThumbnailUri  TEXT )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO CourseBlockPicture (cbpUid, cbpLct, cbpPictureUri, cbpThumbnailUri) SELECT cbpUid, cbpLct, cbpPictureUri, cbpThumbnailUri FROM CourseBlockPicture_OLD")
        _stmt.executeUpdate("DROP TABLE CourseBlockPicture_OLD")
        END MIGRATION*/
        //End: Create table CourseBlockPicture for PostgreSQL

        //Begin: Create table ContentEntryPicture2 for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ContentEntryPicture2 RENAME to ContentEntryPicture2_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ContentEntryPicture2 (  cepUid  BIGINT  PRIMARY KEY  NOT NULL , cepLct  BIGINT  NOT NULL , cepPictureUri  TEXT , cepThumbnailUri  TEXT )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ContentEntryPicture2 (cepUid, cepLct, cepPictureUri, cepThumbnailUri) SELECT cepUid, cepLct, cepPictureUri, cepThumbnailUri FROM ContentEntryPicture2_OLD")
        _stmt.executeUpdate("DROP TABLE ContentEntryPicture2_OLD")
        END MIGRATION*/
        //End: Create table ContentEntryPicture2 for PostgreSQL

        //Begin: Create table TransferJobError for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE TransferJobError RENAME to TransferJobError_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS TransferJobError (  tjeTjUid  INTEGER  NOT NULL , tjeTime  BIGINT  NOT NULL , tjeErrorStr  TEXT , tjeDismissed  BOOL  NOT NULL , tjeId  SERIAL  PRIMARY KEY  NOT NULL )"
        _stmtList += "CREATE INDEX idx_transferjoberror_tjetjuid ON TransferJobError (tjeTjUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO TransferJobError (tjeTjUid, tjeTime, tjeErrorStr, tjeDismissed, tjeId) SELECT tjeTjUid, tjeTime, tjeErrorStr, tjeDismissed, tjeId FROM TransferJobError_OLD")
        _stmt.executeUpdate("DROP TABLE TransferJobError_OLD")
        END MIGRATION*/
        //End: Create table TransferJobError for PostgreSQL

        //Begin: Create table VerbLangMapEntry for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE VerbLangMapEntry RENAME to VerbLangMapEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS VerbLangMapEntry (  vlmeVerbUid  BIGINT  NOT NULL , vlmeLangHash  BIGINT  NOT NULL , vlmeLangCode  TEXT , vlmeEntryString  TEXT , vlmeLastModified  BIGINT  NOT NULL , PRIMARY KEY (vlmeVerbUid, vlmeLangHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO VerbLangMapEntry (vlmeVerbUid, vlmeLangHash, vlmeLangCode, vlmeEntryString, vlmeLastModified) SELECT vlmeVerbUid, vlmeLangHash, vlmeLangCode, vlmeEntryString, vlmeLastModified FROM VerbLangMapEntry_OLD")
        _stmt.executeUpdate("DROP TABLE VerbLangMapEntry_OLD")
        END MIGRATION*/
        //End: Create table VerbLangMapEntry for PostgreSQL

        //Begin: Create table GroupMemberActorJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE GroupMemberActorJoin RENAME to GroupMemberActorJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS GroupMemberActorJoin (  gmajGroupActorUid  BIGINT  NOT NULL , gmajMemberActorUid  BIGINT  NOT NULL , gmajLastMod  BIGINT  NOT NULL , PRIMARY KEY (gmajGroupActorUid, gmajMemberActorUid) )"
        _stmtList +=
            "CREATE INDEX idx_groupmemberactorjoin_gmajgroupactoruid ON GroupMemberActorJoin (gmajGroupActorUid)"
        _stmtList +=
            "CREATE INDEX idx_groupmemberactorjoin_gmajmemberactoruid ON GroupMemberActorJoin (gmajMemberActorUid)"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO GroupMemberActorJoin (gmajGroupActorUid, gmajMemberActorUid, gmajLastMod) SELECT gmajGroupActorUid, gmajMemberActorUid, gmajLastMod FROM GroupMemberActorJoin_OLD")
        _stmt.executeUpdate("DROP TABLE GroupMemberActorJoin_OLD")
        END MIGRATION*/
        //End: Create table GroupMemberActorJoin for PostgreSQL

        //Begin: Create table ActivityLangMapEntry for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityLangMapEntry RENAME to ActivityLangMapEntry_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityLangMapEntry (  almeActivityUid  BIGINT  NOT NULL , almeHash  BIGINT  NOT NULL , almeLangCode  TEXT , almeValue  TEXT , almeAieHash  BIGINT  NOT NULL , almeLastMod  BIGINT  NOT NULL , PRIMARY KEY (almeActivityUid, almeHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityLangMapEntry (almeActivityUid, almeHash, almeLangCode, almeValue, almeAieHash, almeLastMod) SELECT almeActivityUid, almeHash, almeLangCode, almeValue, almeAieHash, almeLastMod FROM ActivityLangMapEntry_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityLangMapEntry_OLD")
        END MIGRATION*/
        //End: Create table ActivityLangMapEntry for PostgreSQL

        //Begin: Create table ActivityExtensionEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ActivityExtensionEntity RENAME to ActivityExtensionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ActivityExtensionEntity (  aeeActivityUid  BIGINT  NOT NULL , aeeKeyHash  BIGINT  NOT NULL , aeeKey  TEXT , aeeJson  TEXT , aeeLastMod  BIGINT  NOT NULL , aeeIsDeleted  BOOL  NOT NULL , PRIMARY KEY (aeeActivityUid, aeeKeyHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ActivityExtensionEntity (aeeActivityUid, aeeKeyHash, aeeKey, aeeJson, aeeLastMod, aeeIsDeleted) SELECT aeeActivityUid, aeeKeyHash, aeeKey, aeeJson, aeeLastMod, aeeIsDeleted FROM ActivityExtensionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE ActivityExtensionEntity_OLD")
        END MIGRATION*/
        //End: Create table ActivityExtensionEntity for PostgreSQL

        //Begin: Create table StatementContextActivityJoin for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementContextActivityJoin RENAME to StatementContextActivityJoin_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementContextActivityJoin (  scajFromStatementIdHi  BIGINT  NOT NULL , scajFromStatementIdLo  BIGINT  NOT NULL , scajToHash  BIGINT  NOT NULL , scajContextType  INTEGER  NOT NULL , scajToActivityUid  BIGINT  NOT NULL , scajToActivityId  TEXT , scajEtag  BIGINT  NOT NULL , PRIMARY KEY (scajFromStatementIdHi, scajFromStatementIdLo, scajToHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementContextActivityJoin (scajFromStatementIdHi, scajFromStatementIdLo, scajToHash, scajContextType, scajToActivityUid, scajToActivityId, scajEtag) SELECT scajFromStatementIdHi, scajFromStatementIdLo, scajToHash, scajContextType, scajToActivityUid, scajToActivityId, scajEtag FROM StatementContextActivityJoin_OLD")
        _stmt.executeUpdate("DROP TABLE StatementContextActivityJoin_OLD")
        END MIGRATION*/
        //End: Create table StatementContextActivityJoin for PostgreSQL

        //Begin: Create table XapiSessionEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE XapiSessionEntity RENAME to XapiSessionEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS XapiSessionEntity (  xseLastMod  BIGINT  NOT NULL , xseRegistrationHi  BIGINT  NOT NULL , xseRegistrationLo  BIGINT  NOT NULL , xseUsUid  BIGINT  NOT NULL , xseAccountPersonUid  BIGINT  NOT NULL , xseActorUid  BIGINT  NOT NULL , xseAccountUsername  TEXT  NOT NULL , xseClazzUid  BIGINT  NOT NULL , xseCbUid  BIGINT  NOT NULL , xseContentEntryUid  BIGINT  NOT NULL , xseRootActivityId  TEXT  NOT NULL , xseRootActivityUid  BIGINT  NOT NULL , xseStartTime  BIGINT  NOT NULL , xseExpireTime  BIGINT  NOT NULL , xseAuth  TEXT , xseCompleted  BOOL  NOT NULL  DEFAULT false, knownActorUidToPersonUids  TEXT  NOT NULL , xseUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO XapiSessionEntity (xseLastMod, xseRegistrationHi, xseRegistrationLo, xseUsUid, xseAccountPersonUid, xseActorUid, xseAccountUsername, xseClazzUid, xseCbUid, xseContentEntryUid, xseRootActivityId, xseRootActivityUid, xseStartTime, xseExpireTime, xseAuth, xseCompleted, knownActorUidToPersonUids, xseUid) SELECT xseLastMod, xseRegistrationHi, xseRegistrationLo, xseUsUid, xseAccountPersonUid, xseActorUid, xseAccountUsername, xseClazzUid, xseCbUid, xseContentEntryUid, xseRootActivityId, xseRootActivityUid, xseStartTime, xseExpireTime, xseAuth, xseCompleted, knownActorUidToPersonUids, xseUid FROM XapiSessionEntity_OLD")
        _stmt.executeUpdate("DROP TABLE XapiSessionEntity_OLD")
        END MIGRATION*/
        //End: Create table XapiSessionEntity for PostgreSQL

        //Begin: Create table StatementEntityJson for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StatementEntityJson RENAME to StatementEntityJson_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StatementEntityJson (  stmtJsonIdHi  BIGINT  NOT NULL , stmtJsonIdLo  BIGINT  NOT NULL , stmtEtag  BIGINT  NOT NULL , fullStatement  TEXT , PRIMARY KEY (stmtJsonIdHi, stmtJsonIdLo) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StatementEntityJson (stmtJsonIdHi, stmtJsonIdLo, stmtEtag, fullStatement) SELECT stmtJsonIdHi, stmtJsonIdLo, stmtEtag, fullStatement FROM StatementEntityJson_OLD")
        _stmt.executeUpdate("DROP TABLE StatementEntityJson_OLD")
        END MIGRATION*/
        //End: Create table StatementEntityJson for PostgreSQL

        //Begin: Create table PersonPasskey for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PersonPasskey RENAME to PersonPasskey_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PersonPasskey (  ppPersonUid  BIGINT  NOT NULL , ppAttestationObj  TEXT , ppClientDataJson  TEXT , ppOriginString  TEXT , ppRpid  TEXT , ppId  TEXT , ppChallengeString  TEXT , ppPublicKey  TEXT , isRevoked  INTEGER  NOT NULL , ppPasskeyLct  BIGINT  NOT NULL , personPasskeyUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PersonPasskey (ppPersonUid, ppAttestationObj, ppClientDataJson, ppOriginString, ppRpid, ppId, ppChallengeString, ppPublicKey, isRevoked, ppPasskeyLct, personPasskeyUid) SELECT ppPersonUid, ppAttestationObj, ppClientDataJson, ppOriginString, ppRpid, ppId, ppChallengeString, ppPublicKey, isRevoked, ppPasskeyLct, personPasskeyUid FROM PersonPasskey_OLD")
        _stmt.executeUpdate("DROP TABLE PersonPasskey_OLD")
        END MIGRATION*/
        //End: Create table PersonPasskey for PostgreSQL

        //Begin: Create table StateEntity for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StateEntity RENAME to StateEntity_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StateEntity (  seActorUid  BIGINT  NOT NULL , seHash  BIGINT  NOT NULL , seActivityUid  BIGINT  NOT NULL , seStateId  TEXT  NOT NULL , seLastMod  BIGINT  NOT NULL , seTimeStored  BIGINT  NOT NULL , seContentType  TEXT  NOT NULL , seCompressed  INTEGER  NOT NULL , seContent  TEXT  NOT NULL , seDeleted  BOOL  NOT NULL , seRegistrationHi  BIGINT , seRegistrationLo  BIGINT , seH5PPreloaded  BOOL  NOT NULL , seH5PSubContentId  TEXT , PRIMARY KEY (seActorUid, seHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StateEntity (seActorUid, seHash, seActivityUid, seStateId, seLastMod, seTimeStored, seContentType, seCompressed, seContent, seDeleted, seRegistrationHi, seRegistrationLo, seH5PPreloaded, seH5PSubContentId) SELECT seActorUid, seHash, seActivityUid, seStateId, seLastMod, seTimeStored, seContentType, seCompressed, seContent, seDeleted, seRegistrationHi, seRegistrationLo, seH5PPreloaded, seH5PSubContentId FROM StateEntity_OLD")
        _stmt.executeUpdate("DROP TABLE StateEntity_OLD")
        END MIGRATION*/
        //End: Create table StateEntity for PostgreSQL

        //Begin: Create table StateDeleteCommand for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE StateDeleteCommand RENAME to StateDeleteCommand_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS StateDeleteCommand (  sdcActorUid  BIGINT  NOT NULL , sdcHash  BIGINT  NOT NULL , sdcActivityUid  BIGINT  NOT NULL , sdcStateId  TEXT , sdcLastMod  BIGINT  NOT NULL , sdcRegistrationHi  BIGINT , sdcRegistrationLo  BIGINT , PRIMARY KEY (sdcActorUid, sdcHash) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO StateDeleteCommand (sdcActorUid, sdcHash, sdcActivityUid, sdcStateId, sdcLastMod, sdcRegistrationHi, sdcRegistrationLo) SELECT sdcActorUid, sdcHash, sdcActivityUid, sdcStateId, sdcLastMod, sdcRegistrationHi, sdcRegistrationLo FROM StateDeleteCommand_OLD")
        _stmt.executeUpdate("DROP TABLE StateDeleteCommand_OLD")
        END MIGRATION*/
        //End: Create table StateDeleteCommand for PostgreSQL

        //Begin: Create table OutgoingReplication for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE OutgoingReplication RENAME to OutgoingReplication_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS OutgoingReplication (  destNodeId  BIGINT  NOT NULL , orPk1  BIGINT  NOT NULL , orPk2  BIGINT  NOT NULL  DEFAULT 0 , orPk3  BIGINT  NOT NULL  DEFAULT 0 , orPk4  BIGINT  NOT NULL  DEFAULT 0 , orTableId  INTEGER  NOT NULL , orUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO OutgoingReplication (destNodeId, orPk1, orPk2, orPk3, orPk4, orTableId, orUid) SELECT destNodeId, orPk1, orPk2, orPk3, orPk4, orTableId, orUid FROM OutgoingReplication_OLD")
        _stmt.executeUpdate("DROP TABLE OutgoingReplication_OLD")
        END MIGRATION*/
        //End: Create table OutgoingReplication for PostgreSQL

        //Begin: Create table ReplicationOperation for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE ReplicationOperation RENAME to ReplicationOperation_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS ReplicationOperation (  repOpRemoteNodeId  BIGINT  NOT NULL , repOpStatus  INTEGER  NOT NULL , repOpTableId  INTEGER  NOT NULL , PRIMARY KEY (repOpRemoteNodeId, repOpTableId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO ReplicationOperation (repOpRemoteNodeId, repOpStatus, repOpTableId) SELECT repOpRemoteNodeId, repOpStatus, repOpTableId FROM ReplicationOperation_OLD")
        _stmt.executeUpdate("DROP TABLE ReplicationOperation_OLD")
        END MIGRATION*/
        //End: Create table ReplicationOperation for PostgreSQL

        //Begin: Create table PendingRepositorySession for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE PendingRepositorySession RENAME to PendingRepositorySession_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS PendingRepositorySession (  endpointUrl  TEXT , remoteNodeId  BIGINT  NOT NULL , rsUid  BIGSERIAL  PRIMARY KEY  NOT NULL )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO PendingRepositorySession (endpointUrl, remoteNodeId, rsUid) SELECT endpointUrl, remoteNodeId, rsUid FROM PendingRepositorySession_OLD")
        _stmt.executeUpdate("DROP TABLE PendingRepositorySession_OLD")
        END MIGRATION*/
        //End: Create table PendingRepositorySession for PostgreSQL

        //Begin: Create table DoorNode for PostgreSQL
        /* START MIGRATION: 
        _stmt.executeUpdate("ALTER TABLE DoorNode RENAME to DoorNode_OLD")
        END MIGRATION */
        _stmtList +=
            "CREATE TABLE IF NOT EXISTS DoorNode (  auth  TEXT , nodeId  BIGINT  NOT NULL , rel  INTEGER  NOT NULL  DEFAULT 2 , PRIMARY KEY (nodeId) )"
        /* START MIGRATION: 
        _stmt.executeUpdate("INSERT INTO DoorNode (auth, nodeId, rel) SELECT auth, nodeId, rel FROM DoorNode_OLD")
        _stmt.executeUpdate("DROP TABLE DoorNode_OLD")
        END MIGRATION*/
        //End: Create table DoorNode for PostgreSQL

      }
    }
    return _stmtList
  }

  public fun makeClearAllTablesSql(): List<String> {
    val _stmtList = mutableListOf<String>()
    _stmtList += "DELETE FROM ClazzLog"
    _stmtList += "DELETE FROM ClazzLogAttendanceRecord"
    _stmtList += "DELETE FROM Schedule"
    _stmtList += "DELETE FROM HolidayCalendar"
    _stmtList += "DELETE FROM Holiday"
    _stmtList += "DELETE FROM Person"
    _stmtList += "DELETE FROM Clazz"
    _stmtList += "DELETE FROM ClazzEnrolment"
    _stmtList += "DELETE FROM LeavingReason"
    _stmtList += "DELETE FROM ContentEntry"
    _stmtList += "DELETE FROM ContentEntryContentCategoryJoin"
    _stmtList += "DELETE FROM ContentEntryParentChildJoin"
    _stmtList += "DELETE FROM ContentEntryRelatedEntryJoin"
    _stmtList += "DELETE FROM ContentCategorySchema"
    _stmtList += "DELETE FROM ContentCategory"
    _stmtList += "DELETE FROM Language"
    _stmtList += "DELETE FROM LanguageVariant"
    _stmtList += "DELETE FROM PersonAuth"
    _stmtList += "DELETE FROM PersonGroup"
    _stmtList += "DELETE FROM PersonGroupMember"
    _stmtList += "DELETE FROM PersonPicture"
    _stmtList += "DELETE FROM VerbEntity"
    _stmtList += "DELETE FROM ActivityEntity"
    _stmtList += "DELETE FROM StatementEntity"
    _stmtList += "DELETE FROM ActorEntity"
    _stmtList += "DELETE FROM SyncNode"
    _stmtList += "DELETE FROM Comments"
    _stmtList += "DELETE FROM Report"
    _stmtList += "DELETE FROM Site"
    _stmtList += "DELETE FROM SiteTerms"
    _stmtList += "DELETE FROM PersonParentJoin"
    _stmtList += "DELETE FROM ScopedGrant"
    _stmtList += "DELETE FROM ErrorReport"
    _stmtList += "DELETE FROM ClazzAssignment"
    _stmtList += "DELETE FROM CourseAssignmentSubmission"
    _stmtList += "DELETE FROM CourseAssignmentSubmissionFile"
    _stmtList += "DELETE FROM CourseAssignmentMark"
    _stmtList += "DELETE FROM PeerReviewerAllocation"
    _stmtList += "DELETE FROM PersonAuth2"
    _stmtList += "DELETE FROM UserSession"
    _stmtList += "DELETE FROM ContentJob"
    _stmtList += "DELETE FROM ContentEntryImportJob"
    _stmtList += "DELETE FROM CourseBlock"
    _stmtList += "DELETE FROM CourseTerminology"
    _stmtList += "DELETE FROM CourseGroupSet"
    _stmtList += "DELETE FROM CourseGroupMember"
    _stmtList += "DELETE FROM ContentEntryPicture"
    _stmtList += "DELETE FROM ActivityInteractionEntity"
    _stmtList += "DELETE FROM CoursePicture"
    _stmtList += "DELETE FROM DiscussionPost"
    _stmtList += "DELETE FROM ExternalAppPermission"
    _stmtList += "DELETE FROM Message"
    _stmtList += "DELETE FROM StudentResult"
    _stmtList += "DELETE FROM ContentEntryVersion"
    _stmtList += "DELETE FROM TransferJob"
    _stmtList += "DELETE FROM TransferJobItem"
    _stmtList += "DELETE FROM CacheLockJoin"
    _stmtList += "DELETE FROM OfflineItem"
    _stmtList += "DELETE FROM OfflineItemPendingTransferJob"
    _stmtList += "DELETE FROM DeletedItem"
    _stmtList += "DELETE FROM EnrolmentRequest"
    _stmtList += "DELETE FROM CoursePermission"
    _stmtList += "DELETE FROM SystemPermission"
    _stmtList += "DELETE FROM CourseBlockPicture"
    _stmtList += "DELETE FROM ContentEntryPicture2"
    _stmtList += "DELETE FROM TransferJobError"
    _stmtList += "DELETE FROM VerbLangMapEntry"
    _stmtList += "DELETE FROM GroupMemberActorJoin"
    _stmtList += "DELETE FROM ActivityLangMapEntry"
    _stmtList += "DELETE FROM ActivityExtensionEntity"
    _stmtList += "DELETE FROM StatementContextActivityJoin"
    _stmtList += "DELETE FROM XapiSessionEntity"
    _stmtList += "DELETE FROM StatementEntityJson"
    _stmtList += "DELETE FROM PersonPasskey"
    _stmtList += "DELETE FROM StateEntity"
    _stmtList += "DELETE FROM StateDeleteCommand"
    _stmtList += "DELETE FROM OutgoingReplication"
    _stmtList += "DELETE FROM ReplicationOperation"
    _stmtList += "DELETE FROM PendingRepositorySession"
    _stmtList += "DELETE FROM DoorNode"
    return _stmtList
  }

  override fun clearAllTables(): Unit = throw
      IllegalStateException("clearAllTables synchronous not supported on Javascript")

  override suspend fun clearAllTablesAsync() {
    execSQLBatchAsyncJs(*makeClearAllTablesSql().toTypedArray())
  }

  override fun personDao(): PersonDao = _PersonDao

  override fun clazzDao(): ClazzDao = _ClazzDao

  override fun courseBlockDao(): CourseBlockDao = _CourseBlockDao

  override fun courseTerminologyDao(): CourseTerminologyDao = _CourseTerminologyDao

  override fun courseGroupSetDao(): CourseGroupSetDao = _CourseGroupSetDao

  override fun courseGroupMemberDao(): CourseGroupMemberDao = _CourseGroupMemberDao

  override fun clazzEnrolmentDao(): ClazzEnrolmentDao = _ClazzEnrolmentDao

  override fun leavingReasonDao(): LeavingReasonDao = _LeavingReasonDao

  override fun contentEntryDao(): ContentEntryDao = _ContentEntryDao

  override fun contentEntryContentCategoryJoinDao(): ContentEntryContentCategoryJoinDao =
      _ContentEntryContentCategoryJoinDao

  override fun contentEntryParentChildJoinDao(): ContentEntryParentChildJoinDao =
      _ContentEntryParentChildJoinDao

  override fun contentEntryRelatedEntryJoinDao(): ContentEntryRelatedEntryJoinDao =
      _ContentEntryRelatedEntryJoinDao

  override fun contentCategorySchemaDao(): ContentCategorySchemaDao = _ContentCategorySchemaDao

  override fun contentCategoryDao(): ContentCategoryDao = _ContentCategoryDao

  override fun languageDao(): LanguageDao = _LanguageDao

  override fun languageVariantDao(): LanguageVariantDao = _LanguageVariantDao

  override fun personAuthDao(): PersonAuthDao = _PersonAuthDao

  override fun personGroupDao(): PersonGroupDao = _PersonGroupDao

  override fun personGroupMemberDao(): PersonGroupMemberDao = _PersonGroupMemberDao

  override fun personPictureDao(): PersonPictureDao = _PersonPictureDao

  override fun verbDao(): VerbDao = _VerbDao

  override fun activityEntityDao(): ActivityEntityDao = _ActivityEntityDao

  override fun reportDao(): ReportDao = _ReportDao

  override fun statementDao(): StatementDao = _StatementDao

  override fun actorDao(): ActorDao = _ActorDao

  override fun clazzLogAttendanceRecordDao(): ClazzLogAttendanceRecordDao =
      _ClazzLogAttendanceRecordDao

  override fun clazzLogDao(): ClazzLogDao = _ClazzLogDao

  override fun scheduleDao(): ScheduleDao = _ScheduleDao

  override fun holidayCalendarDao(): HolidayCalendarDao = _HolidayCalendarDao

  override fun holidayDao(): HolidayDao = _HolidayDao

  override fun clazzAssignmentDao(): ClazzAssignmentDao = _ClazzAssignmentDao

  override fun courseAssignmentSubmissionDao(): CourseAssignmentSubmissionDao =
      _CourseAssignmentSubmissionDao

  override fun courseAssignmentSubmissionFileDao(): CourseAssignmentSubmissionFileDao =
      _CourseAssignmentSubmissionFileDao

  override fun courseAssignmentMarkDao(): CourseAssignmentMarkDao = _CourseAssignmentMarkDao

  override fun commentsDao(): CommentsDao = _CommentsDao

  override fun syncNodeDao(): SyncNodeDao = _SyncNodeDao

  override fun siteDao(): SiteDao = _SiteDao

  override fun siteTermsDao(): SiteTermsDao = _SiteTermsDao

  override fun personParentJoinDao(): PersonParentJoinDao = _PersonParentJoinDao

  override fun scopedGrantDao(): ScopedGrantDao = _ScopedGrantDao

  override fun errorReportDao(): ErrorReportDao = _ErrorReportDao

  override fun personAuth2Dao(): PersonAuth2Dao = _PersonAuth2Dao

  override fun userSessionDao(): UserSessionDao = _UserSessionDao

  override fun contentEntryImportJobDao(): ContentEntryImportJobDao = _ContentEntryImportJobDao

  override fun coursePictureDao(): CoursePictureDao = _CoursePictureDao

  override fun contentEntryPictureDao(): ContentEntryPictureDao = _ContentEntryPictureDao

  override fun messageDao(): MessageDao = _MessageDao

  override fun peerReviewerAllocationDao(): PeerReviewerAllocationDao = _PeerReviewerAllocationDao

  override fun discussionPostDao(): DiscussionPostDao = _DiscussionPostDao

  override fun externalAppPermissionDao(): ExternalAppPermissionDao = _ExternalAppPermissionDao

  override fun contentEntryVersionDao(): ContentEntryVersionDao = _ContentEntryVersionDao

  override fun outgoingReplicationDao(): OutgoingReplicationDao = _OutgoingReplicationDao

  override fun transferJobDao(): TransferJobDao = _TransferJobDao

  override fun transferJobItemDao(): TransferJobItemDao = _TransferJobItemDao

  override fun cacheLockJoinDao(): CacheLockJoinDao = _CacheLockJoinDao

  override fun offlineItemDao(): OfflineItemDao = _OfflineItemDao

  override fun deletedItemDao(): DeletedItemDao = _DeletedItemDao

  override fun enrolmentRequestDao(): EnrolmentRequestDao = _EnrolmentRequestDao

  override fun coursePermissionDao(): CoursePermissionDao = _CoursePermissionDao

  override fun systemPermissionDao(): SystemPermissionDao = _SystemPermissionDao

  override fun courseBlockPictureDao(): CourseBlockPictureDao = _CourseBlockPictureDao

  override fun contentEntryPicture2Dao(): ContentEntryPicture2Dao = _ContentEntryPicture2Dao

  override fun transferJobErrorDao(): TransferJobErrorDao = _TransferJobErrorDao

  override fun studentResultDao(): StudentResultDao = _StudentResultDao

  override fun verbLangMapEntryDao(): VerbLangMapEntryDao = _VerbLangMapEntryDao

  override fun groupMemberActorJoinDao(): GroupMemberActorJoinDao = _GroupMemberActorJoinDao

  override fun activityLangMapEntryDao(): ActivityLangMapEntryDao = _ActivityLangMapEntryDao

  override fun activityInteractionDao(): ActivityInteractionDao = _ActivityInteractionDao

  override fun activityExtensionDao(): ActivityExtensionDao = _ActivityExtensionDao

  override fun statementContextActivityJoinDao(): StatementContextActivityJoinDao =
      _StatementContextActivityJoinDao

  override fun xapiSessionEntityDao(): XapiSessionEntityDao = _XapiSessionEntityDao

  override fun statementEntityJsonDao(): StatementEntityJsonDao = _StatementEntityJsonDao

  override fun personPasskeyDao(): PersonPasskeyDao = _PersonPasskeyDao

  override fun stateEntityDao(): StateEntityDao = _StateEntityDao

  override fun stateDeleteCommandDao(): StateDeleteCommandDao = _StateDeleteCommandDao

  override fun close() {
    jdbcImplHelper.close()
  }
}
