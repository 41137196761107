package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.PersonAndListDisplayDetails
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.composites.PersonNames
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAndDisplayDetail
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonPicture
import com.ustadmobile.lib.db.entities.PersonUidAndPasswordHash
import com.ustadmobile.lib.db.entities.TransferJobItem
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class PersonDao_JdbcImpl(
  public val _db: RoomDatabase,
) : PersonDao() {
  public val _insertAdapterPerson_abort: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person) {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.isPersonalAccount)
      stmt.setLong(10, entity.dateOfBirth)
      stmt.setString(11, entity.personAddress)
      stmt.setString(12, entity.personOrgId)
      stmt.setLong(13, entity.personGroupUid)
      stmt.setLong(14, entity.personLct)
      stmt.setString(15, entity.personCountry)
      stmt.setInt(16, entity.personType)
      stmt.setLong(17, entity.personMasterChangeSeqNum)
      stmt.setLong(18, entity.personLocalChangeSeqNum)
      stmt.setInt(19, entity.personLastChangedBy)
      stmt.setBoolean(20, entity.admin)
      stmt.setString(21, entity.personNotes)
      stmt.setString(22, entity.fatherName)
      stmt.setString(23, entity.fatherNumber)
      stmt.setString(24, entity.motherName)
      stmt.setString(25, entity.motherNum)
    }
  }

  public val _insertAdapterPerson_upsert: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, isPersonalAccount, dateOfBirth, personAddress, personOrgId, personGroupUid, personLct, personCountry, personType, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, admin, personNotes, fatherName, fatherNumber, motherName, motherNum) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person) {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.isPersonalAccount)
      stmt.setLong(10, entity.dateOfBirth)
      stmt.setString(11, entity.personAddress)
      stmt.setString(12, entity.personOrgId)
      stmt.setLong(13, entity.personGroupUid)
      stmt.setLong(14, entity.personLct)
      stmt.setString(15, entity.personCountry)
      stmt.setInt(16, entity.personType)
      stmt.setLong(17, entity.personMasterChangeSeqNum)
      stmt.setLong(18, entity.personLocalChangeSeqNum)
      stmt.setInt(19, entity.personLastChangedBy)
      stmt.setBoolean(20, entity.admin)
      stmt.setString(21, entity.personNotes)
      stmt.setString(22, entity.fatherName)
      stmt.setString(23, entity.fatherNumber)
      stmt.setString(24, entity.motherName)
      stmt.setString(25, entity.motherNum)
    }
  }

  public val _insertAdapterPersonAuth_abort: EntityInsertionAdapter<PersonAuth> = object :
      EntityInsertionAdapter<PersonAuth>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth) {
      if(entity.personAuthUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personAuthUid)
      }
      stmt.setString(2, entity.passwordHash)
      stmt.setInt(3, entity.personAuthStatus)
    }
  }

  public val _insertAdapterPersonGroup_abort: EntityInsertionAdapter<PersonGroup> = object :
      EntityInsertionAdapter<PersonGroup>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroup) {
      if(entity.groupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupUid)
      }
      stmt.setLong(2, entity.groupMasterCsn)
      stmt.setLong(3, entity.groupLocalCsn)
      stmt.setInt(4, entity.groupLastChangedBy)
      stmt.setLong(5, entity.groupLct)
      stmt.setString(6, entity.groupName)
      stmt.setBoolean(7, entity.groupActive)
      stmt.setInt(8, entity.personGroupFlag)
    }
  }

  public val _insertAdapterPersonGroupMember_abort: EntityInsertionAdapter<PersonGroupMember> =
      object : EntityInsertionAdapter<PersonGroupMember>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO PersonGroupMember (groupMemberUid, groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroupMember) {
      if(entity.groupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupMemberUid)
      }
      stmt.setBoolean(2, entity.groupMemberActive)
      stmt.setLong(3, entity.groupMemberPersonUid)
      stmt.setLong(4, entity.groupMemberGroupUid)
      stmt.setLong(5, entity.groupMemberMasterCsn)
      stmt.setLong(6, entity.groupMemberLocalCsn)
      stmt.setInt(7, entity.groupMemberLastChangedBy)
      stmt.setLong(8, entity.groupMemberLct)
    }
  }

  override suspend fun insertListAsync(entityList: List<Person>) {
    _insertAdapterPerson_abort.insertListAsync(entityList)
  }

  override suspend fun insertOrReplace(person: Person) {
    _insertAdapterPerson_upsert.insertAsync(person)
  }

  override fun insertPersonAuth(personAuth: PersonAuth) {
    _insertAdapterPersonAuth_abort.insert(personAuth)
  }

  override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_abort.insertAndReturnIdAsync(personGroup)
    return _retVal
  }

  override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _retVal = _insertAdapterPersonGroupMember_abort.insertAndReturnIdAsync(personGroupMember)
    return _retVal
  }

  public override fun insert(entity: Person): Long {
    val _retVal = _insertAdapterPerson_abort.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Person): Long {
    val _retVal = _insertAdapterPerson_abort.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Person>) {
    _insertAdapterPerson_abort.insertList(entityList)
  }

  override suspend fun updateAsync(entity: Person): Int {
    var _result = 0
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, isPersonalAccount = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personLct = ?, personCountry = ?, personType = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ? WHERE personUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.isPersonalAccount)
      _stmt.setLong(9, entity.dateOfBirth)
      _stmt.setString(10, entity.personAddress)
      _stmt.setString(11, entity.personOrgId)
      _stmt.setLong(12, entity.personGroupUid)
      _stmt.setLong(13, entity.personLct)
      _stmt.setString(14, entity.personCountry)
      _stmt.setInt(15, entity.personType)
      _stmt.setLong(16, entity.personMasterChangeSeqNum)
      _stmt.setLong(17, entity.personLocalChangeSeqNum)
      _stmt.setInt(18, entity.personLastChangedBy)
      _stmt.setBoolean(19, entity.admin)
      _stmt.setString(20, entity.personNotes)
      _stmt.setString(21, entity.fatherName)
      _stmt.setString(22, entity.fatherNumber)
      _stmt.setString(23, entity.motherName)
      _stmt.setString(24, entity.motherNum)
      _stmt.setLong(25, entity.personUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun update(entity: Person) {
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, isPersonalAccount = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personLct = ?, personCountry = ?, personType = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ? WHERE personUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.isPersonalAccount)
      _stmt.setLong(9, entity.dateOfBirth)
      _stmt.setString(10, entity.personAddress)
      _stmt.setString(11, entity.personOrgId)
      _stmt.setLong(12, entity.personGroupUid)
      _stmt.setLong(13, entity.personLct)
      _stmt.setString(14, entity.personCountry)
      _stmt.setInt(15, entity.personType)
      _stmt.setLong(16, entity.personMasterChangeSeqNum)
      _stmt.setLong(17, entity.personLocalChangeSeqNum)
      _stmt.setInt(18, entity.personLastChangedBy)
      _stmt.setBoolean(19, entity.admin)
      _stmt.setString(20, entity.personNotes)
      _stmt.setString(21, entity.fatherName)
      _stmt.setString(22, entity.fatherNumber)
      _stmt.setString(23, entity.motherName)
      _stmt.setString(24, entity.motherNum)
      _stmt.setLong(25, entity.personUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun countUsername(username: String): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT COUNT(*) FROM Person where Person.username = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  override suspend fun findUidAndPasswordHashAsync(username: String): PersonUidAndPasswordHash? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.personUid, Person.firstNames, Person.lastName, 
    |               PersonAuth.passwordHash
    |          FROM Person
    |               JOIN PersonAuth
    |                    ON Person.personUid = PersonAuth.personAuthUid
    |         WHERE Person.username = ?
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_passwordHash = _result.getString("passwordHash")
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        PersonUidAndPasswordHash().apply {
          this.passwordHash = _tmp_passwordHash
          this.personUid = _tmp_personUid
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
        }
      }
    }
  }

  override suspend fun findByUsernameAndPasswordHash2(username: String, passwordHash: String):
      Person? = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.*
    |          FROM Person
    |               JOIN PersonAuth2
    |                    ON Person.personUid = PersonAuth2.pauthUid
    |         WHERE Person.username = ? 
    |               AND PersonAuth2.pauthAuth = ?
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.setString(2,passwordHash)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override fun findByUsername(username: String?): Person? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT Person.* FROM PERSON Where Person.username = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override suspend fun findByUsernameAsync(username: String): Person? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT Person.* FROM PERSON Where Person.username = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override suspend fun findSystemAccount(nodeId: Long): Person? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.*
    |          FROM Person
    |         WHERE Person.dateOfBirth = CAST(? AS BIGINT)
    |           AND Person.personType = 1
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT Person.*
    |          FROM Person
    |         WHERE Person.dateOfBirth = ?
    |           AND Person.personType = 1
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,nodeId)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override fun findByUid(uid: Long): Person? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM PERSON WHERE Person.personUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM PERSON WHERE Person.personUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override suspend fun findByUidWithPicture(accountPersonUid: Long): PersonAndPicture? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.*, PersonPicture.*
    |          FROM Person
    |               LEFT JOIN PersonPicture
    |                    ON PersonPicture.personPictureUid = Person.personUid
    |         WHERE Person.personUid = CAST(? AS BIGINT)           
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT Person.*, PersonPicture.*
    |          FROM Person
    |               LEFT JOIN PersonPicture
    |                    ON PersonPicture.personPictureUid = Person.personUid
    |         WHERE Person.personUid = ?           
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
        var _tmp_PersonPicture_nullCount = 0
        val _tmp_personPictureUid = _result.getLong("personPictureUid")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureLct = _result.getLong("personPictureLct")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureUri = _result.getString("personPictureUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_fileSize = _result.getInt("fileSize")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
        PersonAndPicture().apply {
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.isPersonalAccount = _tmp_isPersonalAccount
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
            }
          }
          if(!_tmp_PersonPicture_isAllNull) {
            this.picture = PersonPicture().apply {
              this.personPictureUid = _tmp_personPictureUid
              this.personPictureLct = _tmp_personPictureLct
              this.personPictureUri = _tmp_personPictureUri
              this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
              this.fileSize = _tmp_fileSize
              this.personPictureActive = _tmp_personPictureActive
            }
          }
        }
      }
    }
  }

  override fun findByUidWithPictureAsFlow(uid: Long): Flow<PersonAndPicture?> =
      _db.doorFlow(arrayOf("Person", "PersonPicture")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT Person.*, PersonPicture.*
      |          FROM Person
      |               LEFT JOIN PersonPicture
      |                    ON PersonPicture.personPictureUid = Person.personUid
      |         WHERE Person.personUid = CAST(? AS BIGINT)           
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT Person.*, PersonPicture.*
      |          FROM Person
      |               LEFT JOIN PersonPicture
      |                    ON PersonPicture.personPictureUid = Person.personUid
      |         WHERE Person.personUid = ?           
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_Person_nullCount = 0
          val _tmp_personUid = _result.getLong("personUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_username = _result.getString("username")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_firstNames = _result.getString("firstNames")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_lastName = _result.getString("lastName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_emailAddr = _result.getString("emailAddr")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_phoneNum = _result.getString("phoneNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_gender = _result.getInt("gender")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_active = _result.getBoolean("active")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personAddress = _result.getString("personAddress")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personOrgId = _result.getString("personOrgId")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLct = _result.getLong("personLct")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personCountry = _result.getString("personCountry")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personType = _result.getInt("personType")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_admin = _result.getBoolean("admin")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personNotes = _result.getString("personNotes")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherName = _result.getString("fatherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherName = _result.getString("motherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherNum = _result.getString("motherNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
          var _tmp_PersonPicture_nullCount = 0
          val _tmp_personPictureUid = _result.getLong("personPictureUid")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureLct = _result.getLong("personPictureLct")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureUri = _result.getString("personPictureUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_fileSize = _result.getInt("fileSize")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
          PersonAndPicture().apply {
            if(!_tmp_Person_isAllNull) {
              this.person = Person().apply {
                this.personUid = _tmp_personUid
                this.username = _tmp_username
                this.firstNames = _tmp_firstNames
                this.lastName = _tmp_lastName
                this.emailAddr = _tmp_emailAddr
                this.phoneNum = _tmp_phoneNum
                this.gender = _tmp_gender
                this.active = _tmp_active
                this.isPersonalAccount = _tmp_isPersonalAccount
                this.dateOfBirth = _tmp_dateOfBirth
                this.personAddress = _tmp_personAddress
                this.personOrgId = _tmp_personOrgId
                this.personGroupUid = _tmp_personGroupUid
                this.personLct = _tmp_personLct
                this.personCountry = _tmp_personCountry
                this.personType = _tmp_personType
                this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                this.personLastChangedBy = _tmp_personLastChangedBy
                this.admin = _tmp_admin
                this.personNotes = _tmp_personNotes
                this.fatherName = _tmp_fatherName
                this.fatherNumber = _tmp_fatherNumber
                this.motherName = _tmp_motherName
                this.motherNum = _tmp_motherNum
              }
            }
            if(!_tmp_PersonPicture_isAllNull) {
              this.picture = PersonPicture().apply {
                this.personPictureUid = _tmp_personPictureUid
                this.personPictureLct = _tmp_personPictureLct
                this.personPictureUri = _tmp_personPictureUri
                this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
                this.fileSize = _tmp_fileSize
                this.personPictureActive = _tmp_personPictureActive
              }
            }
          }
        }
      }
    }
  }

  override fun findByUidLive(uid: Long): Flow<Person?> = _db.doorFlow(arrayOf("Person")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * From Person WHERE personUid = CAST(? AS BIGINT)",
      postgreSql = """
      |SELECT * From Person WHERE personUid = ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_personUid = _result.getLong("personUid")
          val _tmp_username = _result.getString("username")
          val _tmp_firstNames = _result.getString("firstNames")
          val _tmp_lastName = _result.getString("lastName")
          val _tmp_emailAddr = _result.getString("emailAddr")
          val _tmp_phoneNum = _result.getString("phoneNum")
          val _tmp_gender = _result.getInt("gender")
          val _tmp_active = _result.getBoolean("active")
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          val _tmp_personAddress = _result.getString("personAddress")
          val _tmp_personOrgId = _result.getString("personOrgId")
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          val _tmp_personLct = _result.getLong("personLct")
          val _tmp_personCountry = _result.getString("personCountry")
          val _tmp_personType = _result.getInt("personType")
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          val _tmp_admin = _result.getBoolean("admin")
          val _tmp_personNotes = _result.getString("personNotes")
          val _tmp_fatherName = _result.getString("fatherName")
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          val _tmp_motherName = _result.getString("motherName")
          val _tmp_motherNum = _result.getString("motherNum")
          Person().apply {
            this.personUid = _tmp_personUid
            this.username = _tmp_username
            this.firstNames = _tmp_firstNames
            this.lastName = _tmp_lastName
            this.emailAddr = _tmp_emailAddr
            this.phoneNum = _tmp_phoneNum
            this.gender = _tmp_gender
            this.active = _tmp_active
            this.isPersonalAccount = _tmp_isPersonalAccount
            this.dateOfBirth = _tmp_dateOfBirth
            this.personAddress = _tmp_personAddress
            this.personOrgId = _tmp_personOrgId
            this.personGroupUid = _tmp_personGroupUid
            this.personLct = _tmp_personLct
            this.personCountry = _tmp_personCountry
            this.personType = _tmp_personType
            this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
            this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
            this.personLastChangedBy = _tmp_personLastChangedBy
            this.admin = _tmp_admin
            this.personNotes = _tmp_personNotes
            this.fatherName = _tmp_fatherName
            this.fatherNumber = _tmp_fatherNumber
            this.motherName = _tmp_motherName
            this.motherNum = _tmp_motherNum
          }
        }
      }
    }
  }

  override suspend fun findByUidAsync(uid: Long): Person? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM Person WHERE personUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM Person WHERE personUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override fun findByUidAsFlow(uid: Long): Flow<Person?> = _db.doorFlow(arrayOf("Person")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * FROM Person WHERE personUid = CAST(? AS BIGINT)",
      postgreSql = """
      |SELECT * FROM Person WHERE personUid = ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_personUid = _result.getLong("personUid")
          val _tmp_username = _result.getString("username")
          val _tmp_firstNames = _result.getString("firstNames")
          val _tmp_lastName = _result.getString("lastName")
          val _tmp_emailAddr = _result.getString("emailAddr")
          val _tmp_phoneNum = _result.getString("phoneNum")
          val _tmp_gender = _result.getInt("gender")
          val _tmp_active = _result.getBoolean("active")
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          val _tmp_personAddress = _result.getString("personAddress")
          val _tmp_personOrgId = _result.getString("personOrgId")
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          val _tmp_personLct = _result.getLong("personLct")
          val _tmp_personCountry = _result.getString("personCountry")
          val _tmp_personType = _result.getInt("personType")
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          val _tmp_admin = _result.getBoolean("admin")
          val _tmp_personNotes = _result.getString("personNotes")
          val _tmp_fatherName = _result.getString("fatherName")
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          val _tmp_motherName = _result.getString("motherName")
          val _tmp_motherNum = _result.getString("motherNum")
          Person().apply {
            this.personUid = _tmp_personUid
            this.username = _tmp_username
            this.firstNames = _tmp_firstNames
            this.lastName = _tmp_lastName
            this.emailAddr = _tmp_emailAddr
            this.phoneNum = _tmp_phoneNum
            this.gender = _tmp_gender
            this.active = _tmp_active
            this.isPersonalAccount = _tmp_isPersonalAccount
            this.dateOfBirth = _tmp_dateOfBirth
            this.personAddress = _tmp_personAddress
            this.personOrgId = _tmp_personOrgId
            this.personGroupUid = _tmp_personGroupUid
            this.personLct = _tmp_personLct
            this.personCountry = _tmp_personCountry
            this.personType = _tmp_personType
            this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
            this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
            this.personLastChangedBy = _tmp_personLastChangedBy
            this.admin = _tmp_admin
            this.personNotes = _tmp_personNotes
            this.fatherName = _tmp_fatherName
            this.fatherNumber = _tmp_fatherNumber
            this.motherName = _tmp_motherName
            this.motherNum = _tmp_motherNum
          }
        }
      }
    }
  }

  override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): List<PersonAndListDisplayDetails> = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |
    |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
    |              /* Select personUids that can be viewed based on CoursePermission given the active user 
    |                 for their enrolments 
    |              */
    |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
    |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
    |                      JOIN CoursePermission 
    |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
    |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
    |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
    |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
    |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
    |                WHERE CAST(? AS BIGINT) != 0
    |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = CAST(? AS BIGINT)
    |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
    |              
    |               UNION
    |               /* Select personUids that can be viewed based on CoursePermission for the active user
    |                  where the CoursePermission is granted directly to them
    |                */   
    |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
    |                 FROM CoursePermission
    |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
    |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
    |                WHERE CAST(? AS BIGINT) != 0
    |                  AND CoursePermission.cpToPersonUid = CAST(? AS BIGINT))
    |               
    |         SELECT Person.*, PersonPicture.*
    |           FROM Person
    |                LEFT JOIN PersonPicture
    |                     ON PersonPicture.personPictureUid = Person.personUid
    |          WHERE /* Begin permission check */ 
    |                (         
    |                      (
    |        EXISTS(SELECT 1
    |                 FROM SystemPermission
    |                WHERE CAST(? AS BIGINT) != 0 
    |                  AND SystemPermission.spToPersonUid = CAST(? AS BIGINT)
    |                  AND (SystemPermission.spPermissionsFlag &
    |    
    |                       8192
    |                       
    |        ) > 0
    |                  AND NOT SystemPermission.spIsDeleted)
    |    )
    |                    OR (Person.personUid IN 
    |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
    |                                  FROM CanViewPersonUidsViaCoursePermission))
    |                    OR (Person.personUid = CAST(? AS BIGINT))
    |                )
    |                /* End permission check */
    |           AND (CAST(? AS BIGINT) = 0 OR CAST(? AS BIGINT) NOT IN
    |                    (SELECT clazzEnrolmentClazzUid 
    |                       FROM ClazzEnrolment 
    |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                            AND CAST(? AS BIGINT) BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
    |                        AND ClazzEnrolment.clazzEnrolmentActive))
    |           AND Person.personType = 0                  
    |           AND (Person.personUid NOT IN (?))
    |           AND (? = '%' 
    |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
    |      GROUP BY Person.personUid, PersonPicture.personPictureUid
    |      ORDER BY CASE(?)
    |               WHEN 1 THEN Person.firstNames
    |               WHEN 3 THEN Person.lastName
    |               ELSE ''
    |               END ASC,
    |               CASE(?)
    |               WHEN 2 THEN Person.firstNames
    |               WHEN 4 THEN Person.lastName
    |               ELSE ''
    |               END DESC
    |    
    """.trimMargin(),
    hasListParams = true,
    postgreSql = """
    |
    |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
    |              /* Select personUids that can be viewed based on CoursePermission given the active user 
    |                 for their enrolments 
    |              */
    |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
    |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
    |                      JOIN CoursePermission 
    |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
    |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
    |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
    |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
    |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
    |                WHERE ? != 0
    |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = ?
    |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
    |              
    |               UNION
    |               /* Select personUids that can be viewed based on CoursePermission for the active user
    |                  where the CoursePermission is granted directly to them
    |                */   
    |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
    |                 FROM CoursePermission
    |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
    |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
    |                WHERE ? != 0
    |                  AND CoursePermission.cpToPersonUid = ?)
    |               
    |         SELECT Person.*, PersonPicture.*
    |           FROM Person
    |                LEFT JOIN PersonPicture
    |                     ON PersonPicture.personPictureUid = Person.personUid
    |          WHERE /* Begin permission check */ 
    |                (         
    |                      (
    |        EXISTS(SELECT 1
    |                 FROM SystemPermission
    |                WHERE ? != 0 
    |                  AND SystemPermission.spToPersonUid = ?
    |                  AND (SystemPermission.spPermissionsFlag &
    |    
    |                       8192
    |                       
    |        ) > 0
    |                  AND NOT SystemPermission.spIsDeleted)
    |    )
    |                    OR (Person.personUid IN 
    |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
    |                                  FROM CanViewPersonUidsViaCoursePermission))
    |                    OR (Person.personUid = ?)
    |                )
    |                /* End permission check */
    |           AND (? = 0 OR ? NOT IN
    |                    (SELECT clazzEnrolmentClazzUid 
    |                       FROM ClazzEnrolment 
    |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
    |                        AND ClazzEnrolment.clazzEnrolmentActive))
    |           AND Person.personType = 0                  
    |           AND (Person.personUid NOT IN (?))
    |           AND (? = '%' 
    |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
    |      GROUP BY Person.personUid, PersonPicture.personPictureUid
    |      ORDER BY CASE(?)
    |               WHEN 1 THEN Person.firstNames
    |               WHEN 3 THEN Person.lastName
    |               ELSE ''
    |               END ASC,
    |               CASE(?)
    |               WHEN 2 THEN Person.firstNames
    |               WHEN 4 THEN Person.lastName
    |               ELSE ''
    |               END DESC
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,accountPersonUid)
    _stmt.setLong(2,accountPersonUid)
    _stmt.setLong(3,accountPersonUid)
    _stmt.setLong(4,accountPersonUid)
    _stmt.setLong(5,accountPersonUid)
    _stmt.setLong(6,accountPersonUid)
    _stmt.setLong(7,accountPersonUid)
    _stmt.setLong(8,excludeClazz)
    _stmt.setLong(9,excludeClazz)
    _stmt.setLong(10,timestamp)
    _stmt.setArray(11, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        excludeSelected.toTypedArray()))
    _stmt.setString(12,searchText)
    _stmt.setString(13,searchText)
    _stmt.setInt(14,sortOrder)
    _stmt.setInt(15,sortOrder)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
        var _tmp_PersonPicture_nullCount = 0
        val _tmp_personPictureUid = _result.getLong("personPictureUid")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureLct = _result.getLong("personPictureLct")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureUri = _result.getString("personPictureUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_fileSize = _result.getInt("fileSize")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
        PersonAndListDisplayDetails().apply {
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.isPersonalAccount = _tmp_isPersonalAccount
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
            }
          }
          if(!_tmp_PersonPicture_isAllNull) {
            this.picture = PersonPicture().apply {
              this.personPictureUid = _tmp_personPictureUid
              this.personPictureLct = _tmp_personPictureLct
              this.personPictureUri = _tmp_personPictureUri
              this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
              this.fileSize = _tmp_fileSize
              this.personPictureActive = _tmp_personPictureActive
            }
          }
        }
      }
    }
  }

  override fun findPersonsWithPermissionAsPagingSource(
    timestamp: Long,
    excludeClazz: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?,
  ): PagingSource<Int, PersonAndListDisplayDetails> = object :
      DoorLimitOffsetPagingSource<PersonAndListDisplayDetails>(db = _db
  , tableNames = arrayOf("SystemPermission", "ClazzEnrolment", "Person", "PersonPicture",
      "CoursePermission")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<PersonAndListDisplayDetails> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
      |              /* Select personUids that can be viewed based on CoursePermission given the active user 
      |                 for their enrolments 
      |              */
      |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
      |                      JOIN CoursePermission 
      |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
      |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
      |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE CAST(? AS BIGINT) != 0
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = CAST(? AS BIGINT)
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
      |              
      |               UNION
      |               /* Select personUids that can be viewed based on CoursePermission for the active user
      |                  where the CoursePermission is granted directly to them
      |                */   
      |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM CoursePermission
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE CAST(? AS BIGINT) != 0
      |                  AND CoursePermission.cpToPersonUid = CAST(? AS BIGINT))
      |               
      |         SELECT Person.*, PersonPicture.*
      |           FROM Person
      |                LEFT JOIN PersonPicture
      |                     ON PersonPicture.personPictureUid = Person.personUid
      |          WHERE /* Begin permission check */ 
      |                (         
      |                      (
      |        EXISTS(SELECT 1
      |                 FROM SystemPermission
      |                WHERE CAST(? AS BIGINT) != 0 
      |                  AND SystemPermission.spToPersonUid = CAST(? AS BIGINT)
      |                  AND (SystemPermission.spPermissionsFlag &
      |    
      |                       8192
      |                       
      |        ) > 0
      |                  AND NOT SystemPermission.spIsDeleted)
      |    )
      |                    OR (Person.personUid IN 
      |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
      |                                  FROM CanViewPersonUidsViaCoursePermission))
      |                    OR (Person.personUid = CAST(? AS BIGINT))
      |                )
      |                /* End permission check */
      |           AND (CAST(? AS BIGINT) = 0 OR CAST(? AS BIGINT) NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND CAST(? AS BIGINT) BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |                        AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid, PersonPicture.personPictureUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |SELECT * FROM (
      |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
      |              /* Select personUids that can be viewed based on CoursePermission given the active user 
      |                 for their enrolments 
      |              */
      |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
      |                      JOIN CoursePermission 
      |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
      |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
      |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE ? != 0
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = ?
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
      |              
      |               UNION
      |               /* Select personUids that can be viewed based on CoursePermission for the active user
      |                  where the CoursePermission is granted directly to them
      |                */   
      |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM CoursePermission
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE ? != 0
      |                  AND CoursePermission.cpToPersonUid = ?)
      |               
      |         SELECT Person.*, PersonPicture.*
      |           FROM Person
      |                LEFT JOIN PersonPicture
      |                     ON PersonPicture.personPictureUid = Person.personUid
      |          WHERE /* Begin permission check */ 
      |                (         
      |                      (
      |        EXISTS(SELECT 1
      |                 FROM SystemPermission
      |                WHERE ? != 0 
      |                  AND SystemPermission.spToPersonUid = ?
      |                  AND (SystemPermission.spPermissionsFlag &
      |    
      |                       8192
      |                       
      |        ) > 0
      |                  AND NOT SystemPermission.spIsDeleted)
      |    )
      |                    OR (Person.personUid IN 
      |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
      |                                  FROM CanViewPersonUidsViaCoursePermission))
      |                    OR (Person.personUid = ?)
      |                )
      |                /* End permission check */
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |                        AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid, PersonPicture.personPictureUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,accountPersonUid)
      _stmt.setLong(2,accountPersonUid)
      _stmt.setLong(3,accountPersonUid)
      _stmt.setLong(4,accountPersonUid)
      _stmt.setLong(5,accountPersonUid)
      _stmt.setLong(6,accountPersonUid)
      _stmt.setLong(7,accountPersonUid)
      _stmt.setLong(8,excludeClazz)
      _stmt.setLong(9,excludeClazz)
      _stmt.setLong(10,timestamp)
      _stmt.setArray(11, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          excludeSelected.toTypedArray()))
      _stmt.setString(12,searchText)
      _stmt.setString(13,searchText)
      _stmt.setInt(14,sortOrder)
      _stmt.setInt(15,sortOrder)
      _stmt.setInt(16,_limit)
      _stmt.setInt(17,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_Person_nullCount = 0
          val _tmp_personUid = _result.getLong("personUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_username = _result.getString("username")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_firstNames = _result.getString("firstNames")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_lastName = _result.getString("lastName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_emailAddr = _result.getString("emailAddr")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_phoneNum = _result.getString("phoneNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_gender = _result.getInt("gender")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_active = _result.getBoolean("active")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personAddress = _result.getString("personAddress")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personOrgId = _result.getString("personOrgId")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLct = _result.getLong("personLct")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personCountry = _result.getString("personCountry")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personType = _result.getInt("personType")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_admin = _result.getBoolean("admin")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personNotes = _result.getString("personNotes")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherName = _result.getString("fatherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherName = _result.getString("motherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherNum = _result.getString("motherNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
          var _tmp_PersonPicture_nullCount = 0
          val _tmp_personPictureUid = _result.getLong("personPictureUid")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureLct = _result.getLong("personPictureLct")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureUri = _result.getString("personPictureUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_fileSize = _result.getInt("fileSize")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
          PersonAndListDisplayDetails().apply {
            if(!_tmp_Person_isAllNull) {
              this.person = Person().apply {
                this.personUid = _tmp_personUid
                this.username = _tmp_username
                this.firstNames = _tmp_firstNames
                this.lastName = _tmp_lastName
                this.emailAddr = _tmp_emailAddr
                this.phoneNum = _tmp_phoneNum
                this.gender = _tmp_gender
                this.active = _tmp_active
                this.isPersonalAccount = _tmp_isPersonalAccount
                this.dateOfBirth = _tmp_dateOfBirth
                this.personAddress = _tmp_personAddress
                this.personOrgId = _tmp_personOrgId
                this.personGroupUid = _tmp_personGroupUid
                this.personLct = _tmp_personLct
                this.personCountry = _tmp_personCountry
                this.personType = _tmp_personType
                this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                this.personLastChangedBy = _tmp_personLastChangedBy
                this.admin = _tmp_admin
                this.personNotes = _tmp_personNotes
                this.fatherName = _tmp_fatherName
                this.fatherNumber = _tmp_fatherNumber
                this.motherName = _tmp_motherName
                this.motherNum = _tmp_motherNum
              }
            }
            if(!_tmp_PersonPicture_isAllNull) {
              this.picture = PersonPicture().apply {
                this.personPictureUid = _tmp_personPictureUid
                this.personPictureLct = _tmp_personPictureLct
                this.personPictureUri = _tmp_personPictureUri
                this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
                this.fileSize = _tmp_fileSize
                this.personPictureActive = _tmp_personPictureActive
              }
            }
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
      |              /* Select personUids that can be viewed based on CoursePermission given the active user 
      |                 for their enrolments 
      |              */
      |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
      |                      JOIN CoursePermission 
      |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
      |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
      |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE CAST(? AS BIGINT) != 0
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = CAST(? AS BIGINT)
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
      |              
      |               UNION
      |               /* Select personUids that can be viewed based on CoursePermission for the active user
      |                  where the CoursePermission is granted directly to them
      |                */   
      |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM CoursePermission
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE CAST(? AS BIGINT) != 0
      |                  AND CoursePermission.cpToPersonUid = CAST(? AS BIGINT))
      |               
      |         SELECT Person.*, PersonPicture.*
      |           FROM Person
      |                LEFT JOIN PersonPicture
      |                     ON PersonPicture.personPictureUid = Person.personUid
      |          WHERE /* Begin permission check */ 
      |                (         
      |                      (
      |        EXISTS(SELECT 1
      |                 FROM SystemPermission
      |                WHERE CAST(? AS BIGINT) != 0 
      |                  AND SystemPermission.spToPersonUid = CAST(? AS BIGINT)
      |                  AND (SystemPermission.spPermissionsFlag &
      |    
      |                       8192
      |                       
      |        ) > 0
      |                  AND NOT SystemPermission.spIsDeleted)
      |    )
      |                    OR (Person.personUid IN 
      |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
      |                                  FROM CanViewPersonUidsViaCoursePermission))
      |                    OR (Person.personUid = CAST(? AS BIGINT))
      |                )
      |                /* End permission check */
      |           AND (CAST(? AS BIGINT) = 0 OR CAST(? AS BIGINT) NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND CAST(? AS BIGINT) BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |                        AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid, PersonPicture.personPictureUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) AS _PagingCount
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |SELECT COUNT(*) FROM (
      |         WITH CanViewPersonUidsViaCoursePermission(personUid) AS
      |              /* Select personUids that can be viewed based on CoursePermission given the active user 
      |                 for their enrolments 
      |              */
      |              (SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM ClazzEnrolment ClazzEnrolment_ForActiveUser
      |                      JOIN CoursePermission 
      |                           ON CoursePermission.cpClazzUid = ClazzEnrolment_ForActiveUser.clazzEnrolmentClazzUid
      |                          AND CoursePermission.cpToEnrolmentRole = ClazzEnrolment_ForActiveUser.clazzEnrolmentRole
      |                          AND (CoursePermission.cpPermissionsFlag & 8192) > 0
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE ? != 0
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentPersonUid = ?
      |                  AND ClazzEnrolment_ForActiveUser.clazzEnrolmentActive
      |              
      |               UNION
      |               /* Select personUids that can be viewed based on CoursePermission for the active user
      |                  where the CoursePermission is granted directly to them
      |                */   
      |               SELECT DISTINCT ClazzEnrolment_ForClazzMember.clazzEnrolmentPersonUid AS personUid
      |                 FROM CoursePermission
      |                      JOIN ClazzEnrolment ClazzEnrolment_ForClazzMember
      |                           ON ClazzEnrolment_ForClazzMember.clazzEnrolmentClazzUid = CoursePermission.cpClazzUid
      |                WHERE ? != 0
      |                  AND CoursePermission.cpToPersonUid = ?)
      |               
      |         SELECT Person.*, PersonPicture.*
      |           FROM Person
      |                LEFT JOIN PersonPicture
      |                     ON PersonPicture.personPictureUid = Person.personUid
      |          WHERE /* Begin permission check */ 
      |                (         
      |                      (
      |        EXISTS(SELECT 1
      |                 FROM SystemPermission
      |                WHERE ? != 0 
      |                  AND SystemPermission.spToPersonUid = ?
      |                  AND (SystemPermission.spPermissionsFlag &
      |    
      |                       8192
      |                       
      |        ) > 0
      |                  AND NOT SystemPermission.spIsDeleted)
      |    )
      |                    OR (Person.personUid IN 
      |                               (SELECT CanViewPersonUidsViaCoursePermission.personUid
      |                                  FROM CanViewPersonUidsViaCoursePermission))
      |                    OR (Person.personUid = ?)
      |                )
      |                /* End permission check */
      |           AND (? = 0 OR ? NOT IN
      |                    (SELECT clazzEnrolmentClazzUid 
      |                       FROM ClazzEnrolment 
      |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
      |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
      |                        AND ClazzEnrolment.clazzEnrolmentActive))
      |           AND Person.personType = 0                  
      |           AND (Person.personUid NOT IN (?))
      |           AND (? = '%' 
      |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
      |      GROUP BY Person.personUid, PersonPicture.personPictureUid
      |      ORDER BY CASE(?)
      |               WHEN 1 THEN Person.firstNames
      |               WHEN 3 THEN Person.lastName
      |               ELSE ''
      |               END ASC,
      |               CASE(?)
      |               WHEN 2 THEN Person.firstNames
      |               WHEN 4 THEN Person.lastName
      |               ELSE ''
      |               END DESC
      |    ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,accountPersonUid)
      _stmt.setLong(2,accountPersonUid)
      _stmt.setLong(3,accountPersonUid)
      _stmt.setLong(4,accountPersonUid)
      _stmt.setLong(5,accountPersonUid)
      _stmt.setLong(6,accountPersonUid)
      _stmt.setLong(7,accountPersonUid)
      _stmt.setLong(8,excludeClazz)
      _stmt.setLong(9,excludeClazz)
      _stmt.setLong(10,timestamp)
      _stmt.setArray(11, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          excludeSelected.toTypedArray()))
      _stmt.setString(12,searchText)
      _stmt.setString(13,searchText)
      _stmt.setInt(14,sortOrder)
      _stmt.setInt(15,sortOrder)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      Flow<PersonAndDisplayDetail?> = _db.doorFlow(arrayOf("Person", "PersonParentJoin")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT Person.*, PersonParentJoin.* 
      |          FROM Person
      |     LEFT JOIN PersonParentJoin on ppjUid = (
      |                SELECT ppjUid 
      |                  FROM PersonParentJoin
      |                 WHERE ppjMinorPersonUid = CAST(? AS BIGINT) 
      |                       AND ppjParentPersonUid = CAST(? AS BIGINT) 
      |                LIMIT 1)     
      |         WHERE Person.personUid = CAST(? AS BIGINT)
      |        
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT Person.*, PersonParentJoin.* 
      |          FROM Person
      |     LEFT JOIN PersonParentJoin on ppjUid = (
      |                SELECT ppjUid 
      |                  FROM PersonParentJoin
      |                 WHERE ppjMinorPersonUid = ? 
      |                       AND ppjParentPersonUid = ? 
      |                LIMIT 1)     
      |         WHERE Person.personUid = ?
      |        
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setLong(2,activeUserPersonUid)
      _stmt.setLong(3,personUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_Person_nullCount = 0
          val _tmp_personUid = _result.getLong("personUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_username = _result.getString("username")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_firstNames = _result.getString("firstNames")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_lastName = _result.getString("lastName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_emailAddr = _result.getString("emailAddr")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_phoneNum = _result.getString("phoneNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_gender = _result.getInt("gender")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_active = _result.getBoolean("active")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personAddress = _result.getString("personAddress")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personOrgId = _result.getString("personOrgId")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLct = _result.getLong("personLct")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personCountry = _result.getString("personCountry")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personType = _result.getInt("personType")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_admin = _result.getBoolean("admin")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personNotes = _result.getString("personNotes")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherName = _result.getString("fatherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherName = _result.getString("motherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherNum = _result.getString("motherNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
          var _tmp_PersonParentJoin_nullCount = 0
          val _tmp_ppjUid = _result.getLong("ppjUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPcsn = _result.getLong("ppjPcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcsn = _result.getLong("ppjLcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcb = _result.getInt("ppjLcb")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLct = _result.getLong("ppjLct")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjParentPersonUid = _result.getLong("ppjParentPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjMinorPersonUid = _result.getLong("ppjMinorPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjRelationship = _result.getInt("ppjRelationship")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjEmail = _result.getString("ppjEmail")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPhone = _result.getString("ppjPhone")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjInactive = _result.getBoolean("ppjInactive")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjStatus = _result.getInt("ppjStatus")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalTiemstamp = _result.getLong("ppjApprovalTiemstamp")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalIpAddr = _result.getString("ppjApprovalIpAddr")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_PersonParentJoin_isAllNull = _tmp_PersonParentJoin_nullCount == 14
          var _tmp_PersonPicture_nullCount = 0
          val _tmp_personPictureUid = _result.getLong("personPictureUid")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureLct = _result.getLong("personPictureLct")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureUri = _result.getString("personPictureUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_fileSize = _result.getInt("fileSize")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
          var _tmp_TransferJobItem_nullCount = 0
          val _tmp_tjiUid = _result.getInt("tjiUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiTjUid = _result.getInt("tjiTjUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjTotalSize = _result.getLong("tjTotalSize")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjTransferred = _result.getLong("tjTransferred")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjAttemptCount = _result.getInt("tjAttemptCount")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiSrc = _result.getString("tjiSrc")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiDest = _result.getString("tjiDest")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiType = _result.getInt("tjiType")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiStatus = _result.getInt("tjiStatus")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiTableId = _result.getInt("tjiTableId")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiEntityUid = _result.getLong("tjiEntityUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiEntityEtag = _result.getLong("tjiEntityEtag")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiLockIdToRelease = _result.getLong("tjiLockIdToRelease")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiPartialTmpFile = _result.getString("tjiPartialTmpFile")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_TransferJobItem_isAllNull = _tmp_TransferJobItem_nullCount == 14
          PersonAndDisplayDetail().apply {
            if(!_tmp_Person_isAllNull) {
              this.person = Person().apply {
                this.personUid = _tmp_personUid
                this.username = _tmp_username
                this.firstNames = _tmp_firstNames
                this.lastName = _tmp_lastName
                this.emailAddr = _tmp_emailAddr
                this.phoneNum = _tmp_phoneNum
                this.gender = _tmp_gender
                this.active = _tmp_active
                this.isPersonalAccount = _tmp_isPersonalAccount
                this.dateOfBirth = _tmp_dateOfBirth
                this.personAddress = _tmp_personAddress
                this.personOrgId = _tmp_personOrgId
                this.personGroupUid = _tmp_personGroupUid
                this.personLct = _tmp_personLct
                this.personCountry = _tmp_personCountry
                this.personType = _tmp_personType
                this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                this.personLastChangedBy = _tmp_personLastChangedBy
                this.admin = _tmp_admin
                this.personNotes = _tmp_personNotes
                this.fatherName = _tmp_fatherName
                this.fatherNumber = _tmp_fatherNumber
                this.motherName = _tmp_motherName
                this.motherNum = _tmp_motherNum
              }
            }
            if(!_tmp_PersonParentJoin_isAllNull) {
              this.parentJoin = PersonParentJoin().apply {
                this.ppjUid = _tmp_ppjUid
                this.ppjPcsn = _tmp_ppjPcsn
                this.ppjLcsn = _tmp_ppjLcsn
                this.ppjLcb = _tmp_ppjLcb
                this.ppjLct = _tmp_ppjLct
                this.ppjParentPersonUid = _tmp_ppjParentPersonUid
                this.ppjMinorPersonUid = _tmp_ppjMinorPersonUid
                this.ppjRelationship = _tmp_ppjRelationship
                this.ppjEmail = _tmp_ppjEmail
                this.ppjPhone = _tmp_ppjPhone
                this.ppjInactive = _tmp_ppjInactive
                this.ppjStatus = _tmp_ppjStatus
                this.ppjApprovalTiemstamp = _tmp_ppjApprovalTiemstamp
                this.ppjApprovalIpAddr = _tmp_ppjApprovalIpAddr
              }
            }
            if(!_tmp_PersonPicture_isAllNull) {
              this.personPicture = PersonPicture().apply {
                this.personPictureUid = _tmp_personPictureUid
                this.personPictureLct = _tmp_personPictureLct
                this.personPictureUri = _tmp_personPictureUri
                this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
                this.fileSize = _tmp_fileSize
                this.personPictureActive = _tmp_personPictureActive
              }
            }
            if(!_tmp_TransferJobItem_isAllNull) {
              this.personPictureTransferJobItem = TransferJobItem().apply {
                this.tjiUid = _tmp_tjiUid
                this.tjiTjUid = _tmp_tjiTjUid
                this.tjTotalSize = _tmp_tjTotalSize
                this.tjTransferred = _tmp_tjTransferred
                this.tjAttemptCount = _tmp_tjAttemptCount
                this.tjiSrc = _tmp_tjiSrc
                this.tjiDest = _tmp_tjiDest
                this.tjiType = _tmp_tjiType
                this.tjiStatus = _tmp_tjiStatus
                this.tjiTableId = _tmp_tjiTableId
                this.tjiEntityUid = _tmp_tjiEntityUid
                this.tjiEntityEtag = _tmp_tjiEntityEtag
                this.tjiLockIdToRelease = _tmp_tjiLockIdToRelease
                this.tjiPartialTmpFile = _tmp_tjiPartialTmpFile
              }
            }
          }
        }
      }
    }
  }

  override fun findByUidWithDisplayDetailsFlow(personUid: Long, accountPersonUid: Long):
      Flow<PersonAndDisplayDetail?> = _db.doorFlow(arrayOf("Person", "PersonPicture",
      "PersonParentJoin", "TransferJobItem")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT Person.*, PersonParentJoin.* , PersonPicture.*, TransferJobItem.*
      |          FROM Person
      |               LEFT JOIN PersonParentJoin 
      |                    ON ppjUid =
      |                    (SELECT ppjUid 
      |                       FROM PersonParentJoin
      |                      WHERE ppjMinorPersonUid = CAST(? AS BIGINT) 
      |                        AND ppjParentPersonUid = CAST(? AS BIGINT) 
      |                      LIMIT 1)  
      |               LEFT JOIN PersonPicture
      |                    ON PersonPicture.personPictureUid = CAST(? AS BIGINT)
      |               LEFT JOIN TransferJobItem
      |                    ON TransferJobItem.tjiUid = 
      |                       (SELECT TransferJobItem.tjiUid
      |                          FROM TransferJobItem
      |                         WHERE TransferJobItem.tjiEntityUid = CAST(? AS BIGINT)
      |                           AND TransferJobItem.tjiTableId = 50
      |                           AND TransferJobItem.tjiEntityEtag = PersonPicture.personPictureLct
      |                           AND TransferJobItem.tjiStatus != 21
      |                         LIMIT 1)
      |                          
      |         WHERE Person.personUid = CAST(? AS BIGINT)
      |        
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT Person.*, PersonParentJoin.* , PersonPicture.*, TransferJobItem.*
      |          FROM Person
      |               LEFT JOIN PersonParentJoin 
      |                    ON ppjUid =
      |                    (SELECT ppjUid 
      |                       FROM PersonParentJoin
      |                      WHERE ppjMinorPersonUid = ? 
      |                        AND ppjParentPersonUid = ? 
      |                      LIMIT 1)  
      |               LEFT JOIN PersonPicture
      |                    ON PersonPicture.personPictureUid = ?
      |               LEFT JOIN TransferJobItem
      |                    ON TransferJobItem.tjiUid = 
      |                       (SELECT TransferJobItem.tjiUid
      |                          FROM TransferJobItem
      |                         WHERE TransferJobItem.tjiEntityUid = ?
      |                           AND TransferJobItem.tjiTableId = 50
      |                           AND TransferJobItem.tjiEntityEtag = PersonPicture.personPictureLct
      |                           AND TransferJobItem.tjiStatus != 21
      |                         LIMIT 1)
      |                          
      |         WHERE Person.personUid = ?
      |        
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setLong(2,accountPersonUid)
      _stmt.setLong(3,personUid)
      _stmt.setLong(4,personUid)
      _stmt.setLong(5,personUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_Person_nullCount = 0
          val _tmp_personUid = _result.getLong("personUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_username = _result.getString("username")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_firstNames = _result.getString("firstNames")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_lastName = _result.getString("lastName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_emailAddr = _result.getString("emailAddr")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_phoneNum = _result.getString("phoneNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_gender = _result.getInt("gender")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_active = _result.getBoolean("active")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personAddress = _result.getString("personAddress")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personOrgId = _result.getString("personOrgId")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personGroupUid = _result.getLong("personGroupUid")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLct = _result.getLong("personLct")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personCountry = _result.getString("personCountry")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personType = _result.getInt("personType")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_admin = _result.getBoolean("admin")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_personNotes = _result.getString("personNotes")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherName = _result.getString("fatherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_fatherNumber = _result.getString("fatherNumber")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherName = _result.getString("motherName")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_motherNum = _result.getString("motherNum")
          if(_result.wasNull()) _tmp_Person_nullCount++
          val _tmp_Person_isAllNull = _tmp_Person_nullCount == 25
          var _tmp_PersonParentJoin_nullCount = 0
          val _tmp_ppjUid = _result.getLong("ppjUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPcsn = _result.getLong("ppjPcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcsn = _result.getLong("ppjLcsn")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLcb = _result.getInt("ppjLcb")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjLct = _result.getLong("ppjLct")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjParentPersonUid = _result.getLong("ppjParentPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjMinorPersonUid = _result.getLong("ppjMinorPersonUid")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjRelationship = _result.getInt("ppjRelationship")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjEmail = _result.getString("ppjEmail")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjPhone = _result.getString("ppjPhone")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjInactive = _result.getBoolean("ppjInactive")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjStatus = _result.getInt("ppjStatus")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalTiemstamp = _result.getLong("ppjApprovalTiemstamp")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_ppjApprovalIpAddr = _result.getString("ppjApprovalIpAddr")
          if(_result.wasNull()) _tmp_PersonParentJoin_nullCount++
          val _tmp_PersonParentJoin_isAllNull = _tmp_PersonParentJoin_nullCount == 14
          var _tmp_PersonPicture_nullCount = 0
          val _tmp_personPictureUid = _result.getLong("personPictureUid")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureLct = _result.getLong("personPictureLct")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureUri = _result.getString("personPictureUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_fileSize = _result.getInt("fileSize")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
          if(_result.wasNull()) _tmp_PersonPicture_nullCount++
          val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
          var _tmp_TransferJobItem_nullCount = 0
          val _tmp_tjiUid = _result.getInt("tjiUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiTjUid = _result.getInt("tjiTjUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjTotalSize = _result.getLong("tjTotalSize")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjTransferred = _result.getLong("tjTransferred")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjAttemptCount = _result.getInt("tjAttemptCount")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiSrc = _result.getString("tjiSrc")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiDest = _result.getString("tjiDest")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiType = _result.getInt("tjiType")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiStatus = _result.getInt("tjiStatus")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiTableId = _result.getInt("tjiTableId")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiEntityUid = _result.getLong("tjiEntityUid")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiEntityEtag = _result.getLong("tjiEntityEtag")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiLockIdToRelease = _result.getLong("tjiLockIdToRelease")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_tjiPartialTmpFile = _result.getString("tjiPartialTmpFile")
          if(_result.wasNull()) _tmp_TransferJobItem_nullCount++
          val _tmp_TransferJobItem_isAllNull = _tmp_TransferJobItem_nullCount == 14
          PersonAndDisplayDetail().apply {
            if(!_tmp_Person_isAllNull) {
              this.person = Person().apply {
                this.personUid = _tmp_personUid
                this.username = _tmp_username
                this.firstNames = _tmp_firstNames
                this.lastName = _tmp_lastName
                this.emailAddr = _tmp_emailAddr
                this.phoneNum = _tmp_phoneNum
                this.gender = _tmp_gender
                this.active = _tmp_active
                this.isPersonalAccount = _tmp_isPersonalAccount
                this.dateOfBirth = _tmp_dateOfBirth
                this.personAddress = _tmp_personAddress
                this.personOrgId = _tmp_personOrgId
                this.personGroupUid = _tmp_personGroupUid
                this.personLct = _tmp_personLct
                this.personCountry = _tmp_personCountry
                this.personType = _tmp_personType
                this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
                this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
                this.personLastChangedBy = _tmp_personLastChangedBy
                this.admin = _tmp_admin
                this.personNotes = _tmp_personNotes
                this.fatherName = _tmp_fatherName
                this.fatherNumber = _tmp_fatherNumber
                this.motherName = _tmp_motherName
                this.motherNum = _tmp_motherNum
              }
            }
            if(!_tmp_PersonParentJoin_isAllNull) {
              this.parentJoin = PersonParentJoin().apply {
                this.ppjUid = _tmp_ppjUid
                this.ppjPcsn = _tmp_ppjPcsn
                this.ppjLcsn = _tmp_ppjLcsn
                this.ppjLcb = _tmp_ppjLcb
                this.ppjLct = _tmp_ppjLct
                this.ppjParentPersonUid = _tmp_ppjParentPersonUid
                this.ppjMinorPersonUid = _tmp_ppjMinorPersonUid
                this.ppjRelationship = _tmp_ppjRelationship
                this.ppjEmail = _tmp_ppjEmail
                this.ppjPhone = _tmp_ppjPhone
                this.ppjInactive = _tmp_ppjInactive
                this.ppjStatus = _tmp_ppjStatus
                this.ppjApprovalTiemstamp = _tmp_ppjApprovalTiemstamp
                this.ppjApprovalIpAddr = _tmp_ppjApprovalIpAddr
              }
            }
            if(!_tmp_PersonPicture_isAllNull) {
              this.personPicture = PersonPicture().apply {
                this.personPictureUid = _tmp_personPictureUid
                this.personPictureLct = _tmp_personPictureLct
                this.personPictureUri = _tmp_personPictureUri
                this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
                this.fileSize = _tmp_fileSize
                this.personPictureActive = _tmp_personPictureActive
              }
            }
            if(!_tmp_TransferJobItem_isAllNull) {
              this.personPictureTransferJobItem = TransferJobItem().apply {
                this.tjiUid = _tmp_tjiUid
                this.tjiTjUid = _tmp_tjiTjUid
                this.tjTotalSize = _tmp_tjTotalSize
                this.tjTransferred = _tmp_tjTransferred
                this.tjAttemptCount = _tmp_tjAttemptCount
                this.tjiSrc = _tmp_tjiSrc
                this.tjiDest = _tmp_tjiDest
                this.tjiType = _tmp_tjiType
                this.tjiStatus = _tmp_tjiStatus
                this.tjiTableId = _tmp_tjiTableId
                this.tjiEntityUid = _tmp_tjiEntityUid
                this.tjiEntityEtag = _tmp_tjiEntityEtag
                this.tjiLockIdToRelease = _tmp_tjiLockIdToRelease
                this.tjiPartialTmpFile = _tmp_tjiPartialTmpFile
              }
            }
          }
        }
      }
    }
  }

  override fun getAllPerson(): List<Person> = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM Person",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override fun getNamesByUid(uid: Long): Flow<PersonNames?> = _db.doorFlow(arrayOf("Person")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        SELECT Person.firstNames, Person.lastName
      |          FROM Person
      |         WHERE Person.personUid = CAST(? AS BIGINT)  
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        SELECT Person.firstNames, Person.lastName
      |          FROM Person
      |         WHERE Person.personUid = ?  
      |    
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_firstNames = _result.getString("firstNames")
          val _tmp_lastName = _result.getString("lastName")
          PersonNames().apply {
            this.firstNames = _tmp_firstNames
            this.lastName = _tmp_lastName
          }
        }
      }
    }
  }

  override suspend fun getNamesByUidAsync(uid: Long): PersonNames? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.firstNames, Person.lastName
    |          FROM Person
    |         WHERE Person.personUid = CAST(? AS BIGINT)  
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT Person.firstNames, Person.lastName
    |          FROM Person
    |         WHERE Person.personUid = ?  
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        PersonNames().apply {
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
        }
      }
    }
  }

  override suspend fun getMinorByParentPersonUidAsync(uid: Long): List<Person>? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |          SELECT Person.*
    |             FROM Person
    |             LEFT JOIN PersonParentJoin ON Person.personUid = PersonParentJoin.ppjMinorPersonUid
    |              WHERE PersonParentJoin.ppjParentPersonUid =  CAST(? AS BIGINT)  
    |     
    """.trimMargin(),
    postgreSql = """
    |
    |          SELECT Person.*
    |             FROM Person
    |             LEFT JOIN PersonParentJoin ON Person.personUid = PersonParentJoin.ppjMinorPersonUid
    |              WHERE PersonParentJoin.ppjParentPersonUid =  ?  
    |     
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_personUid = _result.getLong("personUid")
        val _tmp_username = _result.getString("username")
        val _tmp_firstNames = _result.getString("firstNames")
        val _tmp_lastName = _result.getString("lastName")
        val _tmp_emailAddr = _result.getString("emailAddr")
        val _tmp_phoneNum = _result.getString("phoneNum")
        val _tmp_gender = _result.getInt("gender")
        val _tmp_active = _result.getBoolean("active")
        val _tmp_isPersonalAccount = _result.getBoolean("isPersonalAccount")
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        val _tmp_personAddress = _result.getString("personAddress")
        val _tmp_personOrgId = _result.getString("personOrgId")
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        val _tmp_personLct = _result.getLong("personLct")
        val _tmp_personCountry = _result.getString("personCountry")
        val _tmp_personType = _result.getInt("personType")
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        val _tmp_admin = _result.getBoolean("admin")
        val _tmp_personNotes = _result.getString("personNotes")
        val _tmp_fatherName = _result.getString("fatherName")
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        val _tmp_motherName = _result.getString("motherName")
        val _tmp_motherNum = _result.getString("motherNum")
        Person().apply {
          this.personUid = _tmp_personUid
          this.username = _tmp_username
          this.firstNames = _tmp_firstNames
          this.lastName = _tmp_lastName
          this.emailAddr = _tmp_emailAddr
          this.phoneNum = _tmp_phoneNum
          this.gender = _tmp_gender
          this.active = _tmp_active
          this.isPersonalAccount = _tmp_isPersonalAccount
          this.dateOfBirth = _tmp_dateOfBirth
          this.personAddress = _tmp_personAddress
          this.personOrgId = _tmp_personOrgId
          this.personGroupUid = _tmp_personGroupUid
          this.personLct = _tmp_personLct
          this.personCountry = _tmp_personCountry
          this.personType = _tmp_personType
          this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
          this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
          this.personLastChangedBy = _tmp_personLastChangedBy
          this.admin = _tmp_admin
          this.personNotes = _tmp_personNotes
          this.fatherName = _tmp_fatherName
          this.fatherNumber = _tmp_fatherNumber
          this.motherName = _tmp_motherName
          this.motherNum = _tmp_motherNum
        }
      }
    }
  }

  override suspend fun updateUsername(
    personUid: Long,
    username: String,
    currentTime: Long,
  ): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        UPDATE Person
    |           SET username = ?,
    |               personLct = CAST(? AS BIGINT)
    |         WHERE Person.personUid = CAST(? AS BIGINT)  
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        UPDATE Person
    |           SET username = ?,
    |               personLct = ?
    |         WHERE Person.personUid = ?  
    |    
    |""".trimMargin(),
    readOnly = false,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.setLong(2,currentTime)
    _stmt.setLong(3,personUid)
    _stmt.executeUpdateAsyncKmp()
  }

  override suspend fun selectExistingUsernames(usernames: List<String>): List<String?> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Person.username
    |          FROM Person
    |         WHERE Person.username IN (?)
    |    
    """.trimMargin(),
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("TEXT",
        usernames.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getString(1)
      }
    }
  }
}
