package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.PersonPasskey
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonPasskeyDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonPasskeyDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : PersonPasskeyDao() {
  override suspend fun insertAsync(personPasskey: PersonPasskey): Long {
    val _result = _repo.withRepoChangeMonitorAsync("PersonPasskey") {
      _dao.insertAsync(personPasskey)
    }
    return _result
  }

  override suspend fun allPasskey(): List<String> {
    val _result = _dao.allPasskey()
    return _result
  }

  override suspend fun findPersonPasskeyFromClientDataJson(id: String): PersonPasskey? {
    val _result = _dao.findPersonPasskeyFromClientDataJson(id)
    return _result
  }
}
