package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.PersonPasskey
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class PersonPasskeyDao {
  public actual abstract suspend fun insertAsync(personPasskey: PersonPasskey): Long

  public actual abstract suspend fun allPasskey(): List<String>

  public actual abstract suspend fun findPersonPasskeyFromClientDataJson(id: String): PersonPasskey?
}
