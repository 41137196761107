package com.ustadmobile

import kotlin.String

internal object BuildConfigJs {
  internal const val APP_VERSION: String = "0.4.132"

  internal const val APP_PRESET_LOCALE: String = ""

  internal const val APP_UI_LANGUAGES: String = ""

  internal const val APP_UI_GOPTS: String = ""

  internal const val APP_UI_SHOW_POWERED_BY: String = "true"

  internal const val SYSTEM_URL: String = "https://learningtree.ustadmobile.com"

  internal const val PASSKEY_RP_ID: String = "ustadmobile.app"

  internal const val PRESET_LEARNING_SPACE_URL: String = ""

  internal const val NEW_PERSONAL_ACCOUNT_LEARNING_SPACE_URL: String =
      "https://personal.learningtree.ustadmobile.com"
}
