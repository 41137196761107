package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.CourseBlockAndAssignment
import com.ustadmobile.lib.db.composites.CourseBlockAndDbEntities
import com.ustadmobile.lib.db.composites.CourseBlockAndDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndGradebookDisplayDetails
import com.ustadmobile.lib.db.composites.CourseBlockAndPicture
import com.ustadmobile.lib.db.composites.CourseBlockUidAndClazzUid
import com.ustadmobile.lib.db.entities.CourseBlock
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseBlockDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseBlockDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : CourseBlockDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun findByUidAsync(uid: Long): CourseBlock? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override suspend fun existsByUid(cbUid: Long): Boolean {
    val _result = _dao.existsByUid(cbUid)
    return _result
  }

  override suspend fun updateAsync(entity: CourseBlock): Int {
    val _result = _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override suspend fun replaceListAsync(list: List<CourseBlock>) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.replaceListAsync(list)
    }
  }

  override fun findByUidAsyncAsFlow(uid: Long): Flow<CourseBlock?> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByUidAsyncAsFlow(uid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseBlockDao/findByUidAsyncAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findByUidAsyncAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override fun findByUidWithPictureAsFlow(uid: Long): Flow<CourseBlockAndPicture?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByUidWithPictureAsFlow(uid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseBlockDao/findByUidWithPictureAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findByUidWithPictureAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long, includeInactive: Boolean):
      List<CourseBlockAndDbEntities> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "CourseBlockDao/findAllCourseBlockByClazzUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseBlockDao/findAllCourseBlockByClazzUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("includeInactive", _repo.config.json.encodeToString(Boolean.serializer(),
            includeInactive))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findAllCourseBlockByClazzUidAsync(clazzUid, includeInactive)
    return _result
  }

  override fun findAllCourseBlockByClazzUidAsPagingSource(
    clazzUid: Long,
    collapseList: List<Long>,
    includeInactive: Boolean,
    includeHidden: Boolean,
    hideUntilFilterTime: Long,
    accountPersonUid: Long,
  ): PagingSource<Int, CourseBlockAndDisplayDetails> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "CourseBlockDao/findAllCourseBlockByClazzUidAsPagingSource",
    dbPagingSource = _dao.findAllCourseBlockByClazzUidAsPagingSource(clazzUid, collapseList,
        includeInactive, includeHidden, hideUntilFilterTime, accountPersonUid),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "CourseBlockDao/findAllCourseBlockByClazzUidAsPagingSource") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findAllCourseBlockByClazzUidAsPagingSource")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("collapseList",
              _repo.config.json.encodeToString(ListSerializer(Long.serializer()), collapseList))
          parameter("includeInactive", _repo.config.json.encodeToString(Boolean.serializer(),
              includeInactive))
          parameter("includeHidden", _repo.config.json.encodeToString(Boolean.serializer(),
              includeHidden))
          parameter("hideUntilFilterTime", _repo.config.json.encodeToString(Long.serializer(),
              hideUntilFilterTime))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.updateActiveByUid(cbUid, active, changeTime)
    }
  }

  override suspend fun upsertListAsync(entities: List<CourseBlock>) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.upsertListAsync(entities)
    }
  }

  override fun getTitleByAssignmentUid(assignmentUid: Long): Flow<String?> {
    val _result = _dao.getTitleByAssignmentUid(assignmentUid)
    return _result
  }

  override fun findByUidAsFlow(courseBlockUid: Long): Flow<CourseBlock?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByUidAsFlow(courseBlockUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseBlockDao/findByUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findByUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("courseBlockUid", _repo.config.json.encodeToString(Long.serializer(),
              courseBlockUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findCourseBlockAndClazzUidByDiscussionPostUid(postUid: Long):
      CourseBlockUidAndClazzUid? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "CourseBlockDao/findCourseBlockAndClazzUidByDiscussionPostUid") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseBlockDao/findCourseBlockAndClazzUidByDiscussionPostUid")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("postUid", _repo.config.json.encodeToString(Long.serializer(), postUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findCourseBlockAndClazzUidByDiscussionPostUid(postUid)
    return _result
  }

  override suspend fun findCourseBlockByDiscussionPostUid(postUid: Long): CourseBlock? {
    val _result = _dao.findCourseBlockByDiscussionPostUid(postUid)
    return _result
  }

  override suspend fun findClazzUidByCourseBlockUid(courseBlockUid: Long): Long {
    val _result = _dao.findClazzUidByCourseBlockUid(courseBlockUid)
    return _result
  }

  override fun findCourseBlockByAssignmentUid(assignmentUid: Long): Flow<CourseBlockAndAssignment?>
      = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findCourseBlockByAssignmentUid(assignmentUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "CourseBlockDao/findCourseBlockByAssignmentUid") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findCourseBlockByAssignmentUid")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findBySourcedId(sourcedId: String, accountPersonUid: Long): CourseBlock? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseBlockDao/findBySourcedId") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "CourseBlockDao/findBySourcedId")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("sourcedId", _repo.config.json.encodeToString(String.serializer(), sourcedId))
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findBySourcedId(sourcedId, accountPersonUid)
    return _result
  }

  override suspend fun findByClazzUid(clazzUid: Long): List<CourseBlock> {
    val _result = _dao.findByClazzUid(clazzUid)
    return _result
  }

  override fun findByClazzUidAsFlow(clazzUid: Long):
      Flow<List<CourseBlockAndGradebookDisplayDetails>> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByClazzUidAsFlow(clazzUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath = "CourseBlockDao/findByClazzUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "CourseBlockDao/findByClazzUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun updateFromLineItem(
    cbUid: Long,
    active: Boolean,
    dateLastModified: Long,
    title: String,
    description: String,
    assignDate: Long,
    dueDate: Long,
    resultValueMin: Float,
    resultValueMax: Float,
  ) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.updateFromLineItem(cbUid, active, dateLastModified, title, description, assignDate,
          dueDate, resultValueMin, resultValueMax)
    }
  }

  override suspend fun findCourseBlockAndClazzUidByCbUid(cbUid: Long, accountPersonUid: Long):
      CourseBlockUidAndClazzUid? {
    val _result = _dao.findCourseBlockAndClazzUidByCbUid(cbUid, accountPersonUid)
    return _result
  }

  public override fun insert(entity: CourseBlock): Long {
    val _result = _repo.withRepoChangeMonitor("CourseBlock") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _result = _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<CourseBlock>) {
    _repo.withRepoChangeMonitor("CourseBlock") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: CourseBlock) {
    _repo.withRepoChangeMonitor("CourseBlock") {
      _dao.update(entity)
    }
  }

  public override suspend fun insertListAsync(entityList: List<CourseBlock>) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.insertListAsync(entityList)
    }
  }

  public override suspend fun updateListAsync(entityList: List<CourseBlock>) {
    _repo.withRepoChangeMonitorAsync("CourseBlock") {
      _dao.updateListAsync(entityList)
    }
  }
}
