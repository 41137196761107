package com.ustadmobile.core.db.dao.xapi

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.BlockStatus
import com.ustadmobile.lib.db.composites.xapi.StatementEntityAndRelated
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.StatementEntityAndDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.xapi.StatementEntity
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class StatementDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StatementDao,
) : StatementDao() {
  override suspend fun insertOrIgnoreListAsync(entityList: List<StatementEntity>) {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.statementLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertOrIgnoreListAsync(entityList)
  }

  override fun getOneStatement(): Flow<StatementEntity?> = _dao.getOneStatement()

  override suspend fun getResults(query: DoorQuery): List<StatementReportData> =
      _dao.getResults(query)

  override fun getListResults(query: DoorQuery): PagingSource<Int, StatementEntityAndDisplayDetails>
      = _dao.getListResults(query)

  override fun getPerson(): Person? = throw
      IllegalStateException("getPerson: synchronous db access is NOT possible on Javascript!")

  override suspend fun getStatements(statementIdHi: Long, statementIdLo: Long):
      List<StatementEntity> = _dao.getStatements(statementIdHi, statementIdLo)

  override suspend fun findById(statementIdHi: Long, statementIdLo: Long): StatementEntity? =
      _dao.findById(statementIdHi, statementIdLo)

  override suspend fun findStatusStatementsByContentEntryUid(
    contentEntryUid: Long,
    courseBlockUid: Long,
    accountPersonUid: Long,
  ): List<StatementEntity> = _dao.findStatusStatementsByContentEntryUid(contentEntryUid,
      courseBlockUid, accountPersonUid)

  override suspend fun findStatusStatementByParentContentEntryUid(parentUid: Long,
      accountPersonUid: Long): List<StatementEntity> =
      _dao.findStatusStatementByParentContentEntryUid(parentUid, accountPersonUid)

  override suspend fun findStatusStatementsForStudentByClazzUid(clazzUid: Long,
      accountPersonUid: Long): List<StatementEntity> =
      _dao.findStatusStatementsForStudentByClazzUid(clazzUid, accountPersonUid)

  override suspend fun findStatusForStudentsInClazzStatements(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    studentsLimit: Int,
    studentsOffset: Int,
    completionOrProgressTrueVal: Boolean,
  ): List<StatementEntityAndRelated> = _dao.findStatusForStudentsInClazzStatements(clazzUid, roleId,
      sortOrder, searchText, filter, accountPersonUid, currentTime, studentsLimit, studentsOffset,
      completionOrProgressTrueVal)

  override suspend fun findStatusForStudentsInClazz(clazzUid: Long, studentPersonUids: List<Long>):
      List<BlockStatus> = _dao.findStatusForStudentsInClazz(clazzUid, studentPersonUids)

  override suspend fun findResumableRegistration(
    activityUid: Long,
    accountPersonUid: Long,
    actorUid: Long,
  ): StatementEntity? = _dao.findResumableRegistration(activityUid, accountPersonUid, actorUid)
}
